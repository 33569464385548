<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Transaction>
  onSubmit: (event: FormSubmitEvent<Partial<Transaction>>) => void
  title?: string
  disabledFields?: (keyof Transaction | string)[]
}>()

const state = ref<Partial<Transaction>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  slideoverOpenReconcile,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
  getDropdownItemsReconcile,
} = useTransactions()

const $financialaccounts = await useFetch<Financialaccount[]>('/api/db/financialaccounts', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const utils = useTransactionsUtils({ $financialaccounts })

const financialaccounts$ = useFinancialaccounts()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
  ]
})

const loading = ref(false)

const onSubmitTransaction = async (event: FormSubmitEvent<Partial<Transaction>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover
    prevent-close
    :title="title"
  >
    <UForm
      id="form.transactions"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      @submit="onSubmitTransaction"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Cuenta financiera"
            name="financialaccountId"
            :required="isRequired(schema, 'financialaccountId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.financialaccountId"
                :options="utils.options.financialaccountId.value"
                :disabled="disabledFields?.includes('financialaccountId')"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('financialaccountId')"
                @click="openNested(() => financialaccounts$.slideoverOpenCreate({
                  data: {
                    type: 'cash',
                    currency: 'MXN',
                    balanceMin: 0,
                    balanceMax: 0,
                  },
                  onSubmit: async (event) => {
                    const financialaccount = await financialaccounts$.onSubmitCreate(event.data)
                    state.financialaccountId = financialaccount!.id
                    await $financialaccounts.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Referencia"
            name="reference"
            :required="isRequired(schema, 'reference')"
          >
            <UInput
              v-model="state.reference"
              autofocus
              :disabled="disabledFields?.includes('reference')"
            />
          </UFormGroup>

          <FieldsetDateRange
            v-model:is-datetime="state.isDatetime"
            v-model:date-start="state.accountingDate"
            v-model:date-end="state.valueDate"
            :is-required-start-date="isRequired(schema, 'accountingDate')"
            :is-required-end-date="isRequired(schema, 'valueDate')"
            :date-start-label="'Fecha contable'"
            :date-end-label="'Fecha valor'"
            :disabled="disabledFields"
          />

          <UFormGroup
            label="Monto"
            name="amount"
            :required="isRequired(schema, 'amount')"
          >
            <UInput
              v-model="state.amount"
              :min="0"
              :step="0.01"
              :disabled="disabledFields?.includes('amount')"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `transactions/${state.id}/files` }"
            />
          </UFormGroup>
        </template>
      </UAccordion>
    </UForm>

    <template #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.transactions"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
