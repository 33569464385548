<script setup lang="ts">
import type { ZodSchema } from 'zod'

const model = defineModel<{ productitemId?: string, [key: string]: string | number | boolean }>({
  required: true,
})

const props = defineProps<{
  schema: ZodSchema
  disabled?: boolean
  filteredIds?: string[]
  slideoverOpenEdit: () => void
}>()

const $products = await useFetch<Productitem[]>('/api/db/products', {
  query: { $columns: ['id', 'name', 'type'] },
  default: () => [],
})
const $productitems = await useFetch<Productitem[]>('/api/db/productitems', {
  query: { $columns: ['id', 'name', 'type', 'productId'] },
  default: () => [],
})

const products$ = useProducts()
const productitems$ = useProductitems()

const productId = ref<number | undefined>(undefined)
const productType = ref<string | undefined>('finished-goods')

const options = { type: optionsProducts.type }

const maps = {
  productId: computed(() => toMapByKey($products?.data.value, 'id')),
  productitemId: computed(() => toMapByKey($productitems?.data.value, 'id')),
  productsByType: computed(() => toMapByGroupKey($products?.data.value, 'type')),
  productitemsByProductId: computed(() => toMapByGroupKey($productitems?.data.value, 'productId')),
}

const regenerateKey = ref(useId())

watch(model.value.productitemId, () => {
  if (!model.value.productitemId) return
  const productitem = maps.productitemId.value.get(model.value.productitemId) as Productitem
  const selectedProduct = maps.productId.value.get(productitem?.productId) as Product
  productType.value = selectedProduct.type
  productId.value = selectedProduct.id
  regenerateKey.value = useId()
},
{ immediate: true })

const FIELDSET = `w-full grid grid-cols-1 gap-2 !mt-4 !mb-2 p-4 border rounded-lg`
</script>

<template>
  <fieldset :class="FIELDSET" :disabled="disabled">
    <UFormGroup
      label="Tipo de producto"
      :required="isRequired(schema, 'productitemId')"
    >
      <SelectMenuBaseInfo
        v-model="productType"
        :options="options.type ?? []"
        :disabled="disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Producto"
      :required="isRequired(schema, 'productitemId')"
    >
      <UButtonGroup class="flex">
        <SelectMenuBase
          :key="`product-id-${regenerateKey}`"
          v-model="productId"
          :options="maps.productsByType?.value.get(productType)?.map(toOption) ?? []"
          class="flex-1"
        />
        <UButton
          icon="i-mdi-plus"
          @click="openNested(() => products$.slideoverOpenCreate({
            data: { type: productType },
            disabledFields: ['type'],
            onSubmit: async (event) => {
              products$.onSubmitCreate(event.data)
              await $products.refresh()
              await openNested(() => props.slideoverOpenEdit({ ...props, data: model }))
            },
          }))"
        />
      </UButtonGroup>
    </UFormGroup>

    <UFormGroup
      label="Item"
      :required="isRequired(schema, 'item')"
    >
      <UButtonGroup class="flex">
        <SelectMenuBase
          v-model="model.productitemId"
          :options="maps.productitemsByProductId?.value.get(productId)?.map(toOption).filter((item) => !filteredIds?.includes(item.value)) ?? []"
          :disabled="!productId"
          class="flex-1"
        />
        <UButton
          icon="i-mdi-plus"
          :disabled="!productId"
          @click="openNested(() => productitems$.slideoverOpenCreate({
            data: { type: productType, productId: productId },
            disabledFields: ['type', 'productId'],
            onSubmit: async (event) => {
              const productitem = await productitems$.onSubmitCreate(event.data)
              model.productitemId = productitem!.id
              await $productitems.refresh()
              await openNested(() => props.slideoverOpenEdit({ ...props, data: model }))
            },
          }))"
        />
      </UButtonGroup>
    </UFormGroup>
  </fieldset>
</template>
