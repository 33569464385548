<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<User>
  onSubmit: (event: FormSubmitEvent<Partial<User>>) => void
  title?: string
  disabledFields?: (keyof User | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<User>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  modalOpenDeleteUser,
  modalOpenInviteUser,
  schemaInternal,
  schemaExternal,
  getDropdownItems,
} = useUsers()

const utils = useUsersUtils()

// make accountId required if type is external in zod schema
const schema = ['external'].includes(state.value.type) ? schemaExternal : schemaInternal

const items: AccordionItem[] = [
  {
    slot: 'general',
    label: 'General',
    icon: 'i-mdi-information',
    defaultOpen: true,
  },
  ...(['external'].includes(state.value.type)
    ? [
        {
          slot: 'details',
          label: 'Detalles',
          icon: 'i-mdi-information',
          defaultOpen: true,
        },
      ]
    : []
  ),
  {
    slot: 'modules',
    label: 'Acceso a Módulos',
    icon: 'i-mdi-hexagon-multiple',
    defaultOpen: true,
  },
]

const modules = computed(() => optionsUsers.modules.filter(mod => mod.userTypes.includes(state.value.type)))

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const optionsAccountId = computed(() => $accounts.data.value?.map(toOption) ?? [])

const loading = ref(false)

const onSubmitUser = async (event: FormSubmitEvent<Partial<User>>) => {
  loading.value = true
  // IMPORTANT: ensure all modules not allowed for the user.type are deleted
  optionsUsers.modules.filter(mod => !mod.userTypes.includes(event.data.type)).forEach((mod) => {
    delete event.data.modules[mod.value]
  })
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.users"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitUser"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type.filter(option => !['admin'].includes(option.value))"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
          >
            <UInput
              v-model="state.name"
              autofocus
              :disabled="disabledFields?.includes('name')"
            />
          </UFormGroup>

          <UFormGroup
            label="Email"
            name="email"
            :required="isRequired(schema, 'email')"
          >
            <UInput
              v-model="state.email"
              type="email"
              autocomplete="email"
              :disabled="disabledFields?.includes('email')"
            />
          </UFormGroup>

          <UFormGroup
            label="Teléfono"
            name="phone"
            :required="isRequired(schema, 'phone')"
          >
            <InputPhone
              v-model="state.phone"
              :disabled="disabledFields?.includes('phone')"
            />
          </UFormGroup>

          <!-- <UFormGroup label="Contraseña" name="password" required>
            <InputPassword v-model="state.password" autocomplete="new-password" />
          </UFormGroup> -->

          <!-- <UFormGroup
            label="Módulos"
            name="modules"
            :required="isRequired(schema, 'modules')"
          >
            <SelectMenuBase
              v-model="state.modules"
              :options="optionsUsers.modules"
              :disabled="disabledFields?.includes('modules')"
            />
          </UFormGroup> -->

          <UFormGroup
            label="Imagen"
            name="image"
            :required="isRequired(schema, 'image')"
          >
            <InputImage
              v-model="state.image"
              :query="{ prefix: `users/${state.id}` }"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup
            label="Cuenta"
            name="accountId"
            :required="isRequired(schema, 'accountId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.accountId"
                :options="optionsAccountId"
                :disabled="disabledFields?.includes('accountId')"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('accountId')"
                @click="openNested(() => accounts$.slideoverOpenCreate({
                  data: { type: 'customer' },
                  onSubmit: async (event) => {
                    const account = await accounts$.onSubmitCreate(event.data)
                    state.accountId = account!.id
                    await $accounts.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <Alert
            type="warning"
            description="Usuario tendra acceso a los datos de la cuenta."
          />
        </template>

        <template #modules>
          <fieldset>
            <div
              v-for="(mod, i) in modules"
              :key="`users-module-${mod.value}-${i}`"
              :name="`modules.${mod.value}.enabled`"
              class="grid grid-cols-[auto,auto,140px] gap-2 items-center"
            >
              <div class="flex items-center gap-2">
                {{ mod.label }}
                <UTooltip :text="mod.description">
                  <UIcon name="i-mdi-information" size="xs" />
                </UTooltip>
                <UBadge
                  v-if="mod.type === 'external'"
                  variant="subtle"
                  color="primary"
                >
                  Externo
                </UBadge>
              </div>
              <div class="text-right mr-3">
                <UToggle
                  v-model="state.modules[mod.value].enabled"
                  label="Activado"
                  size="md"
                  :disabled="disabledFields?.includes('modules') || disabledFields?.includes(`modules.${mod.value}.enabled`)"
                  :title="state.modules[mod.value]?.enabled ? 'Acceso al módulo activado' : 'Acceso al módulo desactivado'"
                  @update:model-value="() => {
                    const { enabled, role } = state.modules[mod.value]
                    if (enabled && !role) state.modules[mod.value].role = 'view'
                    else if (!enabled) state.modules[mod.value].role = undefined
                  }"
                />
              </div>
              <div class="py-2">
                <SelectMenuBase
                  v-model="state.modules[mod.value].role"
                  label="Rol"
                  :options="optionsUsers.modulesRole"
                  :disabled="!state.modules[mod.value]?.enabled || disabledFields?.includes('modules') || disabledFields?.includes(`modules.${mod.value}.enabled`)"
                />
              </div>
            </div>
          </fieldset>
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.users"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
