import { AppDealsSlideover, FilesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppDealsSlideoverProps = ComponentProps<typeof AppDealsSlideover>

export type DealsUtils = ReturnType<typeof useDealsUtils>

export const useDeals = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Deal>('deals')

  const slideoverOpenCreate = (props: AppDealsSlideoverProps) => {
    return new Promise<Deal>((resolve, reject) => {
      const data = getDefaultDeal(props.data)
      slideover.open(AppDealsSlideover, {
        title: 'Crear negocio',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppDealsSlideoverProps) => {
    return new Promise<Deal>((resolve, reject) => {
      const data = getDefaultDeal(props.data)

      slideover.open(AppDealsSlideover, {
        title: 'Editar negocio',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    priority: z.string(),
    stage: z.string(),
    status: z.string(),
    rating: z.string(),
    name: z.string(),
    accountId: z.string(),
    campaignId: z.string(),
    userId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Deal, refresh: () => Promise<void>, utils: DealsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'deals', row, refresh, utils),
      getDropdownItem('copyId', 'deals', row, refresh, utils),
      getDropdownItem('copyUid', 'deals', row, refresh, utils),
      getDropdownItem('archive', 'deals', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'deals', row, refresh, utils),
      getDropdownItem('files', 'deals', row, refresh, utils),
      {
        label: 'Ver archivos (campaña)',
        icon: 'i-mdi-file-multiple',
        disabled: !row.campaignId && row.campaign?.files.length > 0,
        click: () => {
          slideover.open(FilesSlideover, { data: row.campaign, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    // Status Actions
    getDropdownItemGroup('status', 'deals', row, refresh, utils),
    // resource actions:
    [
      {
        label: 'Registrar actividad',
        icon: ICONS.activities,
        click: () => {
          const activities$ = useActivities()
          activities$.slideoverOpenCreate({
            data: {
              dealId: row.id,
              accountId: row.accountId,
              branchId: row.branchId,
            },
            disabledFields: ['dealId', 'accountId'],
          }).then(refresh)
        },
      },
      {
        label: 'Crear cotización',
        icon: ICONS.quotes,
        click: () => {
          const quotes$ = useQuotes()
          quotes$.slideoverOpenCreate({
            data: { dealId: row.id },
            disabledFields: ['dealId'],
          }).then(refresh)
        },
      },
      {
        label: 'Registrar orden de venta',
        icon: ICONS.salesorders,
        click: () => {
          const salesorders$ = useSalesorders()
          salesorders$.slideoverOpenCreate({
            data: { dealId: row.id },
            disabledFields: ['dealId'],
          }).then(refresh)
        },
      },
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'deals', row, refresh, utils),
      getDropdownItem('inmutable', 'deals', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'deals', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useDealsUtils = ({ $accounts, $branches, $campaigns, $inquiries, $contacts, $users, $quotes }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $campaigns?: Awaited<ReturnType<typeof useFetch<Campaign[]>>>
  $inquiries?: Awaited<ReturnType<typeof useFetch<Inquiry[]>>>
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
  $quotes?: Awaited<ReturnType<typeof useFetch<Quote[]>>>
} = {}) => {
  const options = {
    ...optionsDeals,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    campaignId: computed(() => $campaigns?.data.value.map(toOption)),
    inquiryId: computed(() => $inquiries?.data.value.map(toOption)),
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
    quoteId: computed(() => $quotes?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsDeals.type, 'value'),
    priority: toMapByKey(optionsDeals.priority, 'value'),
    stage: toMapByKey(optionsDeals.stage, 'value'),
    status: toMapByKey(optionsDeals.status, 'value'),
    rating: toMapByKey(optionsDeals.rating, 'value'),
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches?.data.value, 'id')),
    campaignId: computed(() => toMapByKey($campaigns?.data.value, 'id')),
    inquiryId: computed(() => toMapByKey($inquiries?.data.value, 'id')),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    campaignByType: computed(() => toMapByGroupKey($campaigns?.data.value, 'type')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches?.data.value, 'accountId')),
    contactByAccountId: computed(() => toMapByGroupKey($contacts?.data.value, 'accountId')),
    quoteByAccountId: computed(() => toMapByGroupKey($quotes?.data.value, 'accountId')),
    quotesByDealId: computed(() => toMapByGroupKey($quotes?.data.value, 'dealId')),
  }

  return { options, maps }
}
