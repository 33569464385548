import { ViewSettingsModal } from '#components'
import { download, generateCsv, mkConfig } from 'export-to-csv'
import { flatten } from 'flat'
import { get } from 'lodash-es'
import type { ComponentProps } from 'vue-component-type-helpers'
import type { Column } from '~/types'

export type Column = {
  key: string
  label?: string
  sortable?: boolean
  disabled?: boolean | undefined
  [key: string]: unknown
}

export type ViewSettings<T> = {
  record: string
  views: Option[]
  selectedView: string
  compact: boolean // view density
  columns: Column[]
  visibleColumns?: Column['key'][] // defaults to all columns except metadataColumns
  // [optional] grouping (used e.g. for kanban view):
  groups?: Option[]
  group?: string
}

export const COLUMNS_METADATA: Column[] = [
  {
    key: 'createdBy',
    label: 'Creado por',
  },
  {
    key: 'createdAt',
    label: 'Fecha de creación',
    sortable: true,
    class: 'text-center', // for <th>
    rowClass: 'text-center', // for <td>
  },
  {
    key: 'updatedBy',
    label: 'Actualizado por',
  },
  {
    key: 'updatedAt',
    label: 'Fecha de última actualización',
    sortable: true,
    class: 'text-center', // for <th>
    rowClass: 'text-center', // for <td>
  },
  {
    key: 'deletedBy',
    label: 'Archivado por',
  },
  {
    key: 'deletedAt',
    label: 'Fecha de archivado',
    sortable: true,
    class: 'text-center', // for <th>
    rowClass: 'text-center', // for <td>
  },
]

// IMPORTANT: useAsyncData/useFetch cannot return null or undefined
export const getUseAsyncDataArgsViewSettings = <T>(key: string, viewSettings: ViewSettings<T>) => [
  key,
  async () => {
    const $fetch = useRequestFetch() // IMPORTANT: send cookies along on SSR (pending merge https://github.com/nuxt/nuxt/issues/24813)
    return await $fetch(`/api/kv/${key}`) ?? viewSettings // no need to persist until updated for first time
  },
  {
    key,
    shallow: false,
    default: () => viewSettings,
  },
]

export function useView<T>(key: string, view: Ref<ViewSettings<T>>) {
  const modal = useModal()

  // NOTE: expose a readonly `columns` built from `view.visibleColumns` to ensure
  // proper column order when toggling columns on/off (otherwise columns lose order)
  const columns = computed<Column[]>(() => {
    const metadataColumns = COLUMNS_METADATA.map(c => c.key)
    const {
      columns,
      visibleColumns = view.value.columns.map(c => c.key).filter(c => !metadataColumns.includes(c)),
    } = view.value
    return columns.filter(column => visibleColumns.includes(column.key)) ?? []
  })

  const selected = ref<T[]>([] as T[])

  const modalOpenViewSettings = (props?: ComponentProps<typeof ViewSettingsModal>) => {
    modal.open(ViewSettingsModal, {
      title: props?.title ?? 'Ajustes de la vista',
      description: props?.description ?? 'Ajusta la vista predeterminada a tus necesidades',
      view: props?.view! ?? view.value,
      onUpdateView: props?.onUpdateView ?? onUpdateView,
      disabledFields: props?.disabledFields,
    })
  }

  async function onUpdateView(value: ViewSettings<T>) {
    view.value = value
    await $fetch(`/api/kv/${key}`, { method: 'PUT', body: value })
  }

  return {
    columns,
    selected,
    modalOpenViewSettings,
    onUpdateView,
  }
}

// query:

// filters: capture as ...filters using rest operator (object required for using ...rest operator)
export type ViewQuery = object & {
  deletedAt?: '$isNull' | '$isNotNull'
  $search?: { columns: string[], value: string }
  $sort?: { column: string, direction: 'asc' | 'desc' }
  $pagination?: { page: number, pageSize: number }
  $with?: Record<string, boolean>
} & {
  [k: `${string}:$gte`]: string
  [k: `${string}:$lte`]: string
  [k: `${string}:$gt`]: string
  [k: `${string}:$lt`]: string
  [k: string]: string | string[] | unknown // requires unknown
}

export function useViewQuery(initialQuery: ViewQuery) {
  const query = ref<ViewQuery>(structuredClone(initialQuery))
  const isFiltering = computed(() => {
    const { $search, $sort, $pagination, $with, ...allFilters } = query.value
    const { deletedAt, ...filters } = allFilters // exclude special filters
    return Object.entries(filters).some(([key, value]) => {
      if (Array.isArray(value)) return value.length > 0
      if (typeof value === 'string') return ![null, undefined, ''].includes(value)
      return value !== undefined
    })
  })
  const clearFilters = () => query.value = structuredClone<ViewQuery>(initialQuery)
  return { query, isFiltering, clearFilters }
}

// pagination:

export function useViewPagination<T>(allRows: Ref<T[]>, initialPageSize = 25) {
  const items = [
    [
      {
        label: '10',
        click: () => setPageSize(10),
      },
      {
        label: '25',
        click: () => setPageSize(25),
      },
      {
        label: '100',
        click: () => setPageSize(100),
      },
    ],
  ]

  const page = ref(1)

  const pageSize = ref(initialPageSize)

  const setPageSize = (count: number) => {
    pageSize.value = count
    page.value = 1 // Reset to the first page when page count changes
  }

  const total = computed(() => unref(allRows)?.length)

  const rows = computed(() => {
    return unref(allRows)?.slice(
      (page.value - 1) * pageSize.value,
      page.value * pageSize.value,
    )
  })

  return { rows, items, page, total, pageSize }
}

// server-pagination:

export type Paginated<T> = {
  total: number // <total number of records>
  limit: number // <max number of items per page>
  offset: number // <number of skipped items (offset)>
  data: T[]
} // inspired by https://feathersjs.com/api/databases/common#pagination

export function useViewServerPagination<T>(query: Ref<ViewQuery>, data: Ref<Paginated<T>>) {
  const items = [
    [
      {
        label: '10',
        click: () => setPageSize(10),
      },
      {
        label: '25',
        click: () => setPageSize(25),
      },
      {
        label: '100',
        click: () => setPageSize(100),
      },
    ],
  ]

  const page = ref(1)

  const pageSize = ref(25)

  const setPageSize = (count: number) => {
    pageSize.value = count
    page.value = 1 // Reset to the first page when page count changes
  }

  const total = computed(() => unref(data)?.total)

  const rows = computed(() => unref(data)?.data)

  watchEffect(() => {
    query.value.$pagination = { page: page.value, pageSize: pageSize.value }
  })

  return { rows, items, page, total, pageSize }
}

// download:

export function useViewDownload() {
  // reduce all rows into a rows with column.label as key, and only keep visibleColumns
  const getCsvRows = <T>(columns: Column[], rows: T[]) => {
    const flatData = rows.map(row => flatten(row))
    const cols = columns.filter(column => !['actions'].includes(column.key))
    return flatData.map((originalRow, index) => {
      return cols.reduce((row, col) => {
        let value = get(originalRow, col.key)
        value ??= '' // cast null/undefined values to empty strings "" for export
        row[col.label ?? col.key] = value
        return row
      }, {})
    })
  }

  // see https://medium.com/@j.lilian/how-to-export-react-tanstack-table-to-csv-file-722a22ccd9c5
  function downloadDataAsCsv<T>(
    flatData: T[],
    filename: string,
    columnHeaders?: Array<string | { key: string, displayLabel: string }>,
  ) {
    const csvConfig = mkConfig({
      fieldSeparator: ',',
      filename, // export file name (without .csv)
      decimalSeparator: '.',
      ...(columnHeaders ? { columnHeaders } : { useKeysAsHeaders: true }),
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const csv = generateCsv(csvConfig)<any>(flatData)
    download(csvConfig)(csv)
  }

  return {
    getCsvRows,
    downloadDataAsCsv,
  }
}
