import { defineComponent, h } from "vue";
export const Primitive = defineComponent({
  name: "Primitive",
  props: {
    as: {
      type: [String, Object],
      required: false
    }
  },
  setup(props, { slots }) {
    const asTag = props.as;
    if (asTag)
      return () => h(asTag, {}, { default: slots.default });
    return () => slots.default?.();
  }
});
