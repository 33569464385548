import { AppApikeysSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppApikeysSlideoverProps = ComponentProps<typeof AppApikeysSlideover>

export type ApikeysUtils = ReturnType<typeof useApikeysUtils>

export const useApikeys = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Apikey>('apikeys')

  // TODO: use custom endpoint POST /api/auth/apikeys instead of onSubmitPatch() to hash apiKey
  // const result = await $fetch<Apikey>('/api/auth/apikeys', { method: 'POST', body: event.data })
  const slideoverOpenCreate = (props: AppApikeysSlideoverProps) => {
    return new Promise<Apikey>((resolve, reject) => {
      const data = getDefaultApikey(props.data)
      slideover.open(AppApikeysSlideover, {
        title: 'Crear clave API',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data, false)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppApikeysSlideoverProps) => {
    return new Promise<Apikey>((resolve, reject) => {
      const data = getDefaultApikey(props.data)
      slideover.open(AppApikeysSlideover, {
        title: 'Editar clave API',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    name: z.string(),
    permission: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Apikey, refresh: () => Promise<void>, utils: ApikeysUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'apikeys', row, refresh, utils),
      getDropdownItem('copyId', 'apikeys', row, refresh, utils),
      getDropdownItem('archive', 'apikeys', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'apikeys', row, refresh, utils),
      getDropdownItem('files', 'apikeys', row, refresh, utils),
    ],
    [
      getDropdownItem('info', 'apikeys', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'apikeys', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useApikeysUtils = () => {
  const options = {
    ...optionsApikeys,
  }

  const maps = {
    permission: toMapByKey(optionsApikeys.permission, 'value'),
  }

  return { options, maps }
}
