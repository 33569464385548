<script setup lang="ts">
const model = defineModel<Serviceitem>({ required: true })

const props = defineProps<{
  utils: ServiceitemsUtils
  disabled?: boolean
}>()
</script>

<template>
  <div />
</template>
