<script setup lang="ts">
import type { ZodSchema } from 'zod'

const model = defineModel<Quoteitem | Salesorderitem>({ required: true })

defineProps<{
  resource: 'quoteitems' | 'salesorderitems'
  utils: QuoteitemsUtils | SalesorderitemsUtils
  schema: ZodSchema
  disabledFields?: (keyof Quoteitem | Salesorderitem | string)[]
  disabled?: boolean
}>()

const toWords = toSpelledCurrency(defaultAppVariables.currency as 'MXN' | 'USD')
const spelledCurrency = ref(toWords.convert(model.value?.amount ?? 0).toUpperCase())

const onUpdateAmountVariables = () => {
  const { quantity = 0, unitPrice = 0, discount } = model.value

  const amount = quantity * unitPrice || 0

  const discountAmount = discount!.type! === 'percent'
    ? (amount * (discount.value ?? 0))
    : (discount.value ?? 0)

  model.value.discount = { ...discount, amount: discountAmount }
  model.value.amount = amount - discountAmount
  spelledCurrency.value = toWords.convert(amount).toUpperCase()
}

const onUpdateDiscountType = () => {
  model.value.discount = {
    type: model.value.discount.type,
    value: null,
    amount: null,
  }
  onUpdateAmountVariables()
}
</script>

<template>
  <fieldset :class="FIELDSET_FULL_12" :disabled="disabled">
    <UFormGroup
      label="SKU"
      name="sku"
      :required="isRequired(schema, 'sku')"
      class="md:col-span-2"
    >
      <UInput
        v-model="model.sku"
        :disabled="disabledFields?.includes('sku')"
      />
    </UFormGroup>

    <UFormGroup
      label="Nombre"
      name="name"
      :required="isRequired(schema, 'name')"
      class="md:col-span-10"
    >
      <UInput
        v-model="model.name"
        :disabled="disabledFields?.includes('name')"
      />
    </UFormGroup>

    <UFormGroup
      label="Descripción"
      name="description"
      :required="isRequired(schema, 'description')"
      class="md:col-span-12"
    >
      <UTextarea
        v-model="model.description"
        resize
        :rows="1"
        :disabled="disabledFields?.includes('description')"
      />
    </UFormGroup>

    <UFormGroup
      label="Imagen"
      name="image"
      :required="isRequired(schema, 'image')"
      class="md:col-span-4"
    >
      <InputImage
        v-model="model.image"
        :query="{ prefix: `${resource}/${model.id}` }"
        :disabled="disabledFields?.includes('image')"
      />
    </UFormGroup>

    <UFormGroup
      label="Etiquetas"
      name="tags"
      class="md:col-span-4"
    >
      <SelectMenuCreatableString
        v-model="model.tags"
        :options="model.tags"
        creatable
      />
    </UFormGroup>

    <UFormGroup
      label="Archivos"
      name="files"
      class="md:col-span-4"
    >
      <InputFiles
        v-model="model.files"
        accept="*"
        :query="{ prefix: `${resource}/${model.id}/files` }"
      />
    </UFormGroup>

    <UDivider class="md:col-span-12 py-3" />

    <UFormGroup
      label="Cantidad"
      name="quantity"
      :required="isRequired(schema, 'quantity')"
      class="md:col-span-3"
    >
      <UInput
        v-model="model.quantity"
        type="number"
        inputmode="decimal"
        :min="0.00"
        :step="0.01"
        :disabled="disabledFields?.includes('quantity')"
        @update:model-value="onUpdateAmountVariables"
      />
    </UFormGroup>

    <UFormGroup
      :label="`Precio U (${defaultAppVariables.currency})`"
      name="unitPrice"
      :required="isRequired(schema, 'unitPrice')"
      class="md:col-span-3"
    >
      <InputCurrency
        v-model="model.unitPrice"
        :currency="defaultAppVariables.currency"
        icon
        :disabled="disabledFields?.includes('unitPrice')"
        @update:model-value="onUpdateAmountVariables"
      />
    </UFormGroup>

    <UFormGroup
      label="Descuento"
      name="discount"
      :required="isRequired(schema, 'discount')"
      class="md:col-span-6"
    >
      <div
        v-if="model.discount?.type === 'percent'"
        class="grid grid-cols-1 md:grid-cols-[1fr,2fr,3fr] gap-1"
      >
        <SelectMenuBase
          v-model="model.discount.type"
          :disabled="disabledFields?.includes('discount')"
          :options="utils.options.discount"
          @update:model-value="onUpdateDiscountType"
        />
        <InputPercent
          v-model="model.discount.value"
          :disabled="disabledFields?.includes('discount')"
          @update:model-value="onUpdateAmountVariables"
        />
        <InputReadOnly
          :model-value="toCurrency(isNaN(model.discount?.amount) ? 0 : (model.discount?.amount ?? 0), defaultAppVariables.currency)"
        />
      </div>

      <div
        v-else
        class="grid grid-cols-1 md:grid-cols-[1fr,4fr] gap-1"
      >
        <SelectMenuBase
          v-model="model.discount.type"
          :disabled="disabledFields?.includes('discount')"
          :options="utils.options.discount"
          @update:model-value="onUpdateDiscountType"
        />
        <InputPercent
          v-if="model.discount.type === 'percent'"
          v-model="model.discount.value"
          :disabled="disabledFields?.includes('discount')"
          @update:model-value="onUpdateAmountVariables"
        />
        <UInput
          v-else
          v-model="model.discount.value"
          inputmode="decimal"
          :min="0.00"
          :step="0.01"
          :disabled="disabledFields?.includes('discount')"
          @update:model-value="onUpdateAmountVariables"
        />
      </div>
    </UFormGroup>

    <div class="md:col-span-9" />

    <UFormGroup
      :label="`Importe (${defaultAppVariables.currency})`"
      name="amount"
      :required="isRequired(schema, 'amount')"
      class="md:col-span-3"
      :ui="{
        help: 'text-xs',
      }"
      :help="model?.amount === 0 ? '' : toWords.convert(model?.amount ?? 0).toUpperCase()"
    >
      <InputReadOnly
        :model-value="toCurrency(isNaN(model.amount) ? 0 : (model.amount ?? 0), defaultAppVariables.currency)"
      />
    </UFormGroup>
  </fieldset>
</template>
