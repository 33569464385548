export function countByKey<T>(
  array: T[],
  keyGetter: (item: T) => string,
): Array<T & { count: number }> {
  const result = array?.reduce(
    (acc, curr) => {
      const value = keyGetter(curr)
      if (!acc[value]) acc[value] = { ...curr, count: 0 }
      acc[value].count += 1
      return acc
    },
    {} as Record<string, T & { count: number }>,
  )

  return Object.values(result)
}

export function sumByKey<T>(array: T[], keyGetter: (item: T) => string): number {
  return array?.reduce((acc, curr) => acc + Number(keyGetter(curr)), 0) ?? 0
}
