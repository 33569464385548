import { format } from 'date-fns'
import { get } from 'lodash-es'
import { customAlphabet } from 'nanoid'

// factories (NOTE: cannot re-export from  server-side`schema.ts` since migration fails):

export const createId = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 12)

export const createUid = (today: Date, prefix: string): string => {
  const suffix = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 3)
  const formattedDate = format(today, 'yyyyMMdd') // e.g. '20240824'
  return `${prefix}-${formattedDate}-${suffix()}`
}

export const createApikey = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 64)

// options:

// avoid using Record<> to allow recursive types (see https://stackoverflow.com/a/76583105/23545143)
export type Options = { [k: string]: Option[] | Options }

export type Option = {
  value: string | number
  label: string
  icon?: string
  avatar?: { src?: string, alt: string }
  color?: string
  disabled?: boolean
  className?: string
  [k: string]: unknown // extra properties e.g. description
}

export const optionsShared = {
  record: [
    {
      value: 'apikeys',
      label: 'Claves API',
      description: 'Las <strong>Claves API</strong> permiten acceder al sistema desde aplicaciones externas de manera segura. Cada clave tiene permisos configurables para proteger la información y las operaciones.',
      icon: 'i-mdi-key',
    },
    {
      value: 'accounts',
      label: 'Cuentas',
      description: 'Una <strong>Cuenta</strong> es un registro de empresa o entidad con la que se tiene relación comercial. Puede representar clientes, proveedores o socios. Cada cuenta puede tener múltiples contactos y negocios asociados.',
      icon: 'i-mdi-office-building',
    },
    {
      value: 'activities',
      label: 'Actividades',
      description: 'Las <strong>Actividades</strong> son registros de interacciones comerciales tante entrada como de salida.',
      icon: 'i-mdi-calendar',
    },
    {
      value: 'applications',
      label: 'Aplicaciones',
      description: 'Las <strong>Aplicaciones</strong> son registros de casos de uso de los productos o servicios de la empresa.',
      icon: 'i-mdi-apps',
    },
    {
      value: 'auditlogs',
      label: 'Registros de Auditoría',
      description: 'Los <strong>Registros de Auditoría</strong> documentan cambios y eventos importantes en el sistema. Incluyen información como el usuario, la acción realizada y la fecha.',
      icon: 'i-mdi-history',
    },
    {
      value: 'banks',
      label: 'Bancos',
      description: 'Los <strong>Bancos</strong> representan instituciones financieras asociadas con las operaciones de la empresa.',
      icon: 'i-mdi-bank',
    },
    {
      value: 'branches',
      label: 'Sucursales',
      description: 'Las <strong>Sucursales</strong> son registros de las ubicaciones físicas de los clientes, proveedores o socios de la empresa.',
      icon: 'i-mdi-map-marker',
    },
    {
      value: 'campaigns',
      label: 'Campañas',
      description: 'Las <strong>Campañas</strong> son iniciativas de marketing o ventas diseñadas para alcanzar objetivos específicos. Todos los <strong>Negocios</strong> requieren una campaña asociada.',
      icon: 'i-mdi-bullhorn',
    },
    {
      value: 'categories',
      label: 'Categorías',
      description: 'Las <strong>Categorías</strong> se utilizan para organizar y agrupar de manera eficiente productos y servicios facilitando su clasificación, búsqueda y gestión dentro del sistema',
      icon: 'i-mdi-label-multiple',
    },
    {
      value: 'contacts',
      label: 'Contactos',
      description: 'Los <strong>Contactos</strong> representan personas o entidades con las que la empresa tiene relaciones comerciales.',
      icon: 'i-mdi-account-group',
    },
    {
      value: 'deals',
      label: 'Negocios',
      description: 'Los <strong>Negocios</strong> representan registros de oportunidades comerciales que pueden asociarse a contactos, actividades y cotizaciones, facilitando un seguimiento detallado y una gestión organizada de cada caso.',
      icon: 'i-mdi-tag',
    },
    {
      value: 'financialaccounts',
      label: 'Cuentas Financieras',
      description: 'Las <strong>Cuentas Financieras</strong> gestionan activos monetarios de la empresa, como cuentas bancarias o cajas.',
      icon: 'i-mdi-cash-register',
    },
    {
      value: 'industries',
      label: 'Industrias',
      description: 'Las <strong>Industrias</strong> agrupan empresas según el sector económico al que pertenecen. El catálogo de industrias facilita la segmentación y análisis de clientes y cuentas.',
      icon: 'i-mdi-office-building',
    },
    {
      value: 'inquiries',
      label: 'Solicitudes',
      description: 'Las <strong>Solicitudes</strong> son registros de solicitudes comerciales recibidas por la empresa.',
      icon: 'i-mdi-message-text',
    },
    {
      value: 'issues',
      label: 'Incidencias',
      description: 'Las <strong>Incidencias</strong> documentan problemas o errores reportados dentro del sistema. Se gestionan para resolverlos de manera eficiente.',
      icon: 'i-mdi-radiobox-marked',
    },
    {
      value: 'messages',
      label: 'Mensajes',
      description: 'Los <strong>Mensajes</strong> son comunicaciones internas o externas enviadas desde el sistema.',
      icon: 'i-mdi-android-messages',
    },
    {
      value: 'notes',
      label: 'Notas',
      description: 'Las <strong>Notas</strong> son comentarios o recordatorios relacionados con contactos, negocios, o actividades. Facilitan el seguimiento de información relevante.',
      icon: 'i-mdi-note-text',
    },
    {
      value: 'notifications',
      label: 'Notificaciones',
      description: 'Las <strong>Notificaciones</strong> informan a los usuarios sobre eventos importantes o cambios en el sistema. Pueden ser configuradas para eventos específicos.',
      icon: 'i-mdi-bell',
    },
    {
      value: 'pricelists',
      label: 'Listas de Precios',
      description: 'Las <strong>Listas de Precios</strong> definen precios para productos y servicios.',
      icon: 'i-mdi-view-list',
    },
    {
      value: 'pricelistitems',
      label: 'Items',
      labelFull: 'Items de Listas de Precios',
      description: 'Los <strong>Precios</strong> detallan valores individuales dentro de una lista de precios.',
      icon: 'i-mdi-barcode',
    },
    {
      value: 'productionorders',
      label: 'Órdenes de Producción',
      description: 'Las <strong>Órdenes de Producción</strong> organizan los procesos de manufactura. Incluyen detalles de productos a fabricar y materiales necesarios.',
      icon: 'i-mdi-package-variant-closed',
    },
    {
      value: 'productionorderitems',
      label: 'Items',
      labelFull: 'Items de Órdenes de Producción',
      description: 'Los <strong>Items de Órdenes de Producción</strong> especifican componentes o productos individuales dentro de una orden de producción.',
      icon: 'i-mdi-barcode',
    },
    {
      value: 'products',
      label: 'Productos',
      description: 'Los <strong>Productos</strong> son bienes físicos que la empresa ofrece.',
      icon: 'i-mdi-package-variant-closed',
    },
    {
      value: 'productitems',
      label: 'Items',
      labelFull: 'Items de Productos',
      description: 'Los <strong>Items de Productos</strong> representan unidades específicas o variaciones de un producto.',
      icon: 'i-mdi-barcode',
    },
    {
      value: 'quotes',
      label: 'Cotizaciones',
      description: 'Las <strong>Cotizaciones</strong> representan propuestas de precios enviadas a clientes. Incluyen detalles de productos o servicios ofrecidos y sus precios.',
      icon: 'i-mdi-file-document-edit',
    },
    {
      value: 'quoteitems',
      label: 'Items',
      labelFull: 'Items de Cotizaciones',
      description: 'Los <strong>Items de Cotizaciones</strong> son elementos individuales dentro de una cotización, como productos o servicios específicos.',
      icon: 'i-mdi-barcode',
    },
    {
      value: 'salesorders',
      label: 'Órdenes de Venta',
      description: 'Las <strong>Órdenes de Venta</strong> documentan pedidos confirmados de clientes. Incluyen detalles de productos, cantidades y precios finales.',
      icon: 'i-mdi-cart',
    },
    {
      value: 'salesorderitems',
      label: 'Items',
      labelFull: 'Items de Órdenes de Venta',
      description: 'Los <strong>Items de Órdenes de Venta</strong> son los productos o servicios específicos incluidos en una orden de venta.',
      icon: 'i-mdi-barcode',
    },
    {
      value: 'services',
      label: 'Servicios',
      description: 'Los <strong>Servicios</strong> son servicios ofrecidas a los clientes.',
      icon: 'i-mdi-tools',
    },
    {
      value: 'serviceitems',
      label: 'Items',
      labelFull: 'Items de Servicios',
      description: 'Los <strong>Items de Servicios</strong> representan variantes o detalles específicos de un servicio.',
      icon: 'i-mdi-barcode',
    },
    {
      value: 'serviceorders',
      label: 'Órdenes de Servicio',
      description: 'Las <strong>Órdenes de Servicio</strong> documentan trabajos o servicios programados para clientes.',
      icon: 'i-mdi-tools',
    },
    {
      value: 'serviceorderitems',
      label: 'Items',
      labelFull: 'Items de Órdenes de Servicio',
      description: 'Los <strong>Items de Órdenes de Servicio</strong> detallan servicios específicos dentro de una orden de servicio.',
      icon: 'i-mdi-barcode',
    },
    {
      value: 'tasks',
      label: 'Tareas',
      description: 'Las <strong>Tareas</strong> son actividades asignadas a usuarios o equipos dentro del sistema. Facilitan el seguimiento de responsabilidades y plazos.',
      icon: 'i-mdi-checkbox-marked-circle',
    },
    {
      value: 'transactions',
      label: 'Transacciones',
      description: 'Las <strong>Transacciones</strong> documentan movimientos financieros realizados en el sistema. Incluyen pagos, transferencias y ajustes contables.',
      icon: 'i-mdi-swap-horizontal-circle',
    },
    {
      value: 'users',
      label: 'Usuarios',
      description: 'Los <strong>Usuarios</strong> son las personas que tienen acceso al sistema. Cada usuario puede tener roles y permisos específicos.',
      icon: 'i-mdi-account',
    },
  ].toSorted((a, b) => a.value.localeCompare(b.value)),
  authProvider: [
    {
      value: 'email',
      label: 'Email',
      icon: 'i-mdi-email',
    },
    {
      value: 'google',
      label: 'Google',
      icon: 'i-logos-google-icon',
    },
    {
      value: 'github',
      label: 'GitHub',
      icon: 'i-mdi-github',
    },
    {
      value: 'microsoft',
      label: 'Microsoft',
      icon: 'i-logos-microsoft-icon',
    },
    {
      value: 'facebook',
      label: 'Facebook',
      icon: 'i-logos-facebook',
    },
    {
      value: 'linkedin',
      label: 'LinkedIn',
      icon: 'i-logos-linkedin-icon',
    },
    {
      value: 'apple',
      label: 'Apple',
      icon: 'i-mdi-apple',
    },
  ],
  alert: [
    { value: 'info', label: 'Información', icon: 'i-mdi-information', color: 'blue' },
    { value: 'warning', label: 'Advertencia', icon: 'i-mdi-alert', color: 'amber' },
    { value: 'error', label: 'Error', icon: 'i-mdi-alert-circle', color: 'red' },
    { value: 'success', label: 'Éxito', icon: 'i-mdi-check-circle', color: 'green' },
  ],
  // from https://gist.github.com/keeguon/2310008?permalink_comment_id=4970109#gistcomment-4970109
  countryCode: [
    { value: 'AF', label: 'Afganistan', dial: '93' },
    { value: 'AL', label: 'Albania', dial: '355' },
    { value: 'DE', label: 'Alemania', dial: '49' },
    { value: 'AD', label: 'Andorra', dial: '376' },
    { value: 'AO', label: 'Angola', dial: '244' },
    { value: 'AI', label: 'Anguila', dial: '1264' },
    { value: 'AQ', label: 'Antartida', dial: '672' },
    { value: 'AG', label: 'Antigua Y Barbuda', dial: '1268' },
    { value: 'AN', label: 'Antillas Neerlandesas', dial: '599' },
    { value: 'SA', label: 'Arabia Saudita', dial: '966' },
    { value: 'DZ', label: 'Argelia', dial: '213' },
    { value: 'AR', label: 'Argentina', dial: '54' },
    { value: 'AM', label: 'Armenia', dial: '374' },
    { value: 'AW', label: 'Aruba', dial: '297' },
    { value: 'AU', label: 'Australia', dial: '61' },
    { value: 'AT', label: 'Austria', dial: '43' },
    { value: 'AZ', label: 'Azerbaiyan', dial: '994' },
    { value: 'BS', label: 'Bahamas', dial: '1242' },
    { value: 'BD', label: 'Banglades', dial: '880' },
    { value: 'BB', label: 'Barbados', dial: '1246' },
    { value: 'BH', label: 'Barein', dial: '973' },
    { value: 'BE', label: 'Belgica', dial: '32' },
    { value: 'BZ', label: 'Belice', dial: '501' },
    { value: 'BJ', label: 'Benin', dial: '229' },
    { value: 'BM', label: 'Bermudas', dial: '1441' },
    { value: 'BY', label: 'Bielorrusia', dial: '375' },
    { value: 'MM', label: 'Birmania', dial: '95' },
    { value: 'BO', label: 'Bolivia', dial: '591' },
    { value: 'BA', label: 'Bosnia Y Herzegovina', dial: '387' },
    { value: 'BW', label: 'Botsuana', dial: '267' },
    { value: 'BR', label: 'Brasil', dial: '55' },
    { value: 'BN', label: 'Brunei', dial: '673' },
    { value: 'BG', label: 'Bulgaria', dial: '359' },
    { value: 'BF', label: 'Burkina Faso', dial: '226' },
    { value: 'BI', label: 'Burundi', dial: '257' },
    { value: 'BT', label: 'Butan', dial: '975' },
    { value: 'CV', label: 'Cabo Verde', dial: '238' },
    { value: 'KH', label: 'Camboya', dial: '855' },
    { value: 'CM', label: 'Camerun', dial: '237' },
    { value: 'CA', label: 'Canada', dial: '1' },
    { value: 'QA', label: 'Catar', dial: '974' },
    { value: 'TD', label: 'Chad', dial: '235' },
    { value: 'CL', label: 'Chile', dial: '56' },
    { value: 'CN', label: 'China', dial: '86' },
    { value: 'CY', label: 'Chipre', dial: '357' },
    { value: 'CO', label: 'Colombia', dial: '57' },
    { value: 'KM', label: 'Comoras', dial: '269' },
    { value: 'CG', label: 'Congo', dial: '242' },
    { value: 'KP', label: 'Corea Del Norte', dial: '850' },
    { value: 'KR', label: 'Corea Del Sur', dial: '82' },
    { value: 'CI', label: 'Costa De Marfil', dial: '225' },
    { value: 'CR', label: 'Costa Rica', dial: '506' },
    { value: 'HR', label: 'Croacia', dial: '385' },
    { value: 'CU', label: 'Cuba', dial: '53' },
    { value: 'DK', label: 'Dinamarca', dial: '45' },
    { value: 'DM', label: 'Dominica', dial: '1767' },
    { value: 'EC', label: 'Ecuador', dial: '593' },
    { value: 'EG', label: 'Egipto', dial: '20' },
    { value: 'SV', label: 'El Salvador', dial: '503' },
    { value: 'AE', label: 'Emiratos Arabes Unidos', dial: '971' },
    { value: 'ER', label: 'Eritrea', dial: '291' },
    { value: 'SK', label: 'Eslovaquia', dial: '421' },
    { value: 'SI', label: 'Eslovenia', dial: '386' },
    { value: 'ES', label: 'Espana', dial: '34' },
    { value: 'US', label: 'Estados Unidos', dial: '1' },
    { value: 'EE', label: 'Estonia', dial: '372' },
    { value: 'ET', label: 'Etiopia', dial: '251' },
    { value: 'PH', label: 'Filipinas', dial: '63' },
    { value: 'FI', label: 'Finlandia', dial: '358' },
    { value: 'FJ', label: 'Fiyi', dial: '679' },
    { value: 'FR', label: 'Francia', dial: '33' },
    { value: 'GA', label: 'Gabon', dial: '241' },
    { value: 'GM', label: 'Gambia', dial: '220' },
    { value: 'GE', label: 'Georgia', dial: '995' },
    { value: 'GS', label: 'Georgia Del Sur Y Las Islas Sandwich Del Sur', dial: '500' },
    { value: 'GH', label: 'Ghana', dial: '233' },
    { value: 'GI', label: 'Gibraltar', dial: '350' },
    { value: 'GD', label: 'Granada', dial: '1473' },
    { value: 'GR', label: 'Grecia', dial: '30' },
    { value: 'GL', label: 'Groenlandia', dial: '299' },
    { value: 'GP', label: 'Guadalupe', dial: '590' },
    { value: 'GU', label: 'Guam', dial: '1671' },
    { value: 'GT', label: 'Guatemala', dial: '502' },
    { value: 'GF', label: 'Guayana Francesa', dial: '594' },
    { value: 'GG', label: 'Guernsey', dial: '44' },
    { value: 'GN', label: 'Guinea', dial: '224' },
    { value: 'GQ', label: 'Guinea Ecuatorial', dial: '240' },
    { value: 'GW', label: 'Guinea-Bissau', dial: '245' },
    { value: 'GY', label: 'Guyana', dial: '595' },
    { value: 'HT', label: 'Haiti', dial: '509' },
    { value: 'HN', label: 'Honduras', dial: '504' },
    { value: 'HK', label: 'Hong Kong', dial: '852' },
    { value: 'HU', label: 'Hungria', dial: '36' },
    { value: 'IN', label: 'India', dial: '91' },
    { value: 'ID', label: 'Indonesia', dial: '62' },
    { value: 'IQ', label: 'Irak', dial: '964' },
    { value: 'IR', label: 'Iran', dial: '98' },
    { value: 'IE', label: 'Irlanda', dial: '353' },
    { value: 'BV', label: 'Isla Bouvet' },
    { value: 'IM', label: 'Isla De Man', dial: '44' },
    { value: 'CX', label: 'Isla De Navidad', dial: '61' },
    { value: 'NF', label: 'Isla Norfolk', dial: '672' },
    { value: 'IS', label: 'Islandia', dial: '354' },
    { value: 'AX', label: 'Islas Aland', dial: '358' },
    { value: 'KY', label: 'Islas Caiman', dial: ' 345' },
    { value: 'CC', label: 'Islas Cocos', dial: '61' },
    { value: 'CK', label: 'Islas Cook', dial: '682' },
    { value: 'FO', label: 'Islas Feroe', dial: '298' },
    { value: 'HM', label: 'Islas Heard Y Mcdonald' },
    { value: 'FK', label: 'Islas Malvinas', dial: '500' },
    { value: 'MP', label: 'Islas Marianas Del Norte', dial: '1670' },
    { value: 'MH', label: 'Islas Marshall', dial: '692' },
    { value: 'SB', label: 'Islas Salomon', dial: '677' },
    { value: 'TC', label: 'Islas Turcas Y Caicos', dial: '1649' },
    { value: 'UM', label: 'Islas Ultramarinas Menores De Estados Unidos' },
    { value: 'VG', label: 'Islas Virgenes Britanicas', dial: '1284' },
    { value: 'VI', label: 'Islas Virgenes De Los Estados Unidos', dial: '1340' },
    { value: 'IL', label: 'Israel', dial: '972' },
    { value: 'IT', label: 'Italia', dial: '39' },
    { value: 'JM', label: 'Jamaica', dial: '1876' },
    { value: 'JP', label: 'Japon', dial: '81' },
    { value: 'JE', label: 'Jersey', dial: '44' },
    { value: 'JO', label: 'Jordania', dial: '962' },
    { value: 'KZ', label: 'Kazajistan', dial: '77' },
    { value: 'KE', label: 'Kenia', dial: '254' },
    { value: 'KG', label: 'Kirguistan', dial: '996' },
    { value: 'KI', label: 'Kiribati', dial: '686' },
    { value: 'KW', label: 'Kuwait', dial: '965' },
    { value: 'LA', label: 'Laos', dial: '856' },
    { value: 'LS', label: 'Lesoto', dial: '266' },
    { value: 'LV', label: 'Letonia', dial: '371' },
    { value: 'LB', label: 'Libano', dial: '961' },
    { value: 'LR', label: 'Liberia', dial: '231' },
    { value: 'LY', label: 'Libia', dial: '218' },
    { value: 'LI', label: 'Liechtenstein', dial: '423' },
    { value: 'LT', label: 'Lituania', dial: '370' },
    { value: 'LU', label: 'Luxemburgo', dial: '352' },
    { value: 'MO', label: 'Macao', dial: '853' },
    { value: 'MK', label: 'Macedonia', dial: '389' },
    { value: 'MG', label: 'Madagascar', dial: '261' },
    { value: 'MY', label: 'Malasia', dial: '60' },
    { value: 'MW', label: 'Malaui', dial: '265' },
    { value: 'MV', label: 'Maldivas', dial: '960' },
    { value: 'ML', label: 'Mali', dial: '223' },
    { value: 'MT', label: 'Malta', dial: '356' },
    { value: 'MA', label: 'Marruecos', dial: '212' },
    { value: 'MQ', label: 'Martinica', dial: '596' },
    { value: 'MU', label: 'Mauricio', dial: '230' },
    { value: 'MR', label: 'Mauritania', dial: '222' },
    { value: 'YT', label: 'Mayotte', dial: '262' },
    { value: 'MX', label: 'Mexico', dial: '52' },
    { value: 'FM', label: 'Micronesia', dial: '691' },
    { value: 'MD', label: 'Moldavia', dial: '373' },
    { value: 'MC', label: 'Monaco', dial: '377' },
    { value: 'MN', label: 'Mongolia', dial: '976' },
    { value: 'MS', label: 'Montserrat', dial: '1664' },
    { value: 'MZ', label: 'Mozambique', dial: '258' },
    { value: 'NA', label: 'Namibia', dial: '264' },
    { value: 'NR', label: 'Nauru', dial: '674' },
    { value: 'NP', label: 'Nepal', dial: '977' },
    { value: 'NI', label: 'Nicaragua', dial: '505' },
    { value: 'NE', label: 'Niger', dial: '227' },
    { value: 'NG', label: 'Nigeria', dial: '234' },
    { value: 'NU', label: 'Niue', dial: '683' },
    { value: 'NO', label: 'Noruega', dial: '47' },
    { value: 'NC', label: 'Nueva Caledonia', dial: '687' },
    { value: 'NZ', label: 'Nueva Zelanda', dial: '64' },
    { value: 'OM', label: 'Oman', dial: '968' },
    { value: 'NL', label: 'Paises Bajos', dial: '31' },
    { value: 'PK', label: 'Pakistan', dial: '92' },
    { value: 'PW', label: 'Palaos', dial: '680' },
    { value: 'PS', label: 'Palestina', dial: '970' },
    { value: 'PA', label: 'Panama', dial: '507' },
    { value: 'PG', label: 'Papua Nueva Guinea', dial: '675' },
    { value: 'PY', label: 'Paraguay', dial: '595' },
    { value: 'PE', label: 'Peru', dial: '51' },
    { value: 'PN', label: 'Pitcairn', dial: '872' },
    { value: 'PF', label: 'Polinesia Francesa', dial: '689' },
    { value: 'PL', label: 'Polonia', dial: '48' },
    { value: 'PT', label: 'Portugal', dial: '351' },
    { value: 'PR', label: 'Puerto Rico', dial: '1939' },
    { value: 'GB', label: 'Reino Unido', dial: '44' },
    { value: 'CF', label: 'Republica Centroafricana', dial: '236' },
    { value: 'CZ', label: 'Republica Checa', dial: '420' },
    { value: 'CD', label: 'Republica Democratica Del Congo', dial: '243' },
    { value: 'DO', label: 'Republica Dominicana', dial: '1849' },
    { value: 'RE', label: 'Reunion', dial: '262' },
    { value: 'RW', label: 'Ruanda', dial: '250' },
    { value: 'RO', label: 'Rumania', dial: '40' },
    { value: 'RU', label: 'Rusia', dial: '7' },
    { value: 'EH', label: 'Sahara Occidental' },
    { value: 'WS', label: 'Samoa', dial: '685' },
    { value: 'AS', label: 'Samoa Americana', dial: '1684' },
    { value: 'KN', label: 'San Cristobal Y Nieves', dial: '1869' },
    { value: 'SM', label: 'San Marino', dial: '378' },
    { value: 'PM', label: 'San Pedro Y Miquelon', dial: '508' },
    { value: 'VC', label: 'San Vicente Y Las Granadinas', dial: '1784' },
    { value: 'SH', label: 'Santa Helena', dial: '290' },
    { value: 'LC', label: 'Santa Lucia', dial: '1758' },
    { value: 'VA', label: 'Santa Sede (Ciudad Del Vaticano)', dial: '379' },
    { value: 'ST', label: 'Santo Tome Y Principe', dial: '239' },
    { value: 'SN', label: 'Senegal', dial: '221' },
    { value: 'CS', label: 'Serbia Y Montenegro' },
    { value: 'SC', label: 'Seychelles', dial: '248' },
    { value: 'SL', label: 'Sierra Leona', dial: '232' },
    { value: 'SG', label: 'Singapur', dial: '65' },
    { value: 'SY', label: 'Siria', dial: '963' },
    { value: 'SO', label: 'Somalia', dial: '252' },
    { value: 'LK', label: 'Sri Lanka', dial: '94' },
    { value: 'SZ', label: 'Suazilandia', dial: '268' },
    { value: 'ZA', label: 'Sudafrica', dial: '27' },
    { value: 'SD', label: 'Sudan', dial: '249' },
    { value: 'SE', label: 'Suecia', dial: '46' },
    { value: 'CH', label: 'Suiza', dial: '41' },
    { value: 'SR', label: 'Surinam', dial: '597' },
    { value: 'SJ', label: 'Svalbard Y Jan Mayen', dial: '47' },
    { value: 'TH', label: 'Tailandia', dial: '66' },
    { value: 'TW', label: 'Taiwan', dial: '886' },
    { value: 'TZ', label: 'Tanzania', dial: '255' },
    { value: 'TJ', label: 'Tayikistan', dial: '992' },
    { value: 'IO', label: 'Territorio Britanico Del Oceano Indico', dial: '246' },
    { value: 'TF', label: 'Territorios Australes Franceses' },
    { value: 'TL', label: 'Timor Oriental', dial: '670' },
    { value: 'TG', label: 'Togo', dial: '228' },
    { value: 'TK', label: 'Tokelau', dial: '690' },
    { value: 'TO', label: 'Tonga', dial: '676' },
    { value: 'TT', label: 'Trinidad Y Tobago', dial: '1868' },
    { value: 'TN', label: 'Tunez', dial: '216' },
    { value: 'TM', label: 'Turkmenistan', dial: '993' },
    { value: 'TR', label: 'Turquia', dial: '90' },
    { value: 'TV', label: 'Tuvalu', dial: '688' },
    { value: 'UA', label: 'Ucrania', dial: '380' },
    { value: 'UG', label: 'Uganda', dial: '256' },
    { value: 'UY', label: 'Uruguay', dial: '598' },
    { value: 'UZ', label: 'Uzbekistan', dial: '998' },
    { value: 'VU', label: 'Vanuatu', dial: '678' },
    { value: 'VE', label: 'Venezuela', dial: '58' },
    { value: 'VN', label: 'Vietnam', dial: '84' },
    { value: 'WF', label: 'Wallis Y Futuna', dial: '681' },
    { value: 'YE', label: 'Yemen', dial: '967' },
    { value: 'DJ', label: 'Yibuti', dial: '253' },
    { value: 'ZM', label: 'Zambia', dial: '260' },
    { value: 'ZW', label: 'Zimbabue', dial: '263' },
  ],
  i18n: [
    { value: 'es-MX', label: 'Español' },
    { value: 'en-US', label: 'English' },
  ],
  views: [
    { value: 'table', label: 'Tabla', icon: 'i-mdi-table' },
    { value: 'cards', label: 'Tarjetas', icon: 'i-mdi-view-module' },
    { value: 'gallery', label: 'Galería', icon: 'i-mdi-view-grid' },
    { value: 'inbox', label: 'Vista previa', icon: 'i-mdi-view-split-vertical' },
    { value: 'kanban', label: 'Kanban', icon: 'i-mdi-view-column' },
    { value: 'calendar', label: 'Calendario', icon: 'i-mdi-calendar' },
  ],
  currency: [
    { value: 'USD', label: 'USD', title: 'USD - Dólar Americano', description: 'Dólar americano', color: 'green' },
    { value: 'MXN', label: 'MXN', title: 'MXN - Peso Mexicano', description: 'Peso mexicano', color: 'blue' },
  ],
  deletedAt: [
    { label: 'Activos', value: '$isNull', icon: 'i-mdi-inbox' },
    { label: 'Archivados', value: '$isNotNull', icon: 'i-mdi-archive' },
  ],
} as const satisfies Record<string, Option[]>

export const ICONS: Record<TRecord, `i-mdi-${string}`> = optionsShared.record.reduce((acc, option) => ({
  ...acc,
  [option.value as TRecord]: option.icon as `i-mdi-${string}`,
}), {} as Record<TRecord, `i-mdi-${string}`>)

// transformations:

/** Simple heuristic to convert an object to an Option (usage: ITEMS.map(toOption)) */
export const toOption = ({
  id,
  key,
  name,
  uid,
  title,
  description,
  i18n,
  ...row
}: Record<string, unknown>): Option => {
  const value = (row?.value ?? id ?? key) as string
  let label = (row?.label ?? name ?? title ?? uid) as string
  if (!label) {
    const { es = {}, en = {} } = (i18n ?? {}) as Record<string, Record<string, string>>
    if (es) label = (es.name ?? es.title ?? es.description ?? es.label ?? value) as string
    else if (en) label = (en.name ?? en.title ?? en.description ?? en.label ?? value) as string
  }
  const src = row?.image as string
  const avatar = src ? { src, alt: label } : { alt: label } // else return `alt` to render initials
  const color = row?.color as string
  return { ...row, value, label, avatar, color, description }
}

export const toFilteredOptions = (
  data: Record<string, unknown>[] = [],
  itemKey: string,
  valueKey: string,
  labelKey: string,
): Option[] => {
  const allOptions = data
    .map(item => item[itemKey])
    .filter(Boolean)
    .map(item => ({
      value: item[valueKey],
      label: item[labelKey],
      avatar: item.avatar ?? item.image ? { src: item.image as string, alt: item[labelKey] } : { alt: item[labelKey] },
    })) as Option[]

  const seenKeys = new Set()
  const uniqueOptions: Option[] = []

  allOptions.forEach((item) => {
    if (!seenKeys.has(item.value)) {
      seenKeys.add(item.value)
      uniqueOptions.push(item)
    }
  })

  return uniqueOptions
}

// WORKAROUND: omit avatar for performance until @nuxt/ui-pro@v3 lands with fix
// see https://github.com/nuxt/ui/issues/388 and https://github.com/nuxt/ui/issues/280
export const toOptionWithoutAvatar = (row: Record<string, unknown>): Option => {
  const { avatar, ...rest } = toOption(row)
  return rest
}

// avoids calling .find() for each row (O(n^2) -> O(n)) and uses a
// Map instead of a plain object (via .reduce()) for much better performance
export const toMapByKey = <T extends object>(array: T[] = [], key: keyof T): Map<T[keyof T], T> => {
  const map = new Map<T[keyof T], T>()
  if (!Array.isArray(array)) return map
  for (const item of array) {
    const value = get(item, key)
    if (value) map.set(value, item)
  }
  return map
}

export const toMapByGroupKey = <T extends object, K extends keyof T>(array: T[] = [], key: K): Map<T[K], T[]> => {
  const map = new Map<T[K], T[]>()
  if (!Array.isArray(array)) return map

  for (const item of array) {
    const value = item[key]

    if (value !== undefined && value !== null) {
      if (!map.has(value)) {
        map.set(value, [])
      }
      map.get(value)?.push(item)
    }
  }

  return map
}

export const toMapByKeyArrayValue = <T extends object, K extends keyof T>(
  array: T[] = [],
  key: K,
): Map<T[K] extends (infer U)[] ? U : never, T[]> => {
  const map = new Map<T[K] extends (infer U)[] ? U : never, T[]>()

  for (const item of array) {
    const value = item[key]
    // Ensure value is an array
    if (Array.isArray(value)) {
      for (const subValue of value) {
        if (!map.has(subValue)) {
          map.set(subValue, [])
        }
        map.get(subValue)?.push(item)
      }
    }
  }

  return map
}

export const toMapByKeyFromObject = <T extends object>(object: Record<string, T[]>, key: keyof T): Record<string, Map<T[keyof T], T>> => {
  const map = {} as Record<string, Map<T[keyof T], T>>
  for (const [property, array] of Object.entries(object)) {
    map[property] = toMapByKey(array, key)
  }
  return map
}

// formatting:

export const formatAddress = (address: Address) => {
  return [
    [
      address?.street,
      address?.numberExterior,
      address?.numberInterior,
    ].filter(Boolean).join(', '),
    address?.neighborhood,
    [
      address?.postalCode ? `${address?.postalCode}` : '',
      address?.city,
      address?.district,
    ].filter(Boolean).join(', '),
    [
      address?.state,
      address?.country,
    ].filter(Boolean).join(', '),
  ].filter(Boolean).join(', ').toUpperCase().trim()
}

export const toUrl = (path: `/${string}`) => {
  return new URL(path, window.location.origin)
}
