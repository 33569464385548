<script setup lang="ts">
import type { ZodSchema } from 'zod'

const model = defineModel<Serviceorderitem>({ required: true })

const props = defineProps<{
  data: Partial<Serviceorderitem >
  salesorderId: Ref<string>
  title?: string
  disabledFields?: (keyof Serviceorderitem | string)[]
  schema: ZodSchema
  disabled?: boolean
  slideoverOpenEdit: () => void
}>()

const $services = await useFetch<Serviceitem[]>('/api/db/services', {
  query: { $columns: ['id', 'name', 'type'] },
  default: () => [],
})
const $serviceitems = await useFetch<Serviceitem[]>('/api/db/serviceitems', {
  query: { $columns: ['id', 'name', 'serviceId', 'image'] },
  default: () => [],
})
const $salesorderitems = await useFetch<Salesorderitem[]>('/api/db/salesorderitems', {
  query: { type: ['services'], $columns: ['id', 'type', 'name', 'description', 'image', 'quantity', 'salesorderId', 'serviceitemId'] },
  default: () => [],
})

const services$ = useServices()
const serviceitems$ = useServiceitems()

const serviceType = ref<string | undefined>(undefined)
const serviceId = ref<number | undefined>(undefined)

const options = {
  type: optionsServices.type,
  serviceId: computed(() => $services?.data.value.map(toOption)),
  serviceitemId: computed(() => $serviceitems?.data.value.map(toOption)),
}

const maps = {
  serviceId: computed(() => toMapByKey($services?.data.value, 'id')),
  serviceitemId: computed(() => toMapByKey($serviceitems?.data.value, 'id')),
  salesorderitemId: computed(() => toMapByKey($salesorderitems?.data.value, 'id')),
  servicesByType: computed(() => toMapByGroupKey($services?.data.value, 'type')),
  serviceitemsByServiceId: computed(() => toMapByGroupKey($serviceitems?.data.value, 'serviceId')),
  salesorderitemsBySalesorderId: computed(() => toMapByGroupKey($salesorderitems?.data.value, 'salesorderId')),
}

const salesorderitemOptions = computed(() => {
  const salesorderitems = maps.salesorderitemsBySalesorderId?.value.get(props.salesorderId)
  return salesorderitems?.map(toOption) ?? []
})

const regenerateKey = ref(useId())

const onUpdateServiceitemId = (id: string) => {
  const serviceitem = maps.serviceitemId?.value.get(id)
  const service = maps.serviceId.value.get(serviceitem?.serviceId) as Partial<Service>
  serviceType.value = service.type
  serviceId.value = service.id
  model.value.name = serviceitem.name
  model.value.text = serviceitem.description
  model.value.images = [serviceitem.image]
}

const onUpdateSalesorderitemId = (id: string) => {
  const salesorderitem = maps.salesorderitemId?.value.get(id)
  model.value.serviceitemId = salesorderitem?.serviceitemId
  model.value.quantityPlanned = salesorderitem?.quantity
  model.value.name = salesorderitem.name
  model.value.text = salesorderitem.description
  model.value.images = [salesorderitem.image]
  onUpdateServiceitemId(model.value.serviceitemId)
}

const generateConceptName = () => {
  const serviceitem = maps.serviceitemId.value.get(model.value.serviceitemId) as Partial<Serviceitem>
  model.value.name = serviceitem.name
}

const selectedServiceserviceTab = ref(!model.value.salesorderitemId ? 0 : 1)

watch(model, () => {
  if (model.value.serviceitemId) {
    onUpdateServiceitemId(model.value.serviceitemId)
  }
  if (model.value.salesorderitemId) {
    onUpdateSalesorderitemId(model.value.salesorderitemId)
  }
  regenerateKey.value = useId()
},
{ immediate: true })

const onResetModel = () => {
  serviceType.value = undefined
  serviceId.value = undefined
  model.value.name = undefined
  model.value.text = undefined
  model.value.images = []
  model.value.salesorderitemId = undefined
  model.value.serviceitemId = undefined
}

const tabs = [
  {
    label: 'Nuevo',
    icon: 'i-mdi-plus-circle',
  },
  {
    label: 'Orden de Venta',
    icon: ICONS.salesorderitems,
  },
]

const FIELDSET = `w-full grid grid-cols-1 gap-2 !mt-4 !mb-2 p-4 border rounded-lg`
</script>

<template>
  <fieldset :class="FIELDSET" :disabled="disabled">
    <UTabs
      v-if="props.salesorderId"
      v-model="selectedServiceserviceTab"
      :items="tabs"
      @update:model-value="onResetModel"
    />

    <UFormGroup
      v-if="props.salesorderId && selectedServiceserviceTab === 1"
      label="Item de Orden de Venta"
      name="salesorderitemId"
      :required="isRequired(schema, 'salesorderitemId') || selectedServiceserviceTab === 1"
    >
      <div class="w-full flex gap-1">
        <UButtonGroup class="flex-1">
          <SelectMenuBase
            v-model="model.salesorderitemId"
            :options="salesorderitemOptions"
            :disabled="disabledFields?.includes('salesorderitemId') || !props.salesorderId"
            class="flex-1"
            @update:model-value="onUpdateSalesorderitemId"
          />
          <UButton icon="i-mdi-plus" disabled />
        </UButtonGroup>
        <ButtonRefresh
          toast="Cantidad actualizada"
          :disabled="!model.salesorderitemId"
          @click="onUpdateSalesorderitemId(model.salesorderitemId)"
        />
      </div>
    </UFormGroup>

    <UFormGroup
      :label="`Tipo de servicio`"
      :required="isRequired(schema, 'serviceitemId')"
    >
      <SelectMenuBaseInfo
        v-model="serviceType"
        :options="options.type ?? []"
        :disabled="disabledFields?.includes('serviceitemId') || (selectedServiceserviceTab === 1)"
      />
    </UFormGroup>

    <UFormGroup
      :label="'Servicio'"
      :required="isRequired(schema, 'serviceitemId')"
    >
      <UButtonGroup class="flex">
        <SelectMenuBase
          :key="`service-id-${regenerateKey}`"
          v-model="serviceId"
          :options="maps.servicesByType?.value.get(serviceType)?.map(toOption) ?? []"
          :disabled="(selectedServiceserviceTab === 1)"
          class="flex-1"
        />
        <UButton
          icon="i-mdi-plus"
          :disabled="(selectedServiceserviceTab === 1)"
          @click="openNested(() => services$.slideoverOpenCreate({
            data: { type: serviceType },
            disabledFields: ['type'],
            onSubmit: async (event) => {
              services$.onSubmitCreate(event.data)
              await $services.refresh()
              await openNested(() => props.slideoverOpenEdit({ ...props, data: model }))
            },
          }))"
        />
      </UButtonGroup>
    </UFormGroup>

    <UFormGroup
      label="Item"
      :required="isRequired(schema, 'serviceitemId')"
    >
      <div class="w-full flex gap-1">
        <UButtonGroup class="flex-1">
          <SelectMenuBase
            v-model="model.serviceitemId"
            :options="maps.serviceitemsByServiceId?.value.get(serviceId)?.map(toOption) ?? []"
            :disabled="!serviceId || (selectedServiceserviceTab === 1)"
            class="flex-1"
            @update:model-value="onUpdateServiceitemId"
          />
          <UButton
            icon="i-mdi-plus"
            :disabled="!serviceId || (selectedServiceserviceTab === 1)"
            @click="openNested(() => serviceitems$.slideoverOpenCreate({
              data: { serviceId: serviceId },
              disabledFields: ['serviceId'],
              onSubmit: async (event) => {
                const serviceitem = await serviceitems$.onSubmitCreate(event.data)
                model.serviceitemId = serviceitem!.id
                await $serviceitems.refresh()
                await openNested(() => props.slideoverOpenEdit({ ...props, data: model }))
              },
            }))"
          />
        </UButtonGroup>
        <ButtonRefresh
          toast="Nombre actualizado"
          :disabled="!serviceId || (selectedServiceserviceTab === 1)"
          @click="onUpdateServiceitemId(model.serviceitemId)"
        />
      </div>
    </UFormGroup>

    <UFormGroup
      label="Nombre"
      name="name"
      :required="isRequired(schema, 'name')"
    >
      <UButtonGroup class="flex">
        <UInput
          v-model="model.name"
          autofocus
          :disabled="disabledFields?.includes('name')"
          class="flex-1"
        />
        <ButtonGenerateString
          :disabled="disabledFields?.includes('name') || !model.serviceitemId && !model.serviceitemId"
          @click="generateConceptName"
        />
      </UButtonGroup>
    </UFormGroup>

    <!-- <UFormGroup
      name="image"
      label="Imagen"
      :required="isRequired(schema, 'image')"
    >
      <InputImage
        v-model="model.image"
        :query="{ prefix: `serviceorderitems/${model.id}` }"
      />
    </UFormGroup> -->

    <UFormGroup
      label="Imágenes"
      name="images"
      :required="isRequired(schema, 'images')"
    >
      <InputImages
        v-model="model.images"
        accept="*"
        :disabled="disabledFields?.includes('images')"
        :query="{ prefix: `serviceorderitems/${model.id}/images` }"
      />
    </UFormGroup>

    <UFormGroup
      label="Especificaciones"
      name="text"
      :required="isRequired(schema, 'text')"
    >
      <UTextarea
        v-model="model.text"
        autoresize
        :disabled="disabledFields?.includes('text')"
      />
    </UFormGroup>
  </fieldset>
</template>
