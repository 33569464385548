import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultApplication = (data: Partial<Application>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    industryIds: [],
    tags: [],
    files: [],
    data: getDefaultApplicationData(data?.data ?? {}),
  }, data)
}
export const optionsApplications = {} satisfies Options

export const optionsApplicationsMap = {}

export const relationsApplications: Record<string, boolean> = {}

export type ApplicationWithRelations = Application & {}

export const viewSettingsApplications: ViewSettings<Application> = {
  record: 'applications',
  views: optionsShared.views.filter(view => ['table', 'gallery'].includes(view.value)),
  selectedView: 'gallery',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
