import { AppUsersModalDelete, AppUsersModalInvite, AppUsersSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppUsersSlideoverProps = ComponentProps<typeof AppUsersSlideover>

export type UsersUtils = ReturnType<typeof useUsersUtils>

export const useUsers = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<User>('users')

  const slideoverOpenCreate = (props: AppUsersSlideoverProps) => {
    return new Promise<User>((resolve, reject) => {
      const data = getDefaultUser(props.data)
      slideover.open(AppUsersSlideover, {
        title: 'Crear usuario',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppUsersSlideoverProps) => {
    return new Promise<User>((resolve, reject) => {
      const data = getDefaultUser(props.data)
      slideover.open(AppUsersSlideover, {
        title: 'Editar usuario',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const modalOpenDeleteUser = (props: ComponentProps<typeof AppUsersModalDelete>) => {
    modal.open(AppUsersModalDelete, {
      title: props.title ?? 'Eliminar usuario',
      description: props.description ?? '¿Estás seguro de que deseas eliminar tu cuenta? Esta acción no se puede deshacer. Todos tus datos se eliminarán permanentemente.',
      data: props.data,
    })
  }

  const modalOpenInviteUser = (props: ComponentProps<typeof AppUsersModalInvite>) => {
    return new Promise<User>((resolve, reject) => {
      modal.open(AppUsersModalInvite, {
        title: 'Invitar usuario',
        description: 'Invita a un usuario nuevo a la aplicación. El usuario solo tendra acceso a los módulos seleccionados.',
        ...props,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            modal.close()
            resolve(result)
          }
          catch (error) {
            modal.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schemaInternal = z.object({
    name: z.string(),
    email: z.string().email(),
    modules: z.record(
      z.string(),
      z.object({
        enabled: z.boolean().optional(),
        role: z.string().nullable().optional(),
      }),
    ),
  }).required()

  const schemaExternal = schemaInternal.extend({
    phone: z.string(),
    accountId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: User, refresh: () => Promise<void>, utils: UsersUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Editar',
        icon: row.$inmutable ? 'i-mdi-eye' : 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            disabledFields: ['email', 'password'],
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      getDropdownItem('copyId', 'users', row, refresh, utils),
      getDropdownItem('archive', 'users', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'users', row, refresh, utils),
      getDropdownItem('files', 'users', row, refresh, utils),
    ],
    // resource actions:
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'users', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'users', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    modalOpenDeleteUser,
    modalOpenInviteUser,
    schemaInternal,
    schemaExternal,
    getDropdownItems,
  }
}

export const useUsersUtils = ({ $accounts }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
} = {}) => {
  const options = {
    ...optionsUsers,
    emailVerified: optionsUsers.emailVerified,
    banned: optionsUsers.banned,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
  }

  const maps = {
    emailVerified: toMapByKey(optionsUsers.emailVerified, 'value'),
    banned: toMapByKey(optionsUsers.banned, 'value'),
    modules: toMapByKey(optionsUsers.modules, 'value'),
    modulesRole: toMapByKey(optionsUsers.modulesRole, 'value'),
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
  }

  return { options, maps }
}
