import { AppServicesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppServicesSlideoverProps = ComponentProps<typeof AppServicesSlideover>

export type ServicesUtils = ReturnType<typeof useServicesUtils>

export const useServices = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Service>('services')

  const slideoverOpenCreate = (props: AppServicesSlideoverProps) => {
    return new Promise<Service>((resolve, reject) => {
      const data = getDefaultService(props.data)
      slideover.open(AppServicesSlideover, {
        title: 'Crear servicio',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppServicesSlideoverProps) => {
    return new Promise<Service>((resolve, reject) => {
      const data = getDefaultService(props.data)
      slideover.open(AppServicesSlideover, {
        title: 'Editar servicio',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    status: z.string(),
    name: z.string(),
    description: z.string(),
    fiscalData: fiscalDataSchema,
    categoryId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Service, refresh: () => Promise<void>, utils: ServicesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'services', row, refresh, utils),
      getDropdownItem('items', 'services', row, refresh, utils),
      getDropdownItem('copyId', 'services', row, refresh, utils),
      getDropdownItem('archive', 'services', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'services', row, refresh, utils),
      getDropdownItem('files', 'services', row, refresh, utils),
    ],
    getDropdownItemGroup('status', 'services', row, refresh, utils),
    // resource actions:
    [
      {
        label: 'Crear item',
        icon: ICONS.serviceitems,
        click: () => {
          const serviceitems$ = useServiceitems()
          serviceitems$.slideoverOpenCreate({
            data: { serviceId: row.id },
            disabledFields: ['serviceId'],
          }).then(refresh)
        },
      },
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'services', row, refresh, utils),
      getDropdownItem('inmutable', 'services', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'services', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useServicesUtils = ({ $accounts, $categories }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $categories?: Awaited<ReturnType<typeof useFetch<Category[]>>>
} = {}) => {
  const options = {
    ...optionsServices,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    categoryId: computed(() => $categories?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsServices.type, 'value'),
    status: toMapByKey(optionsServices.status, 'value'),
    fiscalData: {
      satUnitKey: toMapByKey(optionsSAT.productUnitId, 'value'),
    },
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    categoryId: computed(() => toMapByKey($categories?.data.value, 'id')),
  }

  return { options, maps }
}
