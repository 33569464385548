import type { TRecord } from '#shared'

export const useRecord = (record: TRecord) => {
  switch (record) {
    case 'accounts': return useAccounts()
    case 'activities': return useActivities()
    case 'apikeys': return useApikeys()
    case 'applications': return useApplications()
    case 'auditlogs': return useAuditlogs()
    // case 'authcredentials': return useAuthcredentials()
    // case 'authemailverificationcodes': return useAuthemailverificationcodes()
    // case 'authinvitations': return useAuthinvitations()
    // case 'authoauthaccounts': return useAuthoauthaccounts()
    // case 'authotps': return useAuthotps()
    // case 'authpasswordresettokens': return useAuthpasswordresettokens()
    case 'banks': return useBanks()
    case 'branches': return useBranches()
    case 'campaigns': return useCampaigns()
    case 'categories': return useCategories()
    // case 'cfdis': return useCfdis()
    case 'contacts': return useContacts()
    case 'deals': return useDeals()
    case 'financialaccounts': return useFinancialaccounts()
    case 'serviceorderitems': return useServiceorderitems()
    case 'serviceorders': return useServiceorders()
    case 'industries': return useIndustries()
    case 'inquiries': return useInquiries()
    case 'issues': return useIssues()
    case 'notes': return useNotes()
    // case 'notifications': return useNotifications()
    case 'pricelistitems': return usePricelistitems()
    case 'pricelists': return usePricelists()
    case 'productionorderitems': return useProductionorderitems()
    case 'productionorders': return useProductionorders()
    case 'productitems': return useProductitems()
    case 'products': return useProducts()
    case 'quoteitems': return useQuoteitems()
    case 'quotes': return useQuotes()
    case 'salesorderitems': return useSalesorderitems()
    case 'salesorders': return useSalesorders()
    case 'serviceitems': return useServiceitems()
    case 'services': return useServices()
    case 'tasks': return useTasks()
    case 'transactions': return useTransactions()
    case 'usernotifications': return useUsernotifications()
    case 'users': return useUsers()
    default: throw new Error(`Record "${record}" not found`)
  }
}

export const useRecordUtils = (record: TRecord) => {
  switch (record) {
    case 'accounts': return useAccountsUtils()
    case 'activities': return useActivitiesUtils()
    case 'apikeys': return useApikeysUtils()
    case 'applications': return useApplicationsUtils()
    case 'auditlogs': return useAuditlogsUtils()
    // case 'authcredentials': return useAuthcredentialsUtils()
    // case 'authemailverificationcodes': return useAuthemailverificationcodesUtils()
    // case 'authinvitations': return useAuthinvitationsUtils()
    // case 'authoauthaccounts': return useAuthoauthaccountsUtils()
    // case 'authotps': return useAuthotpsUtils()
    // case 'authpasswordresettokens': return useAuthpasswordresettokensUtils()
    case 'banks': return useBanksUtils()
    case 'branches': return useBranchesUtils()
    case 'campaigns': return useCampaignsUtils()
    case 'categories': return useCategoriesUtils()
    // case 'cfdis': return useCfdisUtils()
    case 'contacts': return useContactsUtils()
    case 'deals': return useDealsUtils()
    case 'financialaccounts': return useFinancialaccountsUtils()
    case 'serviceorderitems': return useServiceorderitemsUtils()
    case 'serviceorders': return useServiceordersUtils()
    case 'industries': return useIndustriesUtils()
    case 'inquiries': return useInquiriesUtils()
    case 'issues': return useIssuesUtils()
    case 'notes': return useNotesUtils()
    // case 'notifications': return useNotificationsUtils()
    case 'pricelistitems': return usePricelistitemsUtils()
    case 'pricelists': return usePricelistsUtils()
    case 'productionorderitems': return useProductionorderitemsUtils()
    case 'productionorders': return useProductionordersUtils()
    case 'productitems': return useProductitemsUtils()
    case 'products': return useProductsUtils()
    case 'quoteitems': return useQuoteitemsUtils()
    case 'quotes': return useQuotesUtils()
    case 'salesorderitems': return useSalesorderitemsUtils()
    case 'salesorders': return useSalesordersUtils()
    case 'serviceitems': return useServiceitemsUtils()
    case 'services': return useServicesUtils()
    case 'tasks': return useTasksUtils()
    case 'transactions': return useTransactionsUtils()
    case 'usernotifications': return useUsernotificationsUtils()
    case 'users': return useUsersUtils()
    default: throw new Error(`Record "${record}" not found`)
  }
}

export const useRecordOptions = (record: TRecord) => {
  switch (record) {
    case 'accounts': return optionsAccounts
    case 'activities': return optionsActivities
    case 'apikeys': return optionsApikeys
    case 'applications': return optionsApplications
    case 'auditlogs': return optionsAuditlogs
    // case 'authcredentials': return optionsAuthcredentials
    // case 'authemailverificationcodes': return optionsAuthemailverificationcodes
    // case 'authinvitations': return optionsAuthinvitations
    // case 'authoauthaccounts': return optionsAuthoauthaccounts
    // case 'authotps': return optionsAuthotps
    // case 'authpasswordresettokens': return optionsAuthpasswordresettokens
    case 'banks': return optionsBanks
    case 'branches': return optionsBranches
    case 'campaigns': return optionsCampaigns
    case 'categories': return optionsCategories
    // case 'cfdis': return optionsCfdis
    case 'contacts': return optionsContacts
    case 'deals': return optionsDeals
    case 'financialaccounts': return optionsFinancialaccounts
    case 'serviceorderitems': return optionsServiceorderitems
    case 'serviceorders': return optionsServiceorders
    case 'industries': return optionsIndustries
    case 'inquiries': return optionsInquiries
    case 'issues': return optionsIssues
    case 'notes': return optionsNotes
    // case 'notifications': return optionsNotifications
    case 'pricelistitems': return optionsPricelistitems
    case 'pricelists': return optionsPricelists
    case 'productionorderitems': return optionsProductionorderitems
    case 'productionorders': return optionsProductionorders
    case 'productitems': return optionsProductitems
    case 'products': return optionsProducts
    case 'quoteitems': return optionsQuoteitems
    case 'quotes': return optionsQuotes
    case 'salesorderitems': return optionsSalesorderitems
    case 'salesorders': return optionsSalesorders
    case 'serviceitems': return optionsServiceitems
    case 'services': return optionsServices
    case 'tasks': return optionsTasks
    case 'transactions': return optionsTransactions
    case 'usernotifications': return optionsUsernotifications
    case 'users': return optionsUsers
    default: throw new Error(`Record "${record}" not found`)
  }
}

export const useRecordOptionsMap = (record: TRecord) => {
  switch (record) {
    case 'accounts': return optionsAccountsMap
    case 'activities': return optionsActivitiesMap
    case 'apikeys': return optionsApikeysMap
    case 'applications': return optionsApplicationsMap
    case 'auditlogs': return optionsAuditlogsMap
    // case 'authcredentials': return optionsAuthcredentialsMap
    // case 'authemailverificationcodes': return optionsAuthemailverificationcodesMap
    // case 'authinvitations': return optionsAuthinvitationsMap
    // case 'authoauthaccounts': return optionsAuthoauthaccountsMap
    // case 'authotps': return optionsAuthotpsMap
    // case 'authpasswordresettokens': return optionsAuthpasswordresettokensMap
    case 'banks': return optionsBanksMap
    case 'branches': return optionsBranchesMap
    case 'campaigns': return optionsCampaignsMap
    case 'categories': return optionsCategoriesMap
    // case 'cfdis': return optionsCfdisMap
    case 'contacts': return optionsContactsMap
    case 'deals': return optionsDealsMap
    case 'financialaccounts': return optionsFinancialaccountsMap
    case 'serviceorderitems': return optionsServiceorderitemsMap
    case 'serviceorders': return optionsServiceordersMap
    case 'industries': return optionsIndustriesMap
    case 'inquiries': return optionsInquiriesMap
    case 'issues': return optionsIssuesMap
    case 'notes': return optionsNotesMap
    // case 'notifications': return optionsNotificationsMap
    case 'pricelistitems': return optionsPricelistitemsMap
    case 'pricelists': return optionsPricelistsMap
    case 'productionorderitems': return optionsProductionorderitemsMap
    case 'productionorders': return optionsProductionordersMap
    case 'productitems': return optionsProductitemsMap
    case 'products': return optionsProductsMap
    case 'quoteitems': return optionsQuoteitemsMap
    case 'quotes': return optionsQuotesMap
    case 'salesorderitems': return optionsSalesorderitemsMap
    case 'salesorders': return optionsSalesordersMap
    case 'serviceitems': return optionsServiceitemsMap
    case 'services': return optionsServicesMap
    case 'tasks': return optionsTasksMap
    case 'transactions': return optionsTransactionsMap
    case 'usernotifications': return optionsUsernotificationsMap
    case 'users': return optionsUsersMap
    default: throw new Error(`Record "${record}" not found`)
  }
}
