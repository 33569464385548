<script setup lang="ts">
const props = defineProps<{
  title?: string
  entries: Record<string, string | number | boolean | null>[]
  defaultOpen?: boolean
  placeholder?: string
}>()

const items = [{
  label: props.title ?? 'Información',
  icon: 'i-mdi-information',
  defaultOpen: props.defaultOpen,
  slot: 'information',
}]
</script>

<template>
  <UAccordion
    v-if="entries.length"
    :items="items"
    color="blue"
    variant="soft"
    size="xs"
    :ui="{
      container: 'bg-blue-50 dark:bg-blue-400 dark:bg-opacity-10 rounded-lg',
      default: { class: 'mb-0' },
    }"
  >
    <template #information>
      <div class="grid grid-cols-2 gap-1 text-xs text-blue-500 dark:text-blue-400 px-4">
        <template
          v-for="([key, value], i) in entries"
          :key="`entries-${key}-${i}`"
        >
          <div class="font-semibold" v-html="key" />
          <div
            class="text-right whitespace-pre text-wrap"
            v-html="value ? value : placeholder ? placeholder : ''"
          />
        </template>
      </div>
    </template>
  </UAccordion>
</template>
