<script setup lang="ts">
const model = defineModel<Campaign>({ required: true })

const props = defineProps<{
  utils: CampaignsUtils
  disabled?: boolean
}>()
</script>

<template>
  <div />
</template>
