import { AppInquiriesSlideover, FilesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppInquiriesSlideoverProps = ComponentProps<typeof AppInquiriesSlideover>

export type InquiriesUtils = ReturnType<typeof useInquiriesUtils>

export const useInquiries = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Inquiry>('inquiries')

  const slideoverOpenCreate = (props: AppInquiriesSlideoverProps) => {
    return new Promise<Inquiry>((resolve, reject) => {
      const data = getDefaultInquiry(props.data)
      slideover.open(AppInquiriesSlideover, {
        title: 'Registrar consulta',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppInquiriesSlideoverProps) => {
    return new Promise<Inquiry>((resolve, reject) => {
      const data = getDefaultInquiry(props.data)
      slideover.open(AppInquiriesSlideover, {
        title: 'Editar consulta',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    status: z.string(),
    source: z.string(),
    name: z.string(),
    date: z.string(),
    text: z.string(),
    campaignId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Inquiry, refresh: () => Promise<void>, utils: InquiriesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'inquiries', row, refresh, utils),
      getDropdownItem('copyId', 'inquiries', row, refresh, utils),
      getDropdownItem('copyUid', 'inquiries', row, refresh, utils),
      getDropdownItem('archive', 'inquiries', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'inquiries', row, refresh, utils),
      getDropdownItem('files', 'inquiries', row, refresh, utils),
      {
        label: 'Ver archivos (campaña)',
        icon: 'i-mdi-file-multiple',
        disabled: !row.campaignId && row.campaign?.files.length > 0,
        click: () => {
          slideover.open(FilesSlideover, { data: row.campaign, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    getDropdownItemGroup('status', 'inquiries', row, refresh, utils),
    [
      {
        label: 'Crear cliente',
        icon: ICONS.accounts,
        disabled: !!row.accountId,
        click: () => {
          const accounts$ = useAccounts()
          accounts$.slideoverOpenCreate({
            data: {
              type: 'customer',
              name: row.company ?? '',
            },
            disabledFields: ['type'],
            onSubmit: async (event) => {
              const account = await accounts$.onSubmitCreate(event.data)
              await onSubmitUpdate({ ...row, accountId: account!.id })
              accounts$.slideover.close()
            },
          }).then(refresh)
        },
      },
      {
        label: 'Crear contacto',
        icon: ICONS.contacts,
        disabled: !!row.contactId,
        click: () => {
          const contacts$ = useContacts()
          contacts$.slideoverOpenCreate({
            data: {
              type: 'customer',
              status: 'active',
              phone: row.phone,
              email: row.email,
              accountId: row.accountId,
            },
            onSubmit: async (event) => {
              const contact = await contacts$.onSubmitCreate(event.data)
              await onSubmitUpdate({ ...row, contactId: contact!.id })
              contacts$.slideover.close()
            },
          }).then(refresh)
        },
      },
      {
        label: 'Crear negocio',
        icon: ICONS.deals,
        disabled: !row.accountId || row.status === 'completed',
        click: () => {
          const deals$ = useDeals()
          deals$.slideoverOpenCreate({
            data: {
              type: 'inbound',
              accountId: row.accountId ?? undefined,
              branchId: row.branchId ?? undefined,
              campaignId: row.campaignId ?? undefined,
              contactId: row.contactId ?? undefined,
              inquiryId: row.id,
              files: row.files,
            },
            disabledFields: ['type', 'accountId'],
            onSubmit: async (event) => {
              await deals$.onSubmitCreate(event.data)
              await onSubmitUpdate({ ...row, status: 'completed' })
              deals$.slideover.close()
            },
          }).then(refresh)
        },
      },
      ...dropdownItemsAction,
    ],
    [
      {
        label: 'Llamar',
        icon: 'i-mdi-phone-outgoing',
        disabled: !row.phone,
        click: () => open(`tel:${row.phone}`),
      },
      // DISABLED: until emails are implemented again
      // {
      //   label: 'Enviar correo',
      //   icon: 'i-mdi-email-arrow-right',
      //   disabled: (!row.email && !row.contactId) || row.status === 'completed',
      //   click: () => {
      //     slideover(AppInquiriesSlideoverQuickReply, {
      //       data: { to: [
      //         {
      //           label: utils.maps.contactId.value.get(row.contactId)?.name ?? row.email,
      //           value: utils.maps.contactId.value.get(row.contactId)?.email ?? row.email,
      //         },
      //       ],
      //       subject: `Re Consulta: ${row.name}`,
      //       },
      //       disabledFields: ['type'],
      //       accountId: row.accountId ?? undefined,
      //     })
      //   },
      // },
    ],
    [
      getDropdownItem('info', 'inquiries', row, refresh, utils),
      getDropdownItem('inmutable', 'inquiries', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'inquiries', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useInquiriesUtils = ({ $accounts, $branches, $campaigns, $contacts, $deals }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $campaigns?: Awaited<ReturnType<typeof useFetch<Campaign[]>>>
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $deals?: Awaited<ReturnType<typeof useFetch<Deal[]>>>
} = {}) => {
  const options = {
    ...optionsInquiries,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    campaignId: computed(() => $campaigns?.data.value.map(toOption)),
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    dealId: computed(() => $deals?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsInquiries.type, 'value'),
    status: toMapByKey(optionsInquiries.status, 'value'),
    source: toMapByKey(optionsInquiries.source, 'value'),
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches?.data.value, 'id')),
    campaignId: computed(() => toMapByKey($campaigns?.data.value, 'id')),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches?.data.value, 'accountId')),
    contactByAccountId: computed(() => toMapByGroupKey($contacts?.data.value, 'accountId')),
  }

  return { options, maps }
}
