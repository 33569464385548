import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultPricelistitem = (data: Partial<Pricelistitem>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    currency: defaultAppVariables.currency,
    tags: [],
    files: [],
    data: getDefaultPricelistitemData(data?.data ?? {}),
  }, data)
}

export const optionsPricelistitems = {
  currency: optionsShared.currency,
} satisfies Options

export const optionsPricelistitemsMap = {
  Moneda: optionsPricelistitems.currency,
}

export const relationsPricelistitems: Record<string, boolean> = {
  pricelist: true,
  productitem: true,
  serviceitem: true,
}

export type PricelistitemWithRelations = Pricelistitem & {
  pricelist: Pricelist
  productitem: Productitem
  serviceitem: Serviceitem
}

export const viewSettingsPricelistitems: ViewSettings<Pricelistitem> = {
  record: 'pricelistitems',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: true,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Item',
      sortable: false,
    },
    {
      key: 'item.status',
      label: 'Estado de item',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'item.sku',
      label: 'SKU',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'item.image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'currency',
      label: 'Moneda',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'basePrice',
      label: 'Precio U',
      sortable: true,
    },
    {
      key: 'item.fiscalData.satUnitKey',
      label: 'Unidad (SAT)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'pricelistId',
      label: 'Lista de precios',
      sortable: true,
    },
    {
      key: 'pricelist.type',
      label: 'Tipo de Lista',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'pricelist.status',
      label: 'Estado lista',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'pricelist.date',
      label: 'Fecha lista',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
