<script setup lang="ts">
const model = defineModel<Serviceorder>({ required: true })

const props = defineProps<{
  utils: ServiceordersUtils
  disabled?: boolean
}>()
</script>

<template>
  <details open>
    <summary class="mt-4 mb-2 cursor-pointer font-bold text-gray-700">
      Programación
    </summary>
    <fieldset class="space-y-2" :class="FIELDSET">
      <!-- TODO -->
    </fieldset>
  </details>

  <details open>
    <summary class="mt-4 mb-2 cursor-pointer font-bold text-gray-700">
      Aprobación
    </summary>
    <fieldset :class="FIELDSET" :disabled="disabled">
      <UFormGroup
        :label="`NPS (Net Promoter Score ${model.data.nps}/10)`"
        name="data.nps"
        class="mb-4"
      >
        <URange
          v-model="model.data.nps"
          color="primary"
          :min="0"
          :max="10"
          :step="1"
        />
      </UFormGroup>

      <UFormGroup
        label="Comentarios"
        name="data.approvalText"
      >
        <UTextarea
          v-model="model.data.approvalText"
          autoresize
          disabled
        />
      </UFormGroup>

      <UFormGroup
        label="Firmado por"
        name="data.approvedBy"
      >
        <UInput v-model="model.data.approvedBy" />
      </UFormGroup>

      <UFormGroup
        label="Firmado por"
        name="data.approvedBy"
      >
        <InputSignature
          v-model="model.data.approvedBy"
          @blur="() => model.data.approvedAt = new Date().toISOString()"
        />
      </UFormGroup>

      <UFormGroup
        label="Firmado en"
        name="data.approvedAt"
      >
        <InputDatetime
          v-model="model.data.approvedAt"
          is-datetime
          hide-checkbox
          disabled
        />
      </UFormGroup>
    </fieldset>
  </details>
</template>
