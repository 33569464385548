import { hooks } from '@feathersjs/hooks'
import type { BlobObject } from '@nuxthub/core'
import * as dbHooks from './hooks/db'

export type TData = { id?: string, [k: string]: unknown }

export const useApiDB = <T extends TData>(record: string) => {
  const $fetch = useRequestFetch() // IMPORTANT: send cookies along on SSR (pending merge https://github.com/nuxt/nuxt/issues/24813)

  const onSubmitCreate = async (row: Partial<T>, toast = true) => {
    const result = await $fetch<T>(`/api/db/${record}`, {
      method: 'POST',
      body: row,
    })
    return result
  }

  const onSubmitUpdate = async (row: Partial<T>, toast = true) => {
    const result = await $fetch<T>(`/api/db/${record}/${row.id}`, {
      method: 'PATCH',
      body: row,
    })
    return result
  }

  const onSubmitDelete = async (rows: (Partial<T> & { id: T['id'] })[], toast = true) => {
    const result = await Promise.all(rows.map((row) => {
      const url = `/api/db/${record}/${row.id}`
      return $fetch(url, { method: 'DELETE' })
    }))

    // [blob] remove eventual associated files from blob:
    const { removeFile } = useApiBlob()
    await Promise.all(rows.map((row) => {
      Object.keys(row).forEach((key) => {
        if (key.startsWith('image')) {
          return removeFile(row[key] as BlobObject)
        }
        else if (key.startsWith('file')) {
          return removeFile(row[key] as BlobObject)
        }
        else if (['files'].includes(key)) {
          return Promise.all((row[key] as BlobObject[]).map(removeFile))
        }
      })
    }))

    return result
  }

  const onSubmitUpdateMultiple = async (rows: Partial<T>[], toast = true) => {
    const result = await Promise.all(
      rows.map((row) => {
        const url = `/api/db/${record}/${row.id}`
        return $fetch(url, { method: 'PATCH', body: row })
      }),
    )
    return result
  }

  return {
    onSubmitCreate: hooks(onSubmitCreate, [
      dbHooks.handleErrors('Registro creado con éxito', 'Error al crear registro'),
      dbHooks.handleBlobCreate(record),
    ]),
    onSubmitUpdate: hooks(onSubmitUpdate, [
      dbHooks.handleErrors('Registro actualizado con éxito', 'Error al actualizar registro'),
      dbHooks.handleCascadeUpdates(record),
      dbHooks.handleBlobUpdate(record),
    ]),
    onSubmitDelete: hooks(onSubmitDelete, [
      dbHooks.handleErrors('Registro(s) eliminado(s) con éxito', 'Error al eliminar registro(s)'),
      dbHooks.handleBlobDelete(record),
    ]),
    onSubmitUpdateMultiple: hooks(onSubmitUpdateMultiple, [
      dbHooks.handleErrors('Registros actualizados con éxito', 'Error al actualizar registros'),
      dbHooks.handleCascadeUpdatesMultiple(record),
    ]),
  }
}
