import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultUser = (data: Partial<User>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'internal',
    modules: Object.fromEntries(MODULES.map(mod => [mod.id, {}])),
  }, data)
}

export const optionsUsers = {
  type: [
    {
      value: 'admin',
      label: 'admin',
      description: 'Tiene acceso total a todos los módulos y funciones.',
      icon: 'i-mdi-shield-account',
      color: 'blue',
    },
    {
      value: 'internal',
      label: 'Interno',
      description: 'Usuario interno de la empresa.',
      icon: 'i-mdi-account-tie',
      color: 'green',
    },
    {
      value: 'external',
      label: 'Externo',
      description: 'Usuario externo a la empresa.',
      icon: 'i-mdi-account',
      color: 'red',
    },
  ],
  emailVerified: [
    { value: true, label: 'Verificado', icon: 'i-mdi-check-circle', color: 'green' },
    { value: false, label: 'No verificado', icon: 'i-mdi-alert-circle', color: 'red' },
  ],
  banned: [
    { value: true, label: 'Bloqueado', icon: 'i-mdi-block-helper', color: 'red' },
    { value: false, label: 'Activo', icon: 'i-mdi-check', color: 'green' },
  ],
  modules: MODULES.map(toOption),
  modulesRole: [
    {
      value: 'admin',
      label: 'Administrador',
      description: 'Tiene acceso total a todos los módulos y funciones.',
      icon: 'i-mdi-shield-account',
      color: 'blue',
    },
    {
      value: 'edit',
      label: 'Editor',
      description: 'Puede editar y crear registros en todos los módulos.',
      icon: 'i-mdi-pencil',
      color: 'red',
    },
    {
      value: 'view',
      label: 'Visualizador',
      description: 'Solo puede ver y leer registros en todos los módulos.',
      icon: 'i-mdi-eye',
      color: 'green',
    },
  ],
} satisfies Options

export const optionsUsersMap = {
  Módulo: optionsUsers.modules.map(({ color, ...option }) => option),
  Rol: optionsUsers.modulesRole,
}

export const relationsUsers: Record<string, boolean> = {
  // NOTE: user relations explicitly disabled for security/performance
  // also since they are never  needed in the /admin/auth/users page
}

export const viewSettingsUsers: ViewSettings<User> = {
  record: 'users',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'email',
      label: 'Email',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'phone',
      label: 'Teléfono',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'emailVerified',
      label: 'Email verificado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'banned',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'modules',
      label: 'Módulos',
    },
  ],
}
