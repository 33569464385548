import { AppActivitiesSlideover, FilesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppActivitiesSlideoverProps = ComponentProps<typeof AppActivitiesSlideover>

export type ActivitiesUtils = ReturnType<typeof useActivitiesUtils>

export const useActivities = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Activity>('activities')

  const slideoverOpenCreate = (props: AppActivitiesSlideoverProps) => {
    return new Promise<Activity>((resolve, reject) => {
      const data = getDefaultActivity(props.data)
      slideover.open(AppActivitiesSlideover, {
        title: 'Registrar actividad',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppActivitiesSlideoverProps) => {
    return new Promise<Activity>((resolve, reject) => {
      const data = getDefaultActivity(props.data)
      slideover.open(AppActivitiesSlideover, {
        title: 'Editar actividad',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    subtype: z.string(),
    status: z.string(),
    priority: z.string(),
    name: z.string(),
    accountId: z.string(),
    dealId: z.string(),
    dateStart: z.string(),
    dateEnd: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Activity, refresh: () => Promise<void>, utils: ActivitiesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'activities', row, refresh, utils),
      getDropdownItem('copyId', 'activities', row, refresh, utils),
      getDropdownItem('copyUid', 'activities', row, refresh, utils),
      getDropdownItem('archive', 'activities', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'activities', row, refresh, utils),
      getDropdownItem('files', 'activities', row, refresh, utils),
      {
        label: 'Ver archivos (negocio)',
        icon: 'i-mdi-file-multiple',
        disabled: !row.dealId && row.deal.files.length > 0,
        click: () => {
          slideover.open(FilesSlideover, { data: row.deal, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    getDropdownItemGroup('status', 'activities', row, refresh, utils),
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'activities', row, refresh, utils),
      getDropdownItem('inmutable', 'activities', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'activities', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useActivitiesUtils = ({ $contacts, $deals, $accounts, $branches, $users }: {
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $deals?: Awaited<ReturnType<typeof useFetch<Deal[]>>>
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsActivities,
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    dealId: computed(() => $deals?.data.value.map(toOption)),
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsActivities.type, 'value'),
    subtype: toMapByKey(optionsActivities.subtype, 'value'),
    status: toMapByKey(optionsActivities.status, 'value'),
    priority: toMapByKey(optionsActivities.priority, 'value'),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    dealId: computed(() => toMapByKey($deals?.data.value, 'id')),
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches?.data.value, 'accountId')),
    contactByAccountId: computed(() => toMapByGroupKey($contacts?.data.value, 'accountId')),
  }

  return { options, maps }
}
