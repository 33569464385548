type ExchangeRate = {
  date: string
  value: number
}

export function useExchangeRate() {
  const {
    state,
    isReady,
    execute,
  } = useAsyncState<ExchangeRate>(() => $fetch('/api/exchange-rate'), {} as ExchangeRate)

  return {
    state,
    isReady,
    execute,
  }
}
