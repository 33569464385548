// see https://pdfmake.github.io/docs/0.1/document-definition-object/images/
// see https://nuxt-pdfmake.behonbaker.com/examples/images#implementation
export async function getBase64ImageFromURL(href: string) {
  if (!href) return ''
  const url = href.startsWith('http') ? new URL(href) : new URL(href, window.location.origin)

  // add cache-buster to avoid cached images since browser disallows CORS sometimes over cache
  // see https://github.com/bpampuch/pdfmake/issues/2416#issuecomment-1291687790
  url.searchParams.set('v', Date.now().toString())

  const result = await fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        }),
    )
  return result
}
