import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultApikey = (data: Partial<Apikey>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    unhashedApikey: createApikey(), // NOTE: POST handler sets hashedApiKey from unhashedApikey
    permission: 'admin',
  }, data)
}

export const optionsApikeys = {
  permission: [
    {
      value: 'admin',
      label: 'Administación',
      description: 'Puede leer, escribir y eliminar registros en todos los módulos.',
      icon: 'i-mdi-shield-account',
      color: 'blue',
    },
    {
      value: 'edit',
      label: 'Escritura',
      description: 'Puede leer y escribir registros en todos los módulos.',
      icon: 'i-mdi-pencil',
      color: 'red',
    },
    {
      value: 'view',
      label: 'Lectura',
      description: 'Solo puede leer registros en todos los módulos.',
      icon: 'i-mdi-eye',
      color: 'green',
    },
  ],
} satisfies Options

export const optionsApikeysMap = {
  Permiso: optionsApikeys.permission,
}

export const relationsApikeys: Record<string, boolean> = {}

export const viewSettingsApikeys: ViewSettings<Apikey> = {
  record: 'apikeys',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'description',
      label: 'Descripción',
    },
    {
      key: 'permission',
      label: 'Permiso',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'hashedApikey',
      label: 'Clave API',
    },
    ...COLUMNS_METADATA,
  ],
}
