<script setup lang="ts">
const model = defineModel<Pricelistitem>({ required: true })

const props = defineProps<{
  utils: PricelistitemsUtils
  disabled?: boolean
}>()
</script>

<template>
  <div />
</template>
