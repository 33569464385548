import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultService = (data: Partial<Service>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    status: 'active',
    fiscalData: {},
    images: [],
    tags: [],
    files: [],
    data: getDefaultServiceData(data?.data ?? {}),
  }, data)
}

export const getServiceInformation = (data: Partial<ServiceWithRelations>) => {
  const maps = {
    type: toMapByKey(optionsServices.type, 'value'),
    status: toMapByKey(optionsServices.status, 'value'),
  }
  if (!data) return []
  return [
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Estado', maps.status.get(data.status!)?.label],
    ['Proveedor', data.supplier?.name],
    ['Clave Producto (SAT)', data.fiscalData?.satProductServiceKey],
  ].filter(([_, value]) => value != null)
}

export const optionsServices = {
  type: [
    { value: 'core', label: 'Principales', icon: 'i-mdi-heart-pulse', color: 'blue', description: 'Servicios principales que son el núcleo del negocio que constituyen la base de la oferta.' },
    { value: 'logistics', label: 'Envío y Logística', icon: 'i-mdi-truck-delivery', color: 'teal', description: 'Servicios de logística y envío, tanto nacionales como internacionales, gestionando transporte, embalaje y entrega de productos.' },
    { value: 'specialized', label: 'Especializados', icon: 'i-mdi-hammer-wrench', color: 'gray', description: 'Servicios fuera de lo convencional, como asesoría, consultoría o servicios de emergencia, que requieren conocimientos especializados.' },
    { value: 'other', label: 'Otros', icon: 'i-mdi-dots-horizontal', color: 'gray', description: 'Servicios que no encajan en las tipos anteriores.' },
  ],
  status: optionsSharedApp.statusResource,
} satisfies Options

export const optionsServicesMap = {
  Tipo: optionsServices.type,
  Estado: optionsServices.status,
}

export const relationsServices: Record<string, boolean> = {
  category: true,
  serviceitems: true,
  supplier: true,
}

export type ServiceWithRelations = Service & {
  category: Category
  serviceitems: Serviceitem[]
  supplier: Account
}

export const viewSettingsServices: ViewSettings<Service> = {
  record: 'services',
  views: optionsShared.views.filter(view => ['table', 'cards'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'categoryId',
      label: 'Categoría',
    },
    {
      key: 'supplierId',
      label: 'Proveedor',
    },
    {
      key: 'fiscalData.satProductServiceKey',
      label: 'Clave (SAT)',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
