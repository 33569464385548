<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types';

const props = defineProps<{
  data: Partial<Branch>
  onSubmit: (event: FormSubmitEvent<Partial<Branch>>) => void
  title?: string
  disabledFields?: (keyof Branch | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Branch>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
} = useBranches()

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: { $columns: ['id', 'name', 'status', 'domain', 'userId'] },
  default: () => [],
})

const utils = useBranchesUtils({ $accounts })

const accounts$ = useAccounts()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.branches,
      defaultOpen: true,
    },
    {
      slot: 'address',
      label: 'Dirección',
      icon: 'i-mdi-map-marker',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const information = computed(() => {
  const account = utils.maps.accountId.value.get(state.value.accountId) as Partial<Account>
  return {
    account: getAccountInformation(account),
  }
})

const loading = ref(false)

const onSubmitBranch = async (event: FormSubmitEvent<Partial<Branch>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.branches"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitBranch"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
          >
            <UInput
              v-model="state.name"
              autofocus
              :disabled="disabledFields?.includes('name')"
            />
          </UFormGroup>

          <UFormGroup
            label="Cuenta"
            name="accountId"
            :required="isRequired(schema, 'accountId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.accountId"
                :options="utils.options.accountId.value"
                :disabled="disabledFields?.includes('accountId')"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('accountId')"
                @click="openNested(() => accounts$.slideoverOpenCreate({
                  data: { type: 'customer' },
                  onSubmit: async (event) => {
                    const account = await accounts$.onSubmitCreate(event.data)
                    state.accountId = account!.id
                    await $accounts.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <ViewRendererInformation
            title="Información de la cuenta"
            :default-open="false"
            :entries="information.account"
          />
        </template>

        <template #details>
          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="optionsBranches.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Teléfono"
            name="phone"
            :required="isRequired(schema, 'phone')"
          >
            <InputPhone
              v-model="state.phone"
              :disabled="disabledFields?.includes('phone')"
            />
          </UFormGroup>

          <UFormGroup
            label="Imagen"
            name="image"
            :required="isRequired(schema, 'image')"
          >
            <InputImage
              v-model="state.image"
              :query="{ prefix: `branches/${state.id}` }"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `branches/${state.id}/files` }"
            />
          </UFormGroup>
        </template>

        <template #address>
          <FieldsetAddress
            v-model="state.address"
            v-bind="{ schema, path: 'address', disabledFields }"
          />
        </template>

        <template #data>
          <AppBranchesFieldsetData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.branches"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
