import { createId, createUid, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultDeal = (data: Partial<Deal>) => {
  const uid = createUid(new Date(), 'NEG') // IMPORTANT: cloudflare throws error if called on global scope
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    priority: '2',
    stage: 'contact',
    status: 'pending',
    rating: '2',
    uid: uid,
    name: uid,
    tags: [],
    files: [],
    data: getDefaultDealData(data?.data ?? {}),
  }, data)
}

export const getDealInformation = (data: Partial<DealWithRelations>) => {
  const maps = {
    type: toMapByKey(optionsDeals.type, 'value'),
    status: toMapByKey(optionsDeals.status, 'value'),
    stage: toMapByKey(optionsDeals.stage, 'value'),
  }
  if (!data) return []
  return [
    ['Referencia', data.uid],
    ['Nombre', data.name],
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Etapa', maps.stage.get(data.stage!)?.label],
    ['Estado', maps.status.get(data.status!)?.label],
  ].filter(([_, value]) => value != null)
}

export const optionsDeals = {
  type: [
    { value: 'inbound', label: 'Entrada', description: 'Negocios iniciados por el cliente, generalmente al contactarnos para solicitar información o cotización. Necesitan ser asociados a una solicitud.', icon: 'i-mdi-arrow-down-circle', color: 'green' },
    { value: 'outbound', label: 'Salida', description: 'Negocios iniciados por nosotros, generalmente a través de actividades de prospección o ventas activas.', icon: 'i-mdi-arrow-up-circle', color: 'orange' },
  ],
  stage: [
    { value: 'contact', label: 'Contacto', description: 'Se requiere establecer el primer contacto con el prospecto para iniciar la negociación.', icon: 'i-mdi-circle-slice-1', color: 'blue' },
    { value: 'qualification', label: 'Cualificación', description: 'Recopilación de información y evaluación del prospecto para avanzar hacia una oferta.', icon: 'i-mdi-circle-slice-3', color: 'blue' },
    { value: 'quote', label: 'Cotización', description: 'El negocio está en la etapa de cotización, y se está esperando la respuesta del cliente.', icon: 'i-mdi-circle-slice-4', color: 'amber' },
    { value: 'purchase-order', label: 'Orden de Venta', description: 'El cliente ha aceptado la cotización, y estamos esperando la orden de venta.', icon: 'i-mdi-circle-slice-8', color: 'green' },
    { value: 'won', label: 'Ganado', description: 'El negocio ha sido cerrado exitosamente tras recibir la orden de ventadel cliente.', icon: 'i-mdi-trophy', color: 'green' },
    { value: 'lost', label: 'Perdido', description: 'El negocio se perdió por diversos motivos, y no avanzará más en el proceso.', icon: 'i-mdi-close-circle', color: 'red' },
  ],
  status: optionsSharedApp.statusProcess,
  priority: optionsSharedApp.priority,
  rating: [
    { value: '1', label: 'Frío', description: 'El cliente muestra poco interés en avanzar con la negociación.', icon: 'i-mdi-snowflake', color: 'blue' },
    { value: '2', label: 'Tibio', description: 'El cliente tiene un interés moderado, pero aún no está decidido.', icon: 'i-mdi-thermometer', color: 'amber' },
    { value: '3', label: 'Caliente', description: 'El cliente está altamente interesado y listo para avanzar.', icon: 'i-mdi-fire', color: 'red' },
  ],
} satisfies Options

export const optionsDealsMap = {
  Tipo: optionsDeals.type,
  Estado: optionsDeals.status,
  Etapa: optionsDeals.stage,
  Prioridad: optionsDeals.priority,
  Valoración: optionsDeals.rating,
}

export const relationsDeals: Record<string, boolean> = {
  account: true,
  activities: true,
  branch: true,
  campaign: true,
  contact: true,
  inquiry: true,
  salesorders: true,
  quotes: true,
  user: true,
}

export type DealWithRelations = Deal & {
  account: Account
  activities: Activity[]
  branch: Branch
  campaign: Campaign
  contact: Contact
  inquiry: Inquiry
  salesorders: Salesorder[]
  quotes: Quote[]
  user: User
}

export const viewSettingsDeals: ViewSettings<Deal> = {
  record: 'deals',
  views: optionsShared.views.filter(view => ['table', 'kanban'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'stage',
      label: 'Etapa',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'rating',
      label: 'Valoración',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'accountId',
      label: 'Cliente',
    },
    {
      key: 'contactId',
      label: 'Contacto',
    },
    {
      key: 'userId',
      label: 'Vendedor',
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'branchId',
      label: 'Sucursal',
    },
    {
      key: 'campaignId',
      label: 'Campaña',
    },
    {
      key: 'inquiryId',
      label: 'Solicitud',
    },
    {
      key: 'quotes',
      label: 'Cotizaciones',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groups: [
    { label: 'Tipo', value: 'type', options: optionsDeals.type },
    { label: 'Etapa', value: 'stage', options: optionsDeals.stage },
    { label: 'Estado', value: 'status', options: optionsDeals.status },
    { label: 'Prioridad', value: 'priority', options: optionsDeals.priority },
    { label: 'Valoración', value: 'rating', options: optionsDeals.rating },
  ],
  group: 'stage',
}
