import { AppProductsSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppProductsSlideoverProps = ComponentProps<typeof AppProductsSlideover>

export type ProductsUtils = ReturnType<typeof useProductsUtils>

export const useProducts = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Product>('products')

  const slideoverOpenCreate = (props: AppProductsSlideoverProps) => {
    return new Promise<Product>((resolve, reject) => {
      const data = getDefaultProduct(props.data)
      slideover.open(AppProductsSlideover, {
        title: 'Crear producto',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppProductsSlideoverProps) => {
    return new Promise<Product>((resolve, reject) => {
      const data = getDefaultProduct(props.data)
      slideover.open(AppProductsSlideover, {
        title: 'Editar producto',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    status: z.string(),
    name: z.string(),
    description: z.string(),
    fiscalData: fiscalDataSchema,
    categoryId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Product, refresh: () => Promise<void>, utils: ProductsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'products', row, refresh, utils),
      getDropdownItem('items', 'products', row, refresh, utils),
      getDropdownItem('copyId', 'products', row, refresh, utils),
      getDropdownItem('archive', 'products', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'products', row, refresh, utils),
      getDropdownItem('files', 'products', row, refresh, utils),
    ],
    getDropdownItemGroup('status', 'products', row, refresh, utils),
    // resource actions:
    [
      {
        label: 'Crear item',
        icon: ICONS.productitems,
        click: () => {
          const productitems$ = useProductitems()
          productitems$.slideoverOpenCreate({
            data: { productId: row.id },
            disabledFields: ['productId'],
          }).then(refresh)
        },
      },
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'products', row, refresh, utils),
      getDropdownItem('inmutable', 'products', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'products', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useProductsUtils = ({ $accounts, $applications, $categories }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $applications?: Awaited<ReturnType<typeof useFetch<Application[]>>>
  $categories?: Awaited<ReturnType<typeof useFetch<Category[]>>>
} = {}) => {
  const options = {
    ...optionsProducts,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    applicationId: computed(() => $applications?.data.value.map(toOption)),
    categoryId: computed(() => $categories?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsProducts.type, 'value'),
    status: toMapByKey(optionsProducts.status, 'value'),
    fiscalData: {
      satUnitKey: toMapByKey(optionsSAT.productUnitId, 'value'),
    },
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    categoryId: computed(() => toMapByKey($categories?.data.value, 'id')),
    applicationId: computed(() => toMapByKey($applications?.data.value, 'id')),
  }

  return { options, maps }
}
