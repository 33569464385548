import { createId, createUid, type Option, type Options } from '#netzo/utils/core/index'
import { format } from 'date-fns'
import { merge } from 'lodash-es'
import type { Content, ContentColumns, TDocumentDefinitions, TDocumentInformation } from 'pdfmake/interfaces'

export const getDefaultPricelist = (data: Partial<Pricelist>) => {
  const uid = createUid(new Date(), 'LP') // IMPORTANT: cloudflare throws error if called on global scope
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    status: 'pending',
    uid: uid,
    name: uid,
    date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    pdfSettings: defaultPdfSettings,
    tags: [],
    files: [],
    data: getDefaultPricelistData(data?.data ?? {}),
  }, data)
}

export const getPricelistInformation = (data: Partial<Pricelist>) => {
  const maps = {
    type: toMapByKey(optionsPricelists.type, 'value'),
    status: toMapByKey(optionsPricelists.status, 'value'),
  }
  if (!data) return []
  return [
    ['Referencia', data.uid],
    ['Nombre', data.name],
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Estado', maps.status.get(data.status!)?.label],
    ['Fecha de entrada en vigor', data.date ? new Date(data.date).toLocaleDateString() : 'Por definir'],
  ].filter(([_, value]) => value != null)
}

// Make sure that function only receives pricelists with status 'completed'
export function getPriceForItem(pricelists: PricelistWithRelations[], type: 'productitem' | 'serviceitem', itemId: string) {
  let basePrice: number | undefined
  for (const pricelist of pricelists) {
    if (pricelist.status === 'completed') {
      const matchingItem = pricelist.pricelistitems.find(plItem => plItem[`${type}Id`] === itemId)
      if (matchingItem) {
        basePrice = matchingItem.basePrice as number
        break
      }
    }
  }
  return basePrice
}

export const optionsPricelists = {
  type: [
    { value: 'products', label: 'Productos', icon: 'i-mdi-package-variant-closed', color: 'sky', description: 'Esta lista de precios incluye bienes tangibles que pueden adquirirse, como mercancías o materiales.' },
    { value: 'services', label: 'Servicios', icon: 'i-mdi-tools', color: 'orange', description: 'Esta lista de precios incluye actividades intangibles ofrecidas para satisfacer necesidades específicas, como consultoría o mantenimiento.' },
  ],
  status: optionsSharedApp.statusProcess,
} satisfies Options

export const optionsPricelistsMap = {
  Tipo: optionsPricelists.type,
  Estado: optionsPricelists.status,
}

export const relationsPricelists: Record<string, boolean> = {
  pricelistitems: true,
}

export type PricelistWithRelations = Pricelist & { pricelistitems: Pricelistitem[] }

export const pricelistToViewCalendarEvent = (calendarId: keyof Pricelist, utils: PricelistsUtils) => {
  return (row: Pricelist): CalendarEvent => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.name}`,
      start: format(new Date(row.date), dateFormat),
      end: format(new Date(row.date), dateFormat),
      calendarId: row[calendarId],
    }
  }
}

export const createPdfmakeDocumentDefinitionPricelists = async (
  data: PricelistWithRelations,
  categories: Partial<Category>[],
  items: Array<(Productitem & { product: Product }) | (Serviceitem & { service: Service })>,
  utils: PricelistsUtils,
): Promise<TDocumentDefinitions> => {
  data.data ??= {}
  data.pdfSettings ??= defaultPdfSettings
  data.pricelistitems ??= []

  const maps = {
    fiscalData: {
      satUnitKey: toMapByKey(optionsSAT.productUnitId, 'value'),
    },
    itemId: toMapByKey(items, 'id'),
    categoryId: toMapByKey(categories, 'id'),
  }

  // sections:

  const info: TDocumentInformation = {
    title: `${data.uid}`,
    subject: 'Lista de precios La Tinta',
    keywords: 'diseño, agencia, publicidad',
    author: 'LA TINTA',
    producer: 'Netzo (https://netzo.dev)',
  }

  const sectionHeader: ContentColumns = {
    columns: [
      { image: SYMBOL_BASE64, fit: [80, 200], margin: [5, 5] },
      {
        stack: [
          { text: 'Lista de Precios', fontSize: 18, alignment: 'right', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Referencia: ', bold: true }, `${data.uid}`], alignment: 'right' },
          { text: [{ text: 'Nombre: ', bold: true }, data.name], alignment: 'right' },
          { text: [{ text: 'Fecha de documento: ', bold: true }, new Date().toLocaleDateString()], alignment: 'right' },
          { text: [{ text: 'Fecha de entrada en vigor: ', bold: true }, new Date(data.date).toLocaleDateString()], alignment: 'right' },
          { text: [{ text: 'Tipo: ', bold: true }, utils.maps.type.get(data.type)?.label!], alignment: 'right' },
          { text: [{ text: 'Estado: ', bold: true }, utils.maps.status.get(data.status)?.label!], alignment: 'right' },
        ],
        margin: [0, 10],
      },
    ],
    margin: [0, 0, 0, 5],
  }

  const sectionPricelistitems: Content = {
    table: {
      dontBreakRows: true,
      headerRows: 1,
      widths: data.pdfSettings?.images
        ? ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']
        : ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
      body: [
        [
          { text: '#', style: 'tableHeader' },
          { text: 'SKU', style: 'tableHeader' },
          ...data.pdfSettings?.images ? [{ text: 'Imagen', style: 'tableHeader' }] : [],
          { text: 'Categoria', style: 'tableHeader' },
          { text: 'Producto', style: 'tableHeader' },
          { text: 'Item', style: 'tableHeader' },
          { text: 'Clave (SAT)', style: 'tableHeader' },
          { text: 'Unidad (SAT)', style: 'tableHeader' },
          { text: 'Moneda', style: 'tableHeader' },
          { text: 'Precio U', style: 'tableHeader' },
        ],
        // Pre-fetch `maps.itemId.get(item[itemString])` to avoid redundant calls
        ...(await Promise.all(
          data!.pricelistitems!.map(async (item: Pricelistitem, index: number) => {
            const type = data.type
            const resolvedItem = type === 'products'
              ? (maps.itemId.get(item.productitemId) as Productitem & { product: Product })
              : (maps.itemId.get(item.serviceitemId) as Serviceitem & { service: Service })
            const categoryName = type === 'products'
              ? maps.categoryId.get(resolvedItem?.product?.categoryId)?.name
              : maps.categoryId.get(resolvedItem?.service?.categoryId)?.name
            const parentName = type === 'products'
              ? resolvedItem?.product?.name
              : resolvedItem?.service?.name
            const satProductServiceKey = type === 'products'
              ? resolvedItem?.product?.fiscalData?.satProductServiceKey
              : resolvedItem?.service?.fiscalData?.satProductServiceKey
            const satUnitKey = resolvedItem?.fiscalData?.satUnitKey

            return [
              { text: index + 1, alignment: 'center', style: 'tableRow' },
              { text: resolvedItem?.sku, alignment: 'center', style: 'tableRow' },
              ...(data.pdfSettings?.images
                ? [
                    resolvedItem?.image
                      ? { image: await getBase64ImageFromURL(resolvedItem.image), width: 40, height: 40, margin: [5, 5, 5, 5] }
                      : { text: '' },
                  ]
                : []),
              { text: categoryName, alignment: 'left', style: 'tableRow' },
              { text: parentName, alignment: 'left', style: 'tableRow' },
              { text: resolvedItem?.name, alignment: 'left', style: 'tableRow' },
              { text: satProductServiceKey, alignment: 'center', style: 'tableRow' },
              { text: satUnitKey, alignment: 'center', style: 'tableRow' },
              { text: item.currency, alignment: 'center', style: 'tableRow' },
              { text: toCurrency(Number(item.basePrice), defaultAppVariables.currency as 'MXN' | 'USD'), alignment: 'right', style: 'tableRow' },
            ]
          }),
        )),

      ].filter(Boolean),
    },
    layout: {
      hLineWidth: function (i, node) {
        return i === 0 || i === node.table.body.length ? 0 : 0.1
      },
      vLineWidth: function (i) { return 0 },
      hLineColor: function (i, node) {
        return i === 0 || i === node.table.body.length ? 'white' : 'gray'
      },
      vLineColor: function (i) { return 'white' },
    },
  }

  const tableType = await getOptionsTable(optionsPricelists.type, 'Tipo de Lista')
  const tableStatus = await getOptionsTable(optionsPricelists.status, 'Estado de Lista')

  const splitOptions = (options: Option[]) => {
    const midIndex = Math.ceil(options.length / 2) // Calculate the middle index
    const firstHalf = options.slice(0, midIndex) // First half (larger half if uneven)
    const secondHalf = options.slice(midIndex) // Second half

    return { firstHalf, secondHalf }
  }

  const { firstHalf, secondHalf } = splitOptions(optionsSAT.productUnitId)

  const firstHalfTableSatUnitKey = await getOptionsTable(firstHalf, 'Unidad de Medida (SAT)', true)
  const secondHalfTableSatUnitKey = await getOptionsTable(secondHalf, 'Unidad de Medida (SAT)', true)

  const sectionLegend = data.pdfSettings?.details
    ? [
        { text: 'Descripción de Opciones', style: 'header', color: '#595959', pageBreak: 'before' },
        { text: 'Resumen de las opciones disponibles para las etiquetas.', color: '#595959', margin: [0, 0, 0, 10] },
        {
          columns: [
            { width: '50%', stack: [tableType] },
            { width: '50%', stack: [tableStatus] },
          ],
          columnGap: 10,
        },
        {
          columns: [
            { width: '25%', stack: [firstHalfTableSatUnitKey] },
            { width: '25%', stack: [secondHalfTableSatUnitKey] },
          ],
          columnGap: 10, // Adjust the space between the columns
        },
      ]
    : []

  return {
    language: 'es-MX',
    userPassword: data.pdfSettings?.password?.enabled ? data.pdfSettings?.password?.value : undefined,
    watermark: data.pdfSettings?.watermark?.enabled
      ? {
          text: data.pdfSettings?.watermark.text,
          color: data.pdfSettings?.watermark.color,
          bold: data.pdfSettings?.watermark.bold,
          italics: data.pdfSettings?.watermark.italic,
          opacity: 0.05,
        }
      : undefined,
    info,
    content: [
      sectionHeader,
      sectionPricelistitems,
      ...sectionLegend as Content[],
    ],
    footer: (currentPage: number, pageCount: number) => ({
      columns: [
        { image: LOGO_BASE64, fit: [80, 20], margin: [20, 10, 0, 0] },
        {
          stack: [
            {
              text: [
                { text: 'Generado con ' },
                { text: 'netzo.dev', link: 'https://netzo.dev', color: 'blue' },
              ],
            },
          ],
          alignment: 'center',
          margin: [0, 10, 0, 0],
        },
        {
          text: `Página ${currentPage.toString()} de ${pageCount}`,
          alignment: 'right',
          margin: [0, 10, 20, 0],
        },
      ],
      widths: ['auto', '*', 'auto'],
    }),
    styles: {
      header: { fontSize: 10, bold: true, margin: [0, 0, 10, 5] },
      tableHeader: { bold: true, fillColor: '#aaa', fillOpacity: 0.25, margin: [5, 5], noWrap: true },
      tableRow: { bold: false, margin: [5, 5] },
    },
    defaultStyle: { fontSize: 8 },
    pageSize: 'LETTER',
    pageMargins: [20, 20, 20, 40],
  }
}

export const viewSettingsPricelists: ViewSettings<Pricelist> = {
  record: 'pricelists',
  views: optionsShared.views.filter(view => ['table', 'cards', 'calendar'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'filePricelistPdf',
      label: 'PDF',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'date',
      label: 'Fecha entrada en vigor',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'items',
      label: 'Items',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groups: [
    { label: 'Tipo', value: 'type', options: optionsPricelists.type },
    { label: 'Estado', value: 'status', options: optionsPricelists.status },
  ],
  group: 'type',
}
