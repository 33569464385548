import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultContact = (data: Partial<Contact>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    status: 'active',
    tags: [],
    files: [],
    data: getDefaultContactData(data?.data ?? {}),
  }, data)
}

export const getContactInformation = (data: Partial<Contact>) => {
  const maps = {
    status: toMapByKey(optionsContacts.status, 'value'),
  }
  if (!data) return []
  return [
    ['Titulo', data.title],
    ['Nombre', data.name],
    ['Estado', maps.status.get(data.status!)?.label],
    ['Correo', data.email],
    ['Teléfono', data.phone],
  ].filter(([_, value]) => value != null)
}

export const optionsContacts = {
  type: [
    { value: 'customer', label: 'Cliente', description: 'Contactos de empresas a las que vendemos nuestros productos o servicios.', color: 'green' },
    { value: 'partner', label: 'Socio', description: 'Contactos de empresas con las que tenemos una relación de colaboración. Pueden ser socios comerciales, proveedores de servicios, distribuidores, etc.', color: 'red' },
    { value: 'supplier', label: 'Proveedor', description: 'Contactos de empresas que nos suministran productos o servicios.', color: 'orange' },
    { value: 'other', label: 'Otro', description: 'Cualquier tipo de contacto que no encaje en las categorías anteriores.', color: 'gray' },
  ],
  status: optionsSharedApp.statusResource,
} satisfies Options

export const optionsContactsMap = {
  Tipo: optionsContacts.type,
  Estado: optionsContacts.status,
}

export const relationsContacts: Record<string, boolean> = {
  account: true,
  branch: true,
  inquiries: true,
  user: true,
}

export type ContactWithRelations = Contact & {
  account: Account
  branch: Branch
  inquiries: Inquiry[]
  user: User
}

export const viewSettingsContacts: ViewSettings<Contact> = {
  record: 'contacts',
  views: optionsShared.views.filter(view => ['table', 'cards'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'title',
      label: 'Titulo',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'userId',
      label: 'Dueño',
    },
    {
      key: 'accountId',
      label: 'Cuenta',
    },
    {
      key: 'branchId',
      label: 'Sucursal',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
