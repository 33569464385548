import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultProduct = (data: Partial<Product>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    status: 'active',
    images: [],
    fiscalData: {},
    applicationIds: [],
    tags: [],
    files: [],
    data: getDefaultProductData(data?.data ?? {}),
  }, data)
}

export const getProductInformation = (data: Partial<ProductWithRelations>) => {
  const maps = {
    type: toMapByKey(optionsProducts.type, 'value'),
    status: toMapByKey(optionsProducts.status, 'value'),
  }
  if (!data) return []
  return [
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Estado', maps.status.get(data.status!)?.label],
    ['Proveedor', data.supplier?.name],
    ['Fabricante', data.manufacturer?.name],
    ['Clave Producto (SAT)', data.fiscalData?.satProductServiceKey],
  ].filter(([_, value]) => value != null)
}

export const optionsProducts = {
  type: [
    { value: 'consumables', label: 'Consumibles', icon: 'i-mdi-cogs', color: 'blue', description: 'Materiales utilizados en el proceso de producción que no se integran en el producto final, como suministros de limpieza, lubricantes y equipos de seguridad.' },
    { value: 'raw-materials', label: 'Materia prima', icon: 'i-mdi-raw', color: 'yellow', description: 'Materiales básicos e insumos utilizados en la fabricación de productos propios.' },
    { value: 'components', label: 'Componentes', icon: 'i-mdi-apps', color: 'purple', description: 'Partes o piezas que se ensamblan para formar productos finales, como piezas mecánicas o electrónicas.' },
    { value: 'packaging', label: 'Embalaje', icon: 'i-mdi-package-variant-closed', color: 'orange', description: 'Materiales utilizados para el embalaje y envío de productos, como etiquetas, sacos, cajas, cubetas y otros materiales de empaque.' },
    { value: 'finished-goods', label: 'Producto terminado', icon: 'i-mdi-diamond-stone', color: 'green', description: 'Productos completamente fabricados que se venden directamente al cliente final.' },
  ],
  status: optionsSharedApp.statusResource,
} satisfies Options

export const optionsProductsMap = {
  Tipo: optionsProducts.type,
  Estado: optionsProducts.status,
}

export const relationsProducts: Record<string, boolean> = {
  category: true,
  manufacturer: true,
  productitems: true,
  supplier: true,
}

export type ProductWithRelations = Product & {
  category: Category
  manufacturer: Account
  productitems: Productitem[]
  supplier: Account
}

export const viewSettingsProducts: ViewSettings<Product> = {
  record: 'products',
  views: optionsShared.views.filter(view => ['table', 'cards'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'categoryId',
      label: 'Categoría',
    },
    {
      key: 'supplierId',
      label: 'Proveedor',
    },
    {
      key: 'manufacturerId',
      label: 'Fabricante',
    },
    {
      key: 'fiscalData.satProductServiceKey',
      label: 'Clave (SAT)',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
