<script setup lang="ts">
const model = defineModel<string>()
</script>

<template>
  <UInput
    :model-value="model"
    @update:model-value="model = $event.toUpperCase()"
  >
    <template #leading>
      <UIcon name="i-mdi-signature" />
    </template>
    <template v-if="model?.length" #trailing>
      <UIcon name="i-mdi-check-cicle" class="bg-green-500" />
    </template>
  </UInput>
</template>
