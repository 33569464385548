<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types';

const props = defineProps<{
  data: Partial<Inquiry>
  onSubmit: (event: FormSubmitEvent<Partial<Inquiry>>) => void
  title?: string
  disabledFields?: (keyof Inquiry | string)[]
  readonly?: boolean
}>()

const route = useRoute()

const { user } = useUserSession()
const { userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Inquiry>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
} = useInquiries()

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: { type: ['customer'], $columns: ['id', 'name', 'status', 'image', 'billing'] },
  default: () => [],
})
const $branches = await useFetch<Branch[]>('/api/db/branches', {
  query: { $columns: ['id', 'name', 'image', 'status', 'phone', 'address', 'accountId'] },
  default: () => [],
})
const $campaigns = await useFetch<Campaign[]>('/api/db/campaigns', {
  query: { type: 'inbound', $columns: ['id', 'uid', 'name', 'status', 'description', 'image', 'type'] },
  default: () => [],
})
const $contacts = await useFetch<Contact[]>('/api/db/contacts', {
  query: { type: ['customer'] },
})
const $deals = await useFetch<Deal[]>('/api/db/deals', {
  query: { $columns: ['id', 'uid', 'image', 'inquiryId'] },
  default: () => [],
})

const utils = useInquiriesUtils({ $accounts, $branches, $campaigns, $contacts, $deals })

const accounts$ = useAccounts()
const contacts$ = useContacts()
const campaigns$ = useCampaigns()
const branches$ = useBranches()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.quotes,
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const loading = ref(false)

const information = computed(() => {
  const account = utils.maps.accountId.value.get(state.value.accountId) as Partial<Account>
  const branch = utils.maps.branchId.value.get(state.value.branchId) as Partial<Branch>
  const campaign = utils.maps.campaignId.value.get(state.value.campaignId) as Partial<Campaign>
  const contact = utils.maps.contactId.value.get(state.value.contactId) as Partial<Contact>
  return {
    account: getAccountInformation(account),
    branch: getBranchInformation(branch),
    campaign: getCampaignInformation(campaign),
    contact: getContactInformation(contact),
  }
})

const onSubmitInquiry = async (event: FormSubmitEvent<Inquiry>) => {
  loading.value = true
  if (['existing'].includes(event.data.type)) {
    delete (event.data as Partial<Inquiry>).company
    delete (event.data as Partial<Inquiry>).email
    delete (event.data as Partial<Inquiry>).phone
  }
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.inquiries"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitInquiry"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
            :help="`Referencia: ${state.uid}`"
          >
            <UButtonGroup class="flex">
              <UInput
                v-model="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="optionsInquiries.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Fecha"
            name="date"
            :required="isRequired(schema, 'date')"
          >
            <InputDatetime
              :date="state.date"
              :is-datetime="state.isDatetime"
              :disabled="disabledFields?.includes('date')"
            />
          </UFormGroup>

          <UFormGroup
            label="Campaña"
            name="campaignId"
            :required="isRequired(schema, 'campaignId')"
          >
            <SelectMenuNative
              v-model="state.campaignId"
              :options="utils.options.campaignId.value"
              :disabled="disabledFields?.includes('campaignId')"
              class="flex-1"
            />
          </UFormGroup>

          <ViewRendererInformation
            title="Información de la campaña"
            :entries="information.campaign"
            :default-open="false"
          />

          <UFormGroup
            v-if="state.type === 'existing'"
            label="Cuenta"
            name="accountId"
            :required="isRequired(schema, 'accountId') && state.type === 'existing'"
          >
            <SelectMenuNative
              v-model="state.accountId"
              :options="utils.options.accountId.value"
              :disabled="disabledFields?.includes('accountId')"
              class="flex-1"
            />
          </UFormGroup>

          <ViewRendererInformation
            title="Información de la cuenta"
            :entries="information.account"
            :default-open="false"
          />

          <UFormGroup
            v-if="state.type === 'existing'"
            label="Sucursal"
            name="branchId"
            :required="isRequired(schema, 'branchId') && state.type === 'existing'"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.branchId"
                :options="utils.maps.branchesByAccountId.value.get(state.accountId)?.map(toOption) ?? []"
                :disabled="disabledFields?.includes('branchId') || !state.accountId"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('branchId') || !state.accountId"
                @click="openNested(() => branches$.slideoverOpenCreate({
                  data: { accountId: state.accountId },
                  onSubmit: async (event) => {
                    const branch = await branches$.onSubmitCreate(event.data)
                    state.branchId = branch!.id
                    await $branches.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <ViewRendererInformation
            title="Información de la sucursal"
            :entries="information.branch"
            :default-open="false"
          />

          <UFormGroup
            v-if="state.type === 'existing'"
            label="Contacto"
            name="contactId"
            :required="isRequired(schema, 'contactId') && state.type === 'existing'"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.contactId"
                :options="utils.maps.contactByAccountId.value.get(state.accountId)?.map(toOption) ?? []"
                :disabled="disabledFields?.includes('contactId') || !state.accountId"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('contactId') || !state.accountId"
                @click="openNested(() => contacts$.slideoverOpenCreate({
                  data: {
                    type: 'customer',
                    accountId: state.accountId,
                  },
                  onSubmit: async (event) => {
                    const contact = await contacts$.onSubmitCreate(event.data)
                    state.contactId = contact!.id
                    await $contacts.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <ViewRendererInformation
            title="Información del contacto"
            :entries="information.contact"
            :default-open="false"
          />

          <UFormGroup
            v-if="state.type === 'new'"
            label="Compañía"
            name="company"
            :required="isRequired(schema, 'company')"
          >
            <UInput
              v-model="state.company"
              :disabled="disabledFields?.includes('company')"
            />
          </UFormGroup>

          <UFormGroup
            v-if="state.type === 'new'"
            label="Email"
            name="email"
            :required="isRequired(schema, 'email')"
          >
            <UInput
              v-model="state.email"
              type="email"
              :disabled="disabledFields?.includes('email')"
            />
          </UFormGroup>

          <UFormGroup
            v-if="state.type === 'new'"
            label="Teléfono"
            name="phone"
            :required="isRequired(schema, 'phone')"
          >
            <InputPhone
              v-model="state.phone"
              :disabled="disabledFields?.includes('phone')"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup
            label="Origen"
            name="source"
            :required="isRequired(schema, 'source')"
          >
            <SelectMenuBaseInfo
              v-model="state.source"
              :options="optionsInquiries.source"
              :disabled="disabledFields?.includes('source')"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="optionsInquiries.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            v-if="state.type === 'new'"
            label="Cuenta"
            name="accountId"
            :required="isRequired(schema, 'accountId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuNative
                v-model="state.accountId"
                :options="utils.options.accountId.value"
                :disabled="disabledFields?.includes('accountId')"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('accountId')"
                @click="openNested(() => accounts$.slideoverOpenCreate({
                  data: { type: 'customer' },
                  onSubmit: async (event) => {
                    const account = await accounts$.onSubmitCreate(event.data)
                    state.accountId = account!.id
                    await $accounts.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            v-if="state.type === 'new'"
            label="Contacto"
            name="contactId"
            :required="isRequired(schema, 'contactId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.contactId"
                :options="utils.maps.contactByAccountId.value.get(state.accountId)?.map(toOption) ?? []"
                :disabled="disabledFields?.includes('contactId') || !state.accountId"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('contactId') || !state.accountId"
                @click="openNested(() => contacts$.slideoverOpenCreate({
                  data: { type: 'customer', accountId: state.accountId },
                  onSubmit: async (event) => {
                    const contact = await contacts$.onSubmitCreate(event.data)
                    state.contactId = contact!.id
                    await $contacts.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `inquiries/${state.id}/files` }"
            />
          </UFormGroup>
          <UFormGroup
            label="Mensaje"
            name="text"
            :required="isRequired(schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>
        </template>

        <template #data>
          <AppInquiriesFieldsetData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </Uform>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.inquiries"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
