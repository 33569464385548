<script setup lang="ts">
import type { z } from 'zod'

const model = defineModel<Address>({ required: true })

const props = defineProps<{
  label?: string
  path: string // necessary for nested objects
  disabled?: boolean
  disabledFields?: (keyof Address | string)[]
  schema: z.ZodObject<any>
}>()
</script>

<template>
  <fieldset :class="FIELDSET_FULL_2" :disabled="disabled">
    <UFormGroup
      :label=" label ?? undefined"
      class="col-span-2"
    />
    <UFormGroup
      label="Calle"
      :name="`${path}.street`"
      :required="isRequired(schema, `${path}.street`)"
    >
      <UInput
        v-model="model.street"
        type="text"
        :disabled="[`${path}.street`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="Número Exterior"
      :name="`${path}.numberExterior`"
      :required="isRequired(schema, `${path}.numberExterior`)"
    >
      <UInput
        v-model="model.numberExterior"
        type="text"
        :disabled="[`${path}.numberExterior`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="Número Interior"
      :name="`${path}.numberInterior`"
      :required="isRequired(schema, `${path}.numberInterior`)"
    >
      <UInput
        v-model="model.numberInterior"
        type="text"
        :disabled="[`${path}.numberInterior`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="Colonia"
      :name="`${path}.neighborhood`"
      :required="isRequired(schema, `${path}.neighborhood`)"
    >
      <UInput
        v-model="model.neighborhood"
        type="text"
        :disabled="[`${path}.neighborhood`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="Ciudad"
      :name="`${path}.city`"
      :required="isRequired(schema, `${path}.city`)"
    >
      <UInput
        v-model="model.city"
        type="text"
        :disabled="[`${path}.city`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="Delegación/Municipio"
      :name="`${path}.district`"
      :required="isRequired(schema, `${path}.district`)"
    >
      <UInput
        v-model="model.district"
        type="text"
        :disabled="[`${path}.district`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="Estado"
      :name="`${path}.state`"
      :required="isRequired(schema, `${path}.state`)"
    >
      <UInput
        v-model="model.state"
        type="text"
        :disabled="[`${path}.state`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="País"
      :name="`${path}.country`"
      :required="isRequired(schema, `${path}.country`)"
    >
      <SelectMenuBase
        v-model="model.country"
        :options="optionsShared.countryCode"
        value-attribute="label"
        :disabled="[`${path}.country`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="Código Postal"
      :name="`${path}.postalCode`"
      :required="isRequired(schema, `${path}.postalCode`)"
    >
      <UInput
        v-model="model.postalCode"
        type="text"
        :disabled="[`${path}.postalCode`].includes(disabledFields)"
      />
    </UFormGroup>
  </fieldset>
</template>
