import { AppCategoriesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppCategoriesSlideoverProps = ComponentProps<typeof AppCategoriesSlideover>

export type CategoriesUtils = ReturnType<typeof useCategoriesUtils>

export const useCategories = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Category>('categories')

  const slideoverOpenCreate = (props: AppCategoriesSlideoverProps) => {
    return new Promise<Category>((resolve, reject) => {
      const data = getDefaultCategory(props.data)
      slideover.open(AppCategoriesSlideover, {
        title: 'Crear categoria',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppCategoriesSlideoverProps) => {
    return new Promise<Category>((resolve, reject) => {
      const data = getDefaultCategory(props.data)
      slideover.open(AppCategoriesSlideover, {
        title: 'Editar categoria',
        ...props,
        data,
        disabledFields: ['type'],
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    name: z.string(),
    description: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Category, refresh: () => Promise<void>, utils: CategoriesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'categories', row, refresh, utils),
      getDropdownItem('copyId', 'categories', row, refresh, utils),
      getDropdownItem('archive', 'categories', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'categories', row, refresh, utils),
      getDropdownItem('files', 'categories', row, refresh, utils),
    ],
    [
      ...[
        {
          label: 'Agregar producto',
          icon: ICONS.products,
          click: () => {
            const products$ = useProducts()
            products$.slideoverOpenCreate({
              data: { categoryId: row.id },
              disabledFields: ['categoryId'],
            }).then(refresh)
          },
        },
        {
          label: 'Agregar servicio',
          icon: ICONS.services,
          click: () => {
            const services$ = useServices()
            services$.slideoverOpenCreate({
              data: { categoryId: row.id },
              disabledFields: ['categoryId'],
            }).then(refresh)
          },
        },
      ].filter((_, index) => row.type === 'products' ? index === 0 : index === 1),
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'categories', row, refresh, utils),
      getDropdownItem('inmutable', 'categories', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'categories', row, refresh, utils),
    ],
  ].filter(items => items?.length).filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useCategoriesUtils = () => {
  const options = {
    ...optionsCategories,
  }

  const maps = {
    type: toMapByKey(optionsCategories.type, 'value'),
  }

  return { options, maps }
}
