import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultServiceitem = (data: Partial<Serviceitem>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    status: 'active',
    fiscalData: {},
    tags: [],
    files: [],
    data: getDefaultServiceitemData(data?.data ?? {}),
  }, data)
}

export const serviceitemToConcept = (data: Serviceitem & { service: Service }, basePrice?: number): Partial<Quoteitem> | Partial<Salesorderitem> => {
  const { service, ...serviceitem } = data ?? {}
  return {
    type: 'services',
    subtype: service.type,
    name: serviceitem?.name,
    description: serviceitem?.description || null,
    image: serviceitem?.image ?? service.image ?? null,
    sku: serviceitem?.sku || null,
    fiscalData: {
      satProductServiceKey: service?.fiscalData?.satProductServiceKey ?? undefined,
      satUnitKey: serviceitem?.fiscalData?.satUnitKey ?? undefined,
    },
    unitPrice: basePrice,
    serviceitemId: serviceitem.id,
  }
}

export const optionsServiceitems = {
  status: optionsSharedApp.statusResource,
} satisfies Options

export const optionsServiceitemsMap = {
  'Estado': optionsServiceitems.status,
  'Datos Fiscales': {
    'Unidad SAT': optionsSAT.productUnitId,
  },
}

export const relationsServiceitems: Record<string, boolean> = {
  pricelistitems: true,
  quoteitems: true,
  salesorderitems: true,
  service: true,
}

export type ServiceitemWithRelations = Serviceitem & {
  pricelistitems: Pricelistitem[]
  quoteitems: Quoteitem[]
  salesorderitems: Salesorderitem[]
  service: Service
}

export const viewSettingsServiceitems: ViewSettings<Serviceitem> = {
  record: 'serviceitems',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'sku',
      label: 'SKU',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'serviceId',
      label: 'Servicio',
      sortable: true,
    },
    {
      key: 'service.type',
      label: 'Tipo de Servicio',
      sortable: true,
      lass: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'service.status',
      label: 'Estado de Servicio',
      sortable: true,
      lass: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'service.fiscalData.satProductServiceKey',
      label: 'Clave (SAT)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'fiscalData.satUnitKey',
      label: 'Unidad (SAT)',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
