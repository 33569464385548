import { createId, createUid, type Options } from '#netzo/utils/core/index'
import type { CalendarEvent } from '@schedule-x/calendar'
import { addMonths, format } from 'date-fns'
import { merge } from 'lodash-es'

export const getDefaultCampaign = (data: Partial<Campaign>) => {
  const uid = createUid(new Date(), 'CAM') // IMPORTANT: cloudflare throws error if called on global scope
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'outbound',
    status: 'active',
    uid: uid,
    name: uid,
    dateStart: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    dateEnd: new Date(`${format(addMonths(new Date(), 1), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    tags: [],
    files: [],
    data: getDefaultCampaignData(data?.data ?? {}),
  }, data)
}

export const getCampaignInformation = (data: Partial<Campaign>) => {
  const maps = {
    type: toMapByKey(optionsCampaigns.type, 'value'),
    status: toMapByKey(optionsCampaigns.status, 'value'),
  }
  if (!data) return []
  return [
    ['Referencia', data.uid],
    ['Nombre', data.name],
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Estado', maps.status.get(data.status!)?.label],
    ['Descripción', data.description],
  ].filter(([_, value]) => value != null)
}
export const optionsCampaigns = {
  type: [
    { value: 'outbound', label: 'Entrada', description: 'Campañas para contactar proactivamente a clientes nuevos y existentes con el fin de generar negocios.', icon: 'i-mdi-account-group', color: 'blue' },
    { value: 'inbound', label: 'Salida', description: 'Campañas diseñadas para atraer nuevos clientes y fortalecer relaciones actuales a través de la promoción de productos y servicios.', icon: 'i-mdi-bullhorn', color: 'orange' },
  ],
  status: optionsSharedApp.statusResource,
} satisfies Options

export const optionsCampaignsMap = {
  Tipo: optionsCampaigns.type,
  Estado: optionsCampaigns.status,
}

export const relationsCampaigns: Record<string, boolean> = {
  deals: true,
  inquiries: true,
  user: true,
}

export type CampaignWithRelations = Campaign & {
  deals: Deal[]
  inquiries: Inquiry[]
  user: User
}

// views:

export const campaignToViewCalendarEvent = (calendarId: keyof Campaign, utils: CampaignsUtils) => {
  return (row: Campaign): CalendarEvent => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.name}`,
      description: row.description!,
      start: format(new Date(row.dateStart), dateFormat),
      end: format(new Date(row.dateEnd), dateFormat),
      calendarId: row[calendarId],
    }
  }
}

export const viewSettingsCampaigns: ViewSettings<Campaign> = {
  record: 'campaigns',
  views: optionsShared.views.filter(view => ['table', 'cards', 'calendar'].includes(view.value)),
  selectedView: 'cards',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateStart',
      label: 'Fecha de Inicio',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateEnd',
      label: 'Fecha de Cierre',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'userId',
      label: 'Responsable',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groups: [
    { label: 'Tipo', value: 'type', options: optionsActivities.type },
    { label: 'Estado', value: 'status', options: optionsActivities.status },
  ],
  group: 'type',
}
