import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultNotification = (data: Partial<Notification>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'info',
    name: '',
    description: '',
    optionsLocal: {},
    optionsPush: {},
  }, data)
}

export const optionsNotifications = {
  type: [
    {
      value: 'info',
      label: 'Información',
      description: 'Notificación informativa que no requiere acción.',
      icon: 'i-mdi-information',
      color: 'blue',
    },
    {
      value: 'warning',
      label: 'Advertencia',
      description: 'Notificación de advertencia que requiere atención.',
      icon: 'i-mdi-alert',
      color: 'amber',
    },
    {
      value: 'error',
      label: 'Error',
      description: 'Notificación de error que requiere corrección.',
      icon: 'i-mdi-alert-circle',
      color: 'red',
    },
    {
      value: 'success',
      label: 'Éxito',
      description: 'Notificación de éxito que confirma una acción.',
      icon: 'i-mdi-check-circle',
      color: 'green',
    },
  ],
} satisfies Options

export const optionsNotificationsMap = {}

export const relationsNotifications: Record<string, boolean> = {
  usernotifications: true,
}

export type NotificationWithRelations = Notification & {
  usernotifications: Usernotification[]
}

// runtime:

export const createNotification = async (
  notification: Notification,
  userIds: Usernotification['userId'][],
) => {
  const { onSubmitCreate } = useApiDB<Notification>('notifications')
  const { onSubmitCreate: onSubmitCreateUsernotification } = useApiDB<Usernotification>('usernotifications')

  if (userIds.length) {
    const data = await onSubmitCreate(getDefaultNotification(notification))

    await Promise.all(userIds.map(async (userId) => {
      await onSubmitCreateUsernotification({
        ...data,
        userId,
        notificationId: data!.id,
        read: false,
      })
    }))
  }
}
