<script setup lang="ts">
const { user } = useUserSession()

const $usernotifications = await useFetch<Usernotification[]>('/api/db/usernotifications', {
  key: `usernotifications-${user.value?.id}`,
  query: { userId: user.value?.id, $with: { notification: true } },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  // key: `usernotifications-${user.value?.id}`,
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenList,
  modal,
  modalOpenDelete,
  getDropdownItems,
} = useUsernotifications()

const utils = useUsernotificationsUtils({ $users })
</script>

<template>
  <UDashboardSlideover title="Notificaciones">
    <div class="flex flex-col h-full gap-3">
      <ViewPlaceholder
        :loading="['pending'].includes($usernotifications.status.value)"
        :rows="$usernotifications.data.value"
        icon="i-mdi-bell"
        label="No hay notificaciones para mostrar."
      >
        <div class="flex-1 h-full overflow-y-auto flex flex-col justify-start gap-3">
          <AppUsernotificationsCardNotification
            v-for="usernotification in $usernotifications.data.value"
            :key="`usernotification-${usernotification.id}`"
            :usernotification="usernotification"
            :utils="utils"
            :dropdown-items="getDropdownItems(usernotification, $usernotifications.refresh, utils)"
          />
        </div>
      </ViewPlaceholder>
    </div>
  </UDashboardSlideover>
</template>
