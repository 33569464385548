<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types';
import { parseISO } from 'date-fns';

const props = defineProps<{
  data: Partial<Serviceorder>
  onSubmit: (event: FormSubmitEvent<Partial<Serviceorder>>) => void
  title?: string
  disabledFields?: (keyof Serviceorder | string)[]
  readonly?: boolean
}>()

const router = useRouter()
const { user } = useUserSession()
const { moduleId, userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Serviceorder>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  modalOpenStageApproval,
  schema,
  getDropdownItems,
  createPdf,
  createFileServiceorderPdf,
} = useServiceorders()

const contacts$ = useContacts()

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: { $columns: ['id', 'name', 'image', 'billing'] },
  default: () => [],
})
const $branches = await useFetch<Branch[]>('/api/db/branches', {
  query: {},
  default: () => [],
})
const $contacts = await useFetch<Contact[]>('/api/db/contacts', {
  query: { type: ['customer'] },
  default: () => [],
})
const $productionorders = await useFetch<Productionorder[]>('/api/db/productionorders', {
  query: { $columns: ['id', 'name', 'salesorderId'] },
  default: () => [],
})
const $salesorders = await useFetch<SalesorderWithRelations[]>('/api/db/salesorders', {
  query: { $with: relationsSalesorders },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { type: ['internal'], $columns: ['id', 'name', 'image'] },
  default: () => [],
})
const utils = useServiceordersUtils({ $accounts, $branches, $contacts, $productionorders, $salesorders, $users })

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.serviceorders,
      defaultOpen: true,
    },
    {
      slot: 'address',
      label: 'Entrega',
      icon: 'i-mdi-truck-delivery',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
    {
      slot: 'pdfSettings',
      label: 'Configuración del PDF',
      icon: 'i-mdi-file-pdf',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateSalesorderId = async (id: string) => {
  const salesorder = utils.maps.salesorderId.value.get(id)
  if (salesorder) {
    state.value.name = salesorder.name
    state.value.accountId = salesorder.accountId
    state.value.branchId = salesorder.branchId
    state.value.contactId = salesorder.contactId
    state.value.useAccountAddress = salesorder.delivery.useAccountAddress
    state.value.address = salesorder.delivery.address
  }
}

const informationSalesorder = computed(() => {
  const salesorder = utils.maps.salesorderId.value.get(state.value.salesorderId) as Salesorder & { user: User, account: Account }
  if (!salesorder) return []
  const map = {
    schedule: toMapByKey(optionsDeliveries.schedule, 'value'),
    incoterm: toMapByKey(optionsDeliveries.incoterm, 'value'),
  }
  const delivery = salesorder?.delivery.schedule !== 'scheduled'
    ? map.schedule.get(salesorder?.delivery.schedule)?.label
    : !salesorder?.delivery.isDatetime ? parseISO(salesorder?.delivery?.date).toLocaleDateString() : parseISO(salesorder?.delivery?.date).toLocaleString()

  const localDate = parseISO(salesorder?.date)
  const date = !salesorder?.isDatetime ? localDate.toLocaleDateString() : localDate.toLocaleString()
  return [
    ['Referencia', salesorder?.uid],
    ['Nombre', salesorder?.name],
    ['Vendedor', salesorder?.user?.name],
    ['Cliente', salesorder?.account?.name],
    ['Fecha de recepción', date],
    ['Terminos de entrega', map.incoterm.get(salesorder?.delivery?.incoterm)?.label],
    ['Entrega', delivery],
    salesorder?.branchId && ['Sucursal', salesorder?.branch?.name],
    ['Dirección de entrega', formatAddress(salesorder?.delivery?.address)],
  ]
})

const contactInformation = computed(() => {
  const contact = utils.maps.contactId.value.get(state.value.contactId)
  return getContactInformation(contact)
})

const onToggleUseAccountAddress = () => {
  state.value.useAccountAddress = !state.value.useAccountAddress
}

const onUpdateAddress = () => {
  if (state.value.useAccountAddress) {
    const account = utils.maps.accountId.value.get(state.value.accountId)
    if (account) {
      state.value.address = account.billing?.address ?? {} as Address
    }
  }
  else if (state.value.branchId) {
    state.value.address = utils.maps.branchId.value.get(state.value.branchId)?.address ?? {} as Address
  }
}

const onUpdateBranchId = async (branchId: string) => {
  state.value.address = {} as Address
  onUpdateAddress()
}

watch(() => state.value.useAccountAddress, () => {
  onUpdateAddress()
})

const loading = ref(false)

const onSubmitServiceorder = async (event: FormSubmitEvent<Partial<Serviceorder>>) => {
  loading.value = true

  // WORKAROUND: add ommited props to work-around UForm bug omitting nested properties when validating
  event.data.delivery = state.value.delivery

  if (['pending', 'EXW'].includes(event.data?.delivery?.incoterm)) {
    delete event.data.branchId
    event.data.delivery.address = {}
  }
  if (event.data?.delivery?.useAccountAddress) delete event.data.branchId

  await props.onSubmit(event)
  router.push(`/${moduleId.value}/serviceorders/${event.data.id}/items`)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.serviceorders"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitServiceorder"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
            :help="`Referencia: ${state.uid}`"
          >
            <UButtonGroup class="flex">
              <UInput
                v-model="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Orden de venta"
            name="salesorderId"
            :required="isRequired(schema, 'salesorderId')"
          >
            <div class="w-full flex gap-1">
              <UButtonGroup class="flex-1">
                <SelectMenuBase
                  v-model="state.salesorderId"
                  :options="utils.options.salesorderId.value"
                  :disabled="disabledFields?.includes('salesorderId')"
                  class="flex-1"
                  @update:model-value="onUpdateSalesorderId"
                />
                <UButton icon="i-mdi-plus" disabled />
              </UButtonGroup>
              <ButtonRefresh
                toast="Los campos relacionados a la orden de venta se han actualizado correctamente."
                @click="onUpdateSalesorderId(state.salesorderId)"
              />
            </div>
          </UFormGroup>

          <ViewRendererInformation
            title="Información de la orden de venta"
            :default-open="false"
            :entries="informationSalesorder"
          />

          <UFormGroup
            label="Orden de producción"
            name="productionorderId"
            :required="isRequired(schema, 'productionorderId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.productionorderId"
                :options="utils.maps.productionordersBySalesorderId.value.get(state.salesorderId)?.map(toOption) ?? []"
                :disabled="disabledFields?.includes('productionorderId') || !state.salesorderId"
                class="flex-1"
              />
              <UButton icon="i-mdi-plus" disabled />
            </UButtonGroup>
          </UFormGroup>

          <FieldsetDateRange
            v-model:is-datetime="state.isDatetime"
            v-model:date-start="state.dateStart"
            v-model:date-end="state.dateEnd"
            :is-required-start-date="isRequired(schema, 'dateStart')"
            :is-required-end-date="isRequired(schema, 'dateEnd')"
            :disabled-fields="disabledFields"
          />
        </template>

        <template #details>
          <UFormGroup
            label="Etapa"
            name="stage"
            :required="isRequired(schema, 'stage')"
          >
            <SelectMenuBaseInfo
              v-model="state.stage"
              :options="utils.options.stage"
              :disabled="disabledFields?.includes('stage')"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="utils.options.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Prioridad"
            name="priority"
            :required="isRequired(schema, 'priority')"
          >
            <SelectMenuBaseInfo
              v-model="state.priority"
              :options="utils.options.priority"
              :disabled="disabledFields?.includes('priority')"
            />
          </UFormGroup>

          <UFormGroup
            label="Responsable"
            name="userId"
            :required="isRequired(schema, 'userId')"
          >
            <SelectMenuBase
              v-model="state.userId"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userId')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `serviceorders/${state.id}/files` }"
            />
          </UFormGroup>

          <UFormGroup
            label="Detalles"
            name="text"
            :required="isRequired(schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>
        </template>

        <template #address>
          <UFormGroup
            label="Contacto"
            name="contactId"
            :required="isRequired(schema, 'contactId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.contactId"
                :options="utils.maps.contactsByAccountId.value.get(state.accountId)?.map(toOption) ?? []"
                :disabled="disabledFields?.includes('contactId')"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('contactId')"
                @click="openNested(() => contacts$.slideoverOpenCreate({
                  data: { type: 'customer', accountId: state.accountId },
                  disabledFields: ['type', 'accountId'],
                  onSubmit: async (event) => {
                    const contact = await contacts$.onSubmitCreate(event.data)
                    state.contactId = contact!.id
                    await $contacts?.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <ViewRendererInformation
            title="Información del contacto"
            :entries="contactInformation"
            :default-open="false"
          />

          <UFormGroup
            label="Dirección de entrega"
            name="branchId"
            :required="state.useAccountAddress"
          >
            <div
              v-if="state.useAccountAddress"
              class="w-full flex items-center gap-1"
            >
              <UButton
                icon="i-mdi-checkbox-marked"
                square
                variant="outline"
                :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
                :disabled="['EXW', 'pending'].includes(state?.incoterm)"
                @click="onToggleUseAccountAddress"
              />
              <div class="w-full flex gap-1 pl-1">
                <UButtonGroup class="flex-1">
                  <InputReadOnly
                    model-value="Entregar en domicilio fiscal"
                    class="flex-1"
                  />
                  <UButton icon="i-mdi-plus" disabled />
                </UButtonGroup>
                <ButtonRefresh
                  toast="Dirección actualizada"
                  @click="onUpdateAddress()"
                />
              </div>
            </div>
            <UButtonGroup v-else class="w-full">
              <UButton
                icon="i-mdi-checkbox-blank-outline"
                square
                variant="outline"
                :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
                @click="onToggleUseAccountAddress"
              />
              <div class="w-full flex gap-1 pl-1">
                <UButtonGroup class="flex-1">
                  <SelectMenuBase
                    v-model="state.branchId"
                    :options="utils.maps.branchesByAccountId?.value.get(state.accountId)?.map(toOption) ?? []"
                    class="flex-1"
                    :disabled="disabledFields?.includes('branchId')"
                    @update:model-value="onUpdateBranchId"
                  />
                  <UButton
                    icon="i-mdi-plus"
                    :disabled="disabledFields?.includes('branchId')"
                    @click="openNested(() => branches$.slideoverOpenCreate({
                      data: { accountId: state.accountId },
                      onSubmit: async (event) => {
                        const branch = await branches$.onSubmitCreate(event.data)
                        state.branchId = branch!.id
                        await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                      },
                    }))"
                  />
                </UButtonGroup>
                <ButtonRefresh
                  toast="Dirección actualizada"
                  @click="onUpdateAddress()"
                />
              </div>
            </UButtonGroup>
          </UFormGroup>

          <FieldsetAddress
            v-model="state.address"
            disabled
            v-bind="{ schema, path: 'address', disabledFields }"
          />
        </template>

        <template #pdfSettings>
          <FieldsetPdfSettings
            v-model="state!.pdfSettings"
            :cols="2"
          />
        </template>

        <template #data>
          <AppServiceordersFieldsetData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.serviceorders"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
