import { AppPricelistitemsSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppPricelistitemsSlideoverProps = ComponentProps<typeof AppPricelistitemsSlideover>

export type PricelistitemsUtils = ReturnType<typeof usePricelistitemsUtils>

export const usePricelistitems = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Pricelistitem>('pricelistitems')

  const slideoverOpenCreate = (props: AppPricelistitemsSlideoverProps) => {
    return new Promise<Pricelistitem>((resolve, reject) => {
      const data = getDefaultPricelistitem(props.data)
      slideover.open(AppPricelistitemsSlideover, {
        title: 'Crear precio',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppPricelistitemsSlideoverProps) => {
    return new Promise<Pricelistitem > ((resolve, reject) => {
      const data = getDefaultPricelistitem(props.data)
      slideover.open(AppPricelistitemsSlideover, {
        title: 'Editar precio',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    currency: z.string(),
    basePrice: z.number(),
    pricelistId: z.string(),
  }).required()

  // actions:

  const getItem = (row: Pricelistitem): Productitem | Serviceitem => {
    return row?.productitem ?? row?.serviceitem
  }

  const getDropdownItems = (row: Pricelistitem, refresh: () => Promise<void>, utils: PricelistitemsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'pricelistitems', row, refresh, utils),
      getDropdownItem('copyId', 'pricelistitems', row, refresh, utils),
      getDropdownItem('archive', 'pricelistitems', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'pricelistitems', row, refresh, utils),
      getDropdownItem('files', 'pricelistitems', row, refresh, utils),
    ],
    // resource actions:
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'pricelistitems', row, refresh, utils),
      {
        label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        disabled: row?.pricelist?.$inmutable,
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      getDropdownItem('delete', 'pricelistitems', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getItem,
    getDropdownItems,
  }
}

export const usePricelistitemsUtils = ({ $pricelists, $productitems, $serviceitems }: {
  $pricelists?: Awaited<ReturnType<typeof useFetch<Pricelist[]>>>
  $productitems?: Awaited<ReturnType<typeof useFetch<Productitem[]>>>
  $serviceitems?: Awaited<ReturnType<typeof useFetch<Serviceitem[]>>>
} = {}) => {
  const options = {
    ...optionsPricelistitems,
    currency: optionsPricelistitems.currency,
    pricelistId: computed(() => $pricelists?.data.value.map(toOption)),
    productitemId: computed(() => $productitems?.data.value.map(toOption)),
    serviceitemId: computed(() => $serviceitems?.data.value.map(toOption)),
  }

  const maps = {
    type: {
      pricelists: toMapByKey(optionsPricelists.type, 'value'),
      products: toMapByKey(optionsProducts.type, 'value'),
      services: toMapByKey(optionsServices.type, 'value'),
    },
    status: {
      pricelists: toMapByKey(optionsPricelists.status, 'value'),
      products: toMapByKey(optionsProducts.status, 'value'),
      services: toMapByKey(optionsServices.status, 'value'),
    },
    fiscalData: {
      satUnitKey: toMapByKey(optionsSAT.productUnitId, 'value'),
    },
    currency: toMapByKey(optionsPricelistitems.currency, 'value'),
    pricelistId: computed(() => toMapByKey($pricelists?.data.value, 'id')),
    productitemId: computed(() => toMapByKey($productitems?.data.value, 'id')),
    serviceitemId: computed(() => toMapByKey($serviceitems?.data.value, 'id')),
  }

  return { options, maps }
}
