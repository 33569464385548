<script setup lang="ts">
import { format, parseISO } from 'date-fns'

const isDatetime = defineModel<boolean | null>('isDatetime')
const dateStart = defineModel<string | null>('dateStart')
const dateEnd = defineModel<string | null>('dateEnd')

const props = defineProps<{
  isRequiredStartDate?: boolean
  isRequiredEndDate?: boolean
  dateStartLabel?: string
  dateEndLabel?: string
  disabled?: boolean
  disabledFields?: string[]
}>()

const formatStartDate = computed(() => dateStart.value ? format(parseISO(dateStart.value), isDatetime.value ? 'yyyy-MM-dd\'T\'HH:mm' : 'yyyy-MM-dd') : '')
const formatEndDate = computed(() => dateEnd.value ? format(parseISO(dateEnd.value), isDatetime.value ? 'yyyy-MM-dd\'T\'HH:mm' : 'yyyy-MM-dd') : '')

const onUpdateStartDate = (event: string) => {
  dateStart.value = isDatetime.value ? new Date(event).toISOString() : new Date(`${event}T00:00:00`).toISOString()
}

const onUpdateEndDate = (event: string) => {
  dateEnd.value = isDatetime.value ? new Date(event).toISOString() : new Date(`${event}T00:00:00`).toISOString()
}

const FIELDSET = `w-full grid grid-cols-1 md:grid-cols-2 gap-2 !mt-4 !mb-2 p-4 border rounded-lg`
</script>

<template>
  <fieldset :class="FIELDSET" :disabled="disabled">
    <UFormGroup
      :label="dateStartLabel || 'Inicio'"
      name="dateStart"
      :required="isRequiredStartDate"
    >
      <UInput
        :model-value="formatStartDate"
        :type="isDatetime ? 'datetime-local' : 'date'"
        v-bind="$attrs"
        :size="isDatetime ? 'xs' : 'sm'"
        :disabled="disabledFields?.includes('dateStart')"
        @update:model-value="onUpdateStartDate($event)"
      />
    </UFormGroup>

    <UFormGroup
      :label="dateEndLabel || 'Fin'"
      name="dateEnd"
      :required="isRequiredEndDate"
    >
      <UInput
        :model-value="formatEndDate"
        :type="isDatetime ? 'datetime-local' : 'date'"
        v-bind="$attrs"
        :size="isDatetime ? 'xs' : 'sm'"
        :disabled="disabledFields?.includes('dateEnd')"
        @update:model-value="onUpdateEndDate($event)"
      />
    </UFormGroup>

    <UFormGroup name="isDatetime">
      <div class="w-full flex items-center gap-2 text-xs">
        <UCheckbox
          id="is-datetime-checkbox"
          v-model="isDatetime"
          :disabled="disabledFields?.includes('isDatetime')"
        />
        <label for="is-datetime-checkbox">Fecha y hora</label>
      </div>
    </UFormGroup>
  </fieldset>
</template>
