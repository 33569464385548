<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Pricelistitem>
  onSubmit: (event: FormSubmitEvent<Partial<Pricelistitem>>) => void
  title?: string
  disabledFields?: (keyof Pricelistitem | string)[]
  readonly?: boolean
}>()

const route = useRoute()
const { user } = useUserSession()
const { userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Pricelistitem>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getItem,
  getDropdownItems,
} = usePricelistitems()

const $pricelists = await useFetch<PricelistWithRelations[]>('/api/db/pricelists', {
  query: { $with: relationsPricelists },
  default: () => [],
})

const utils = usePricelistitemsUtils({ $pricelists })

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.pricelistitems,
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const filteredIds = ref([]) as Ref<string[]>

const onUpdatePricelistId = async (pricelistId: string) => {
  const $pricelistitems = await useFetch<Pricelistitem[]>('/api/db/pricelistitems', {
    query: { pricelistId: pricelistId, $columns: ['id', 'productitemId', 'serviceitemId'] },
    default: () => [],
  })

  const type = utils.maps.pricelistId.value.get(pricelistId)?.type
  if (type === 'products') {
    filteredIds.value = $pricelistitems.data.value.map(item => item.productitemId).filter(id => id !== props.data.productitemId)
  }
  else {
    filteredIds.value = $pricelistitems.data.value.map(item => item.serviceitemId).filter(id => id !== props.data.serviceitemId)
  }
}

if (props.data.pricelistId) {
  onUpdatePricelistId(props.data.pricelistId)
}

const loading = ref(false)

const onSubmitPricelistitem = async (event: FormSubmitEvent<Partial<Pricelistitem>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.pricelistitems"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitPricelistitem"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Lista de precios"
            name="pricelistId"
            :required="isRequired(schema, 'pricelistId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.pricelistId"
                :options="utils.options.pricelistId.value"
                :disabled="disabledFields?.includes('pricelistId')"
                class="flex-1"
                @update:model-value="onUpdatePricelistId"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('pricelistId')"
                @click="openNested(() => pricelists$.slideoverOpenCreate({
                  onSubmit: async (event) => {
                    const pricelist = await pricelists$.onSubmitCreate(event.data)
                    state.pricelistId = pricelist!.id
                    await $pricelists.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <SelectMenuProductitemId
            v-if="['products'].includes(utils.maps.pricelistId.value.get(state.pricelistId)?.type) ?? false"
            v-model="state"
            :disabled="disabledFields?.includes('productitemId') || !state.pricelistId"
            v-bind="{ schema, filteredIds }"
          />

          <SelectMenuServiceitemId
            v-else
            v-model="state"
            :disabled="disabledFields?.includes('serviceitemId') || !state.pricelistId"
            v-bind="{ schema, filteredIds }"
          />

          <UFormGroup
            :label="`Precio base (${state.currency})`"
            name="basePrice"
            :required="isRequired(schema, 'basePrice')"
          >
            <InputCurrency
              v-model="state.basePrice"
              icon
              :disabled="disabledFields?.includes('basePrice')"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `pricelistitems/${state.id}/files` }"
            />
          </UFormGroup>
        </template>

        <template #data>
          <AppPricelistitemsFieldsetData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.pricelistitems"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
