<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Serviceorderitem>
  onSubmit: (event: FormSubmitEvent<Partial<Serviceorderitem>>) => void
  title?: string
  disabledFields?: (keyof Serviceorderitem | string)[]
  readonly?: boolean
}>()

const router = useRouter()
const { user } = useUserSession()
const { moduleId, userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Serviceorderitem>>(getDefaultServiceorderitem(props.data))

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
  createPdf,
  createFileServiceorderitemPdf,
} = useServiceorderitems()

const $serviceorders = await useFetch<Serviceorder[]>('/api/db/serviceorders', {
  query: { $columns: ['id', 'type', 'name', 'uid', 'salesorderId', 'userId'] },
  default: () => [],
})
const $salesorders = await useFetch<Salesorder[]>('/api/db/salesorders', {
  query: { $columns: ['id', 'uid', 'salesorderId', 'userId'] },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { type: ['internal'], $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const utils = useServiceorderitemsUtils({ $serviceorders, $salesorders, $users })

const serviceorders$ = useServiceorders()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.serviceorderitems,
      defaultOpen: true,
    },
    {
      slot: 'tasks',
      label: 'Tareas',
      icon: 'i-mdi-checkbox-multiple-marked',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateServiceorderId = (id: string) => {
  const serviceorder = utils.maps.serviceorderId.value.get(id)
  state.value.userId = serviceorder?.userId
}

const loading = ref(false)
const onSubmitServiceorderitem = async (event: FormSubmitEvent<Partial<Serviceorderitem>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.serviceorderitems"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitServiceorderitem"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              autofocus
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Orden de Servicio"
            name="serviceorderId"
            :required="isRequired(schema, 'serviceorderId')"
          >
            <div class="w-full flex gap-1">
              <UButtonGroup class="flex-1">
                <SelectMenuBase
                  v-model="state.serviceorderId"
                  :options="utils.options.serviceorderId.value"
                  :disabled="disabledFields?.includes('serviceorderId')"
                  class="flex-1"
                  @update:model-value="onUpdateServiceorderId"
                />
                <UButton
                  icon="i-mdi-plus"
                  :disabled="disabledFields?.includes('serviceorderId')"
                  @click="openNested(() => serviceorders$.slideoverOpenCreate({
                    onSubmit: async (event) => {
                      const serviceorder = await serviceorders$.onSubmitCreate(event.data)
                      state.serviceorderId = serviceorder!.id
                      await $serviceorders.refresh()
                      await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                    },
                  }))"
                />
              </UButtonGroup>
              <ButtonRefresh
                toast="Información relacionada a la orden de producción actualizada correctamente."
                :disabled="!state.serviceorderId"
                @click="onUpdateServiceorderId(state.serviceorderId!)"
              />
            </div>
          </UFormGroup>

          <AppServiceorderitemsFieldsetServiceitem
            v-model="state"
            :salesorder-id="utils.maps.serviceorderId.value.get(state.serviceorderId)?.salesorderId"
            :disabled="disabledFields?.includes('serviceitemId')"
            v-bind="{ data, onSubmit, schema, title, disabledFields, slideoverOpenEdit }"
          />
        </template>

        <template #details>
          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="utils.options.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Responsable"
            name="userId"
            :required="isRequired(schema, 'userId')"
          >
            <SelectMenuBase
              v-model="state.userId"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userId') || !state.serviceorderId"
            />
          </UFormGroup>

          <div :class="FIELDSET_FULL_2">
            <UFormGroup
              label="Cantidad (plan)"
              name="quantityPlanned"
              :required="isRequired(schema, 'quantityPlanned')"
            >
              <UInput
                v-model="state.quantityPlanned"
                type="number"
                inputmode="decimal"
                :min="0.00"
                :step="0.01"
                :disabled="disabledFields?.includes('quantityPlanned')"
              />
            </UFormGroup>

            <UFormGroup
              label="Cantidad (real)"
              name="quantityActual"
              :required="isRequired(schema, 'quantityActual')"
            >
              <UInput
                v-model="state.quantityActual"
                type="number"
                inputmode="decimal"
                :min="0.00"
                :step="0.01"
                :disabled="disabledFields?.includes('quantityActual')"
              />
            </UFormGroup>
          </div>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup
            label="Imágenes"
            name="images"
            :required="isRequired(schema, 'images')"
          >
            <InputImages
              v-model="state.images"
              accept="*"
              :disabled="disabledFields?.includes('images')"
              :query="{ prefix: `serviceorderitems/${state.id}/images` }"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `serviceorderitems/${state.id}/files` }"
            />
          </UFormGroup>
        </template>

        <template #data>
          <AppServiceorderitemsFieldsetData v-model="state" :utils="utils" />
        </template>

        <template #tasks>
          <InputChecklist v-model="state.tasks" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.serviceorderitems"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
