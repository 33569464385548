import { merge } from 'lodash-es'

export const getDefaultNote = (data: Partial<Note>) => {
  return merge({
    text: '',
  }, data)
}

export const optionsNotes = {} satisfies Options

export const optionsNotesMap = {}

export const relationsNotes: Record<string, boolean> = {
  user: true,
}

export type NoteWithRelations = Note & {
  user: User
}
