<script setup lang="ts">
const model = defineModel<Serviceorderitem>({ required: true })

const props = defineProps<{
  utils: ServiceorderitemsUtils
  disabled?: boolean
}>()
</script>

<template>
  <details open>
    <summary class="mt-4 mb-2 cursor-pointer font-bold text-gray-700">
      Aprobación
    </summary>
    <fieldset :class="FIELDSET" :disabled="disabled">
      <UFormGroup
        label="Items de Orden de Producción"
        name="productionorderitemIds"
      >
        <SelectMenuBase
          v-model="state.productionorderitemId"
          :options="data.productionorderitems.map(toOption)"
          multiple
        />
      </UFormGroup>

      <UFormGroup
        label="Aprobación"
        name="data.approvalRequired"
      >
        <div
          class="w-full flex items-center gap-1"
        >
          <UButton
            :icon="model.data.approvalRequired ? 'i-mdi-checkbox-marked' : 'i-mdi-checkbox-blank-outline'"
            square
            variant="outline"
            :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
            @click="model.data.approvalRequired = !model.data.approvalRequired"
          />
          <SelectMenuBaseInfo
            v-model="model.data.approvalStatus"
            :options="utils.options.data.status"
            class="flex-1"
          />
        </div>
      </UFormGroup>

      <UFormGroup
        label="Comentarios"
        name="data.approvalText"
      >
        <UTextarea
          v-model="model.data.approvalText"
          autoresize
          disabled
        />
      </UFormGroup>
    </fieldset>
  </details>
</template>
