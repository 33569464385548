<script setup lang="ts">
import { format, parseISO } from 'date-fns'

const model = defineModel<string | null | undefined>({ required: true })
const isDatetime = defineModel<boolean>('isDatetime')

const props = defineProps<{
  required?: boolean
  disabled?: boolean
  label?: string
  hideCheckbox?: boolean
}>()

const formatModel = computed(() => model.value ? format(parseISO(model.value), isDatetime.value ? 'yyyy-MM-dd\'T\'HH:mm' : 'yyyy-MM-dd') : '')

const onUpateModel = (event: string) => {
  model.value = isDatetime.value ? new Date(event).toISOString() : new Date(`${event}T00:00:00`).toISOString()
}
</script>

<template>
  <UInput
    :model-value="formatModel"
    :type="isDatetime ? 'datetime-local' : 'date'"
    v-bind="$attrs"
    :disabled="disabled"
    @update:model-value="onUpateModel($event)"
  />
  <UFormGroup v-if="!hideCheckbox" name="isDatetime">
    <div class="w-full flex items-center mt-2 gap-2">
      <UCheckbox
        id="is-datetime-checkbox"
        v-model="isDatetime"
        :disabled="disabled"
      />
      <label for="is-datetime-checkbox">Fecha y hora</label>
    </div>
  </UFormGroup>
</template>
