<script setup lang="ts">
const model = defineModel({ required: true })

const props = defineProps<{
  options: Option[]
  disabled?: boolean
  multiple?: boolean
}>()

const emit = defineEmits(['click:create'])
</script>

<template>
  <UButtonGroup class="flex-1 flex">
    <SelectMenuBase
      v-model="model"
      :options="options"
      searchable
      :disabled="disabled"
      :multiple="multiple"
      trailing
      class="flex-1"
    />
    <UButton
      icon="i-mdi-plus"
      :disabled="disabled"
      @click="emit('click:create')"
    />
  </UButtonGroup>
</template>
