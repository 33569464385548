import { AppServiceorderitemsSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppServiceorderitemsSlideoverProps = ComponentProps<typeof AppServiceorderitemsSlideover>

export type ServiceorderitemsUtils = ReturnType<typeof useServiceorderitemsUtils>

export const useServiceorderitems = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Serviceorderitem>('serviceorderitems')

  const slideoverOpenCreate = (props: AppServiceorderitemsSlideoverProps) => {
    return new Promise<Serviceorderitem>((resolve, reject) => {
      const data = getDefaultServiceorderitem(props.data)
      slideover.open(AppServiceorderitemsSlideover, {
        type: 'POST',
        title: 'Agregar item',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppServiceorderitemsSlideoverProps) => {
    return new Promise<Serviceorderitem>((resolve, reject) => {
      const data = getDefaultServiceorderitem(props.data)
      slideover.open(AppServiceorderitemsSlideover, {
        title: 'Editar item',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    status: z.string(),
    name: z.string(),
    quantityPlanned: z.number(),
    serviceorderId: z.string(),
    serviceitemId: z.string(),
    userId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Serviceorderitem, refresh: () => Promise<void>, utils: ServiceorderitemsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'serviceorderitems', row, refresh, utils),
      getDropdownItem('copyId', 'serviceorderitems', row, refresh, utils),
      getDropdownItem('archive', 'serviceorderitems', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'serviceorderitems', row, refresh, utils),
      getDropdownItem('files', 'serviceorderitems', row, refresh, utils),
    ],
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'serviceorderitems', row, refresh, utils),
      {
        label: `${row.$inmutable ? 'Habilitar' : 'Deshabilitar'}`,
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        disabled: row?.serviceorder?.$inmutable,
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      getDropdownItem('delete', 'serviceorderitems', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useServiceorderitemsUtils = ({ $serviceorders, $salesorders, $salesorderitems, $users }: {
  $serviceorders?: Awaited<ReturnType<typeof useFetch<Serviceorder[]>>>
  $salesorders?: Awaited<ReturnType<typeof useFetch<Salesorder[]>>>
  $salesorderitems?: Awaited<ReturnType<typeof useFetch<Salesorderitem[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsServiceorderitems,
    concept: {
      category: optionsCategories.type,
      type: {
        products: optionsProducts.type,
        services: optionsServices.type,
      },
    },
    serviceorderId: computed(() => $serviceorders?.data.value.map(toOption)),
    salesorderId: computed(() => $salesorders?.data.value.map(toOption)),
    salesorderitemId: computed(() => $salesorderitems?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsServiceorderitems.type, 'value'),
    status: toMapByKey(optionsServiceorderitems.status, 'value'),
    data: {
      approvalStatus: toMapByKey(optionsServiceorderitems.data.approvalStatus, 'value'),
    },
    serviceorder: {
      type: toMapByKey(optionsServiceorders.type, 'value'),
    },
    serviceorderId: computed(() => toMapByKey($serviceorders?.data.value, 'id')),
    salesorderId: computed(() => toMapByKey($salesorders?.data.value, 'id')),
    salesorderitemId: computed(() => toMapByKey($salesorderitems?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    fullfillmentordersByType: computed(() => toMapByGroupKey($serviceorders?.data.value, 'type')),
  }
  return { options, maps }
}
