import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultBranch = (data: Partial<Branch>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    status: 'active',
    address: {},
    tags: [],
    files: [],
    data: getDefaultBranchData(data?.data ?? {}),
  }, data)
}

export const getBranchInformation = (data: Partial<Branch>) => {
  const maps = {
    status: toMapByKey(optionsBranches.status, 'value'),
  }
  if (!data) return []
  return [
    ['Nombre', data.name],
    ['Estado', maps.status.get(data.status!)?.label],
    ['Teléfono', data.phone],
    ['Dirección', formatAddress(data?.address as Address)],
  ].filter(([_, value]) => value != null)
}

export const optionsBranches = {
  status: optionsSharedApp.statusResource,
} satisfies Options

export const optionsBranchesMap = {
  Estado: optionsBranches.status,
}

export const relationsBranches: Record<string, boolean> = {
  account: true,
  activities: true,
  contacts: true,
  deals: true,
  serviceorders: true,
  inquiries: true,
}

export type BranchWithRelations = Branch & {
  account: Account
  activities: Activity[]
  contacts: Contact[]
  deals: Deal[]
  serviceorders: Serviceorder[]
  inquiries: Inquiry[]
}

export const viewSettingsBranches: ViewSettings<Branch> = {
  record: 'branches',
  views: optionsShared.views.filter(view => ['table', 'cards'].includes(view.value)),
  selectedView: 'cards',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'phone',
      label: 'Teléfono',
    },
    {
      key: 'address',
      label: 'Dirección',
      class: 'min-w-[300px]',
    },
    {
      key: 'accountId',
      label: 'Cuenta',
    },
    {
      key: 'deals',
      label: 'Negocios',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
