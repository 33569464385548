import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultUsernotification = (data: Partial<Usernotification>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'info',
    name: '',
    description: '',
    optionsLocal: {},
    optionsPush: {},
  }, data)
}

export const optionsUsernotifications = {} satisfies Options

export const optionsUsernotificationsMap = {
  Tipo: optionsNotifications.type,
}

export const relationsUsernotifications: Record<string, boolean> = {
  notification: true,
  user: true,
}

export const viewSettingsNotifications: ViewSettings<Usernotification> = {
  record: 'usernotifications',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'notificationType',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'notificationName',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'notificationDescription',
      label: 'Descripción',
    },
    ...COLUMNS_METADATA,
  ],
}
