import { AppQuotesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppQuotesSlideoverProps = ComponentProps<typeof AppQuotesSlideover>

export type QuotesUtils = ReturnType<typeof useQuotesUtils>

export const useQuotes = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Quote>('quotes')

  const slideoverOpenCreate = (props: AppQuotesSlideoverProps) => {
    return new Promise<Quote>((resolve, reject) => {
      const data = getDefaultQuote(props.data)
      slideover.open(AppQuotesSlideover, {
        type: 'POST',
        title: 'Crear cotización',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppQuotesSlideoverProps) => {
    return new Promise<Quote>((resolve, reject) => {
      const data = getDefaultQuote(props.data)
      slideover.open(AppQuotesSlideover, {
        title: 'Editar cotización',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    status: z.string(),
    stage: z.string(),
    type: z.string(),
    name: z.string(),
    issueDate: z.string(),
    expirationDate: z.string(),
    payment: paymentSchema,
    delivery: deliverySchema,
    accountId: z.string(),
    dealId: z.string(),
    userId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Quote, refresh: () => Promise<void>, utils: QuotesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'quotes', row, refresh, utils),
      getDropdownItem('items', 'quotes', row, refresh, utils),
      getDropdownItem('copyId', 'quotes', row, refresh, utils),
      getDropdownItem('copyUid', 'quotes', row, refresh, utils),
      getDropdownItem('archive', 'quotes', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'quotes', row, refresh, utils),
      getDropdownItem('files', 'quotes', row, refresh, utils),
    ],
    [
      getDropdownItem('password', 'quotes', row, refresh, utils),
      {
        label: 'Ver PDF',
        icon: 'i-mdi-file-pdf',
        click: () => slideoverOpenFilePreview(row.id, row.fileQuotePdf, `Cotización: ${row.name}`),
      },
    ],
    // Status Actions
    getDropdownItemGroup('status', 'quotes', row, refresh, utils),
    [
      {
        label: 'Agregar item',
        disabled: row.$inmutable,
        icon: ICONS.quoteitems,
        click: () => {
          const quoteitems$ = useQuoteitems()
          quoteitems$.slideoverOpenCreate({
            data: { quoteId: row.id },
            disabledFields: ['quoteId'],
          }).then(refresh)
        },
      } as DropdownItem,
      {
        label: 'Registrar orden de venta',
        icon: ICONS.salesorders,
        click: () => {
          const salesorders$ = useSalesorders()
          salesorders$.slideoverOpenCreate({
            data: { accountId: row.accountId,
              dealId: row.dealId,
              quoteId: row.id,
            },
          }).then(refresh)
        },
      } as DropdownItem,
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'quotes', row, refresh, utils),
      getDropdownItem('inmutable', 'quotes', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'quotes', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  // files:

  const { uploadFile, removeFile } = useApiBlob()

  const createPdf = async (data: QuoteWithRelations, utils: QuotesUtils) => {
    const documentDefinition = await createPdfmakeDocumentDefinitionQuotes(data, utils)
    const pdf = await usePDFMake().createPdf(documentDefinition)
    return pdf
  }

  // updates the quote.files array (to be used after POST/PATCH)
  async function createFileQuotePdf(data: Partial<Quote>, utils: QuotesUtils) {
    const [existingData] = await $fetch<QuoteWithRelations[]>(`/api/db/quotes`, {
      query: { id: data.id, $with: relationsQuotes },
    })
    const newData = { ...existingData, ...data } as QuoteWithRelations
    const pdf = await createPdf(newData, utils)
    // IMPORTANT: wrap with a Promise to await the blob
    const blob: Blob = await new Promise(resolve => pdf.getBlob(blob => resolve(blob)))
    const file = new File([blob], 'quote.pdf', { type: 'application/pdf' })
    return uploadFile(file, { prefix: `quotes/${data.id}` })
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
    createPdf,
    createFileQuotePdf,
  }
}

export const useQuotesUtils = ({ $accounts, $branches, $contacts, $deals, $financialaccounts, $users }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $deals?: Awaited<ReturnType<typeof useFetch<Deal[]>>>
  $financialaccounts?: Awaited<ReturnType<typeof useFetch<Financialaccount[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsQuotes,
    delivery: optionsDeliveries,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    dealId: computed(() => $deals?.data.value.map(toOption)),
    financialaccountId: computed(() => $financialaccounts?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsQuotes.type, 'value'),
    stage: toMapByKey(optionsQuotes.stage, 'value'),
    status: toMapByKey(optionsQuotes.status, 'value'),
    satCfdiUse: toMapByKey(optionsSAT.taxCfdiUse, 'value'),
    billing: {
      cfdiType: toMapByKey(optionsSAT.cfdiType, 'value'),
      satFiscalRegime: toMapByKey(optionsSAT.fiscalRegime, 'value'),
      satTaxObject: toMapByKey(optionsSAT.taxObject, 'value'),
    },
    payment: {
      method: toMapByKey(optionsSAT.paymentMethod, 'value'),
      form: toMapByKey(optionsSAT.paymentForm, 'value'),
      condition: toMapByKey(optionsAccounts.billing.paymentConditions, 'value'),
      currency: toMapByKey(optionsSAT.currency, 'value'),
    },
    delivery: {
      incoterm: toMapByKey(optionsDeliveries.incoterm, 'value'),
      schedule: toMapByKey(optionsDeliveries.schedule, 'value'),
    },
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches?.data.value, 'id')),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    dealId: computed(() => toMapByKey($deals?.data.value, 'id')),
    financialaccountId: computed(() => toMapByKey($financialaccounts?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches?.data.value, 'accountId')),
    contactsByAccountId: computed(() => toMapByGroupKey($contacts?.data.value, 'accountId')),
  }

  return { options, maps }
}
