import { AppTransactionsSlideover, AppTransactionsSlideoverReconcile } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppTransactionsSlideoverProps = ComponentProps<typeof AppTransactionsSlideover>

type AppTransactionsSlideoverReconcileProps = ComponentProps<typeof AppTransactionsSlideoverReconcile>

export type TransactionsUtils = ReturnType<typeof useTransactionsUtils>

export const useTransactions = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Transaction>('transactions')

  const slideoverOpenCreate = (props: AppTransactionsSlideoverProps) => {
    return new Promise<Transaction>((resolve, reject) => {
      const data = getDefaultTransaction(props.data)
      slideover.open(AppTransactionsSlideover, {
        title: 'Crear transacción',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppTransactionsSlideoverProps) => {
    return new Promise<Transaction>((resolve, reject) => {
      const data = getDefaultTransaction(props.data)
      slideover.open(AppTransactionsSlideover, {
        title: 'Editar transacción',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenReconcile = (props: AppTransactionsSlideoverReconcileProps) => {
    return new Promise<Transaction>((resolve, reject) => {
      const data = getDefaultTransaction(props.data)
      slideover.open(AppTransactionsSlideoverReconcile, {
        title: 'Conciliar transacción',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    reference: z.string(),
    accountingDate: z.string(),
    amount: z.number(),
    netAmount: z.number(),
    financialaccountId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Transaction, refresh: () => Promise<void>, utils: TransactionsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'transactions', row, refresh, utils),
      getDropdownItem('copyId', 'transactions', row, refresh, utils),
      getDropdownItem('copyUid', 'transactions', row, refresh, utils),
      getDropdownItem('archive', 'transactions', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'transactions', row, refresh, utils),
      getDropdownItem('files', 'transactions', row, refresh, utils),
    ],
    // resource actions:
    [
      // resource actions:
      [
        ...dropdownItemsAction,
      ],
    ],
    [
      getDropdownItem('info', 'transactions', row, refresh, utils),
      getDropdownItem('inmutable', 'transactions', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'transactions', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  const getDropdownItemsReconcile = (row: Transaction, refresh: () => Promise<void>, utils: TransactionsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Reconciliar',
        icon: 'i-mdi-pencil',
        disabled: row.$inmutable,
        click: () => {
          slideoverOpenReconcile({
            data: row,
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      getDropdownItem('copyId', 'transactions', row, refresh, utils),
      getDropdownItem('archive', 'transactions', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'transactions', row, refresh, utils),
      getDropdownItem('files', 'transactions', row, refresh, utils),
    ],
    // resource actions:
    [
      // resource actions:
      [
        ...dropdownItemsAction,
      ],
    ],
    [
      getDropdownItem('info', 'transactions', row, refresh, utils),
      getDropdownItem('inmutable', 'transactions', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'transactions', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    slideoverOpenCreate,
    slideoverOpenEdit,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenReconcile,
    modal,
    modalOpenDelete,
    schema,
    getDropdownItems,
    getDropdownItemsReconcile,
  }
}

export const useTransactionsUtils = ({ $financialaccounts, $accounts, $branches, $productionorders, $cfdi, $invoices }: {
  $financialaccounts?: Awaited<ReturnType<typeof useFetch<Financialaccount[]>>>
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $productionorders?: Awaited<ReturnType<typeof useFetch<Productionorder[]>>>
  $cfdi?: Awaited<ReturnType<typeof useFetch<Cfdi[]>>>
  $invoices?: Awaited<ReturnType<typeof useFetch<Invoice[]>>>
} = {}) => {
  const options = {
    ...optionsTransactions,
    financialaccountId: computed(() => $financialaccounts.data.value.map(toOption)),
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    productionorderId: computed(() => $productionorders?.data.value.map(toOption)),
    cfdiId: computed(() => $cfdi?.data.value.map(toOption)),
    invoiceId: computed(() => $invoices?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsTransactions.type, 'value'),
    category: toMapByKey(optionsTransactions.category, 'value'),
    subcategory: toMapByKeyFromObject(optionsTransactions.subcategory, 'value'),
    currency: toMapByKey(optionsSAT.currency, 'value'),
    method: toMapByKey(optionsTransactions.method, 'value'),
    flagType: toMapByKey(optionsTransactions.flagType, 'value'),
    flagStatus: toMapByKeyFromObject(optionsTransactions.flagStatus, 'value'),
    financialaccountId: computed(() => toMapByKey($financialaccounts?.data.value, 'id')),
  }

  return { options, maps }
}
