import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultIndustry = (data: Partial<Industry>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    tags: [],
    files: [],
    data: getDefaultIndustryData(data?.data ?? {}),
  }, data)
}

export const optionsIndustries = {} satisfies Options

export const optionsIndustriesMap = {}

export const relationsIndustries: Record<string, boolean> = {
  accounts: true,
}

export type IndustryWithRelations = Industry & {
  accounts: Account[]
}

export const viewSettingsIndustries: ViewSettings<Industry> = {
  record: 'industries',
  views: optionsShared.views.filter(view => ['table', 'gallery'].includes(view.value)),
  selectedView: 'gallery',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
