import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultProductionorderitem = (data: Partial<Productionorderitem>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'finished-goods',
    stage: 'new',
    status: 'pending',
    priority: '2',
    images: [],
    tasks: [],
    tags: [],
    files: [],
    data: getDefaultProductionorderitemData(data?.data ?? {}),
  }, data)
}

export const optionsProductionorderitems = {
  type: optionsProducts.type,
  stage: optionsProductionorders.stage,
  status: optionsSharedApp.statusProcess,
  priority: optionsSharedApp.priority,
} satisfies Options

export const optionsProductionorderitemsMap = {
  Tipo: optionsProductionorderitems.type,
  Etapa: optionsProductionorderitems.stage,
  Estado: optionsProductionorderitems.status,
  Prioridad: optionsProductionorderitems.priority,
}

export const relationsProductionorderitems: Record<string, boolean> = {
  productitem: true,
  salesorderitem: true,
  user: true,
  productionorder: true,
}

export type ProductionorderitemWithRelations = Productionorderitem & {
  productitem: Productitem
  salesorderitem: Salesorderitem
  user: User
  productionorder: Productionorder
}

export const viewSettingsProductionorderitems: ViewSettings<Productionorderitem> = {
  record: 'productionorderitems',
  views: optionsShared.views.filter(view => ['table', 'kanban'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
    },
    {
      key: 'productitemId',
      label: 'Item',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'stage',
      label: 'Etapa',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'userId',
      label: 'Responsable',
      sortable: true,
    },
    {
      key: 'productionorderId',
      label: 'Orden de Producción',
      sortable: true,
    },
    {
      key: 'quantityPlanned',
      label: 'Cantidad (plan)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'quantityActual',
      label: 'Cantidad (real)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'images',
      label: 'Imágenes',
      class: 'max-w-[300px] overflow-x-auto',
    },
    {
      key: 'tasks',
      label: 'Tareas',
      class: 'min-w-[200px]',
    },
    {
      key: 'text',
      label: 'Especificaciones',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groups: [
    { label: 'Tipo', value: 'type', options: optionsProductionorderitems.type },
    { label: 'Etapa', value: 'stage', options: optionsProductionorderitems.stage },
    { label: 'Estado', value: 'status', options: optionsProductionorderitems.status },
    { label: 'Prioridad', value: 'priority', options: optionsProductionorderitems.priority },
  ],
  group: 'stage',
}
