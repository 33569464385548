import { AppAccountsSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppAccountsSlideoverProps = ComponentProps<typeof AppAccountsSlideover>

export type AccountsUtils = ReturnType<typeof useAccountsUtils>

export const useAccounts = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Account>('accounts')

  const slideoverOpenCreate = (props: AppAccountsSlideoverProps) => {
    return new Promise<Account>((resolve, reject) => {
      const data = getDefaultAccount(props.data)
      slideover.open(AppAccountsSlideover, {
        title: 'Crear cuenta',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppAccountsSlideoverProps) => {
    return new Promise<Account>((resolve, reject) => {
      const data = getDefaultAccount(props.data)
      slideover.open(AppAccountsSlideover, {
        title: 'Editar cuenta',
        disabledFields: ['type'],
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    status: z.string(),
    name: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Account, refresh: () => Promise<void>, utils: AccountsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'accounts', row, refresh, utils),
      getDropdownItem('copyId', 'accounts', row, refresh, utils),
      getDropdownItem('archive', 'accounts', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'accounts', row, refresh, utils),
      getDropdownItem('files', 'accounts', row, refresh, utils),
    ],
    getDropdownItemGroup('status', 'accounts', row, refresh, utils),
    [
      {
        label: 'Agregar contacto',
        icon: ICONS.contacts,
        click: () => {
          const contacts$ = useContacts()
          contacts$.slideoverOpenCreate({
            data: { type: row.type, accountId: row.id, userId: row.userId },
            disabledFields: ['type', 'accountId'],
          }).then(refresh)
        },
      },
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'accounts', row, refresh, utils),
      getDropdownItem('inmutable', 'accounts', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'accounts', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useAccountsUtils = ({ $contacts, $users, $industries }: {
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
  $industries?: Awaited<ReturnType<typeof useFetch<Industry[]>>>
} = {}) => {
  const options = {
    ...optionsAccounts,
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
    industryId: computed(() => $industries?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsAccounts.type, 'value'),
    status: toMapByKey(optionsAccounts.status, 'value'),
    satCfdiUse: toMapByKey(optionsSAT.taxCfdiUse, 'value'),
    billing: {
      type: toMapByKey(optionsAccounts.billing.type, 'value'),
      country: toMapByKey(optionsShared.countryCode, 'value'),
      currency: toMapByKey(optionsSAT.currency, 'value'),
      form: toMapByKey(optionsSAT.paymentForm, 'value'),
    },
    payment: {
      condition: toMapByKey(optionsAccounts.billing.paymentConditions, 'value'),
      method: toMapByKey(optionsSAT.paymentMethod, 'value'),
      currency: toMapByKey(optionsSAT.currency, 'value'),
      forms: toMapByKey(optionsSAT.paymentForm, 'value'),
    },
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    industryId: computed(() => toMapByKey($industries?.data.value, 'id')),
  }

  const getters = {
    individual: (data: Partial<Account['billing']>) => getDefaultBilling(data),
    legalEntity: (data: Partial<Account['billing']>) => getDefaultBilling(data),
    foreignLegalEntity: (data: Partial<Account['billing']>) => getDefaultBillingForeignLegalEntity(data),
    genericRFC: (data: Partial<Account['billing']>) => getDefaultBillingGenericRFC(data),
  }

  return { options, maps, getters }
}
