import type { Options } from '#netzo/utils/core/index'
import { format } from 'date-fns'
import { merge } from 'lodash-es'

export const deliverySchema = z.object({
  incoterm: z.string(),
  schedule: z.string(),
  isDatetime: z.boolean().optional(),
  date: z.string().nullable().optional(),
  useAccountAddress: z.boolean().optional(),
  address: z.object({
    street: z.string().nullable(),
    numberExterior: z.string().nullable(),
    numberInterior: z.string().nullable().optional(),
    neighborhood: z.string().nullable().optional(),
    city: z.string().nullable(),
    district: z.string().nullable().optional(),
    state: z.string().nullable(),
    country: z.string().nullable(),
    postalCode: z.string().nullable(),
  }).optional(),
  text: z.string().nullable().optional(),
})

export const getDefaultDelivery = (data: Partial<Delivery>) => merge({
  incoterm: 'DAP',
  schedule: 'pending',
  isDatetime: false,
  date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  address: {},
}, data)

// NOTE: This options MUST remain in this file, as they are specific to the `deliveries` components
export const optionsDeliveries = {
  incoterm: [
    { value: 'pending', label: 'Por definir', description: 'Aún no se han definido los término de entrega.', color: 'gray', icon: 'i-mdi-clock' },
    { value: 'EXW', label: 'En Fábrica (EXW)', description: 'Nosotros hacemos disponible la mercancía, y el Cliente asume los costos y riesgos desde la recogida.', color: 'blue', icon: 'i-mdi-factory' },
    { value: 'FCA', label: 'Libre Transportista (FCA)', description: 'Nosotros entregamos la mercancía al transportista en un lugar designado, luego el riesgo es del Cliente.', color: 'green', icon: 'i-mdi-truck' },
    { value: 'CPT', label: 'Transporte Pagado Hasta (CPT)', description: 'Nosotros pagamos el transporte hasta un destino acordado, el riesgo es del Cliente después de la entrega.', color: 'purple', icon: 'i-mdi-shipping' },
    { value: 'CIP', label: 'Transporte y Seguro Pagado Hasta (CIP)', description: 'Nosotros pagamos transporte y seguro hasta el destino acordado, el riesgo pasa al Cliente después de la entrega.', color: 'indigo', icon: 'i-mdi-security' },
    { value: 'DAP', label: 'Entregado en Lugar (DAP)', description: 'Nosotros entregamos la mercancía en un lugar acordado, el Cliente asume los riesgos tras la entrega.', color: 'yellow', icon: 'i-mdi-map-marker' },
    { value: 'DPU', label: 'Entregado en Lugar y Descargado (DPU)', description: 'Nosotros entregamos y descargamos en el lugar acordado, el Cliente asume los riesgos después de la descarga.', color: 'orange', icon: 'i-mdi-download' },
    { value: 'DDP', label: 'Entregado con Derechos Pagados (DDP)', description: 'Nosotros nos encargamos de todo, incluido el despacho aduanal, hasta las instalaciones del Cliente.', color: 'red', icon: 'i-mdi-truck-delivery' },
    { value: 'FAS', label: 'Libre al Costado del Buque (FAS)', description: 'Nosotros entregamos al costado del buque, el Cliente asume riesgos y costos desde allí.', color: 'teal', icon: 'i-mdi-ship' },
    { value: 'FOB', label: 'Libre a Bordo (FOB)', description: 'Nosotros cargamos la mercancía al buque, el Cliente asume los riesgos una vez a bordo.', color: 'cyan', icon: 'i-mdi-ship-wheel' },
    { value: 'CFR', label: 'Costo y Flete (CFR)', description: 'Nosotros pagamos el flete hasta el puerto de destino, el Cliente asume los riesgos al embarcar.', color: 'pink', icon: 'i-mdi-boat' },
    { value: 'CIF', label: 'Costo, Seguro y Flete (CIF)', description: 'Nosotros pagamos el flete y seguro, pero el Cliente asume los riesgos desde el embarque.', color: 'lime', icon: 'i-mdi-lock' },
  ],
  schedule: [
    { value: 'pending', label: 'Por definir', description: 'Aún no se ha definido el tiempo de entrega.', color: 'gray', icon: 'i-mdi-clock' },
    { value: 'immediate', label: 'Inmediata', description: 'Entrega inmediata, el Cliente recibe la mercancía al instante.', color: 'green', icon: 'i-mdi-truck-fast' },
    { value: 'scheduled', label: 'Programada', description: 'Entrega programada para una fecha específica acordada con el Cliente.', color: 'blue', icon: 'i-mdi-calendar-clock' },
    { value: '1-3', label: '1-3 días hábiles', description: 'Entrega dentro de 1 a 3 días hábiles.', color: 'sky', icon: 'i-mdi-calendar' },
    { value: '3-7', label: '3-7 días hábiles', description: 'Entrega dentro de 3 a 7 días hábiles.', color: 'amber', icon: 'i-mdi-calendar-week' },
    { value: '7-14', label: '7-14 días hábiles', description: 'Entrega dentro de 7 a 14 días hábiles.', color: 'yellow', icon: 'i-mdi-calendar-month' },
    { value: '15+', label: '+15 días hábiles', description: 'Entrega en un plazo mayor a 15 días hábiles.', color: 'red', icon: 'i-mdi-calendar-alert' },
  ],
} satisfies Options

export const optionsDeliveriesMap = {
  Incoterm: optionsDeliveries.incoterm,
  Programación: optionsDeliveries.schedule,
}
