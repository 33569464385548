import type { HookContext, NextFunction } from '@feathersjs/hooks'
import type { BlobObject } from '@nuxthub/core'
import type { TData } from '../../useApiDB'

export const handleErrors = (successMessage: string, errorMessage: string) => {
  return async (context: HookContext, next: NextFunction) => {
    const [_, toast = true] = context.arguments as [unknown, boolean]
    try {
      await next()
      if (toast) useToastAlert().success(successMessage)
    }
    catch (error) {
      if (toast) useToastAlert().error(errorMessage)
      console.error(error)
    }
  }
}

const tablesWithItems = [
  'productionorders',
  'quotes',
  'salesorders',
  'serviceorders',
  'pricelists',
  'products',
  'services',
] as const

export const handleCascadeUpdates = (record: string) => {
  return async (context: HookContext, next: NextFunction) => {
    const [row] = context.arguments as [TData]

    await next()

    if (tablesWithItems.includes(record)) {
      const prefix = record.replace(/s$/, '')
      const recordItems = `${prefix}items`
      const idField = `${prefix}Id`
      const items = await $fetch(`/api/db/${recordItems}?${idField}=${row.id}`)
      const result = await Promise.all(items.map(async (item) => {
        return $fetch(`/api/db/${recordItems}/${item.id}`, {
          method: 'PATCH',
          body: { id: item.id, $inmutable: row.$inmutable },
        })
      }))
      // console.debug(`Cascade updates for ${result.length} rows of table '${recordItems}'.`)
    }
  }
}

export const handleCascadeUpdatesMultiple = (record: string) => {
  return async (context: HookContext, next: NextFunction) => {
    const [rows] = context.arguments as [TData[]]

    await next()

    if (tablesWithItems.includes(record)) {
      const prefix = record.replace(/s$/, '')
      const recordItems = `${prefix}items`
      const idField = `${prefix}Id`
      const items = await $fetch(`/api/db/${recordItems}`, {
        query: { [idField]: rows.map(row => row.id) },
      })
      const result = await Promise.all(items.map(async (item) => {
        const { $inmutable } = rows.find(row => row.id === item[idField]) ?? {}
        return $fetch(`/api/db/${recordItems}/${item.id}`, {
          method: 'PATCH',
          body: { id: item.id, $inmutable },
        })
      }))
      // console.debug(`Cascade updates for ${result.length} rows of table '${recordItems}'.`)
    }
  }
}

export const handleBlobCreate = (record: string) => {
  return async (context: HookContext, next: NextFunction) => {
    const [row] = context.arguments as [TData]

    if (['pricelists'].includes(record)) {
      const { createFilePricelistPdf } = usePricelists()
      row.filePricelistPdf = await createFilePricelistPdf(row, usePricelistsUtils())
    }
    if (['productionorders'].includes(record)) {
      const { createFileProductionorderPdf } = useProductionorders()
      row.fileProductionorderPdf = await createFileProductionorderPdf(row, useProductionordersUtils())
    }
    if (['quotes'].includes(record)) {
      const { createFileQuotePdf } = useQuotes()
      row.fileQuotePdf = await createFileQuotePdf(row, useQuotesUtils())
    }
    if (['salesorders'].includes(record)) {
      const { createFileSalesorderPdf } = useSalesorders()
      row.fileSalesorderPdf = await createFileSalesorderPdf(row, useSalesordersUtils())
    }
    if (['serviceorders'].includes(record)) {
      const { createFileServiceorderPdf } = useServiceorders()
      row.fileServiceorderPdf = await createFileServiceorderPdf(row, useServiceordersUtils())
    }

    await next()
  }
}

export const handleBlobUpdate = (record: string) => {
  return async (context: HookContext, next: NextFunction) => {
    const [row] = context.arguments as [TData]

    if (['pricelists'].includes(record)) {
      const { createFilePricelistPdf } = usePricelists()
      row.filePricelistPdf = await createFilePricelistPdf(row, usePricelistsUtils())
    }
    if (['productionorders'].includes(record)) {
      const { createFileProductionorderPdf } = useProductionorders()
      row.fileProductionorderPdf = await createFileProductionorderPdf(row, useProductionordersUtils())
    }
    if (['quotes'].includes(record)) {
      const { createFileQuotePdf } = useQuotes()
      row.fileQuotePdf = await createFileQuotePdf(row, useQuotesUtils())
    }
    if (['salesorders'].includes(record)) {
      const { createFileSalesorderPdf } = useSalesorders()
      row.fileSalesorderPdf = await createFileSalesorderPdf(row, useSalesordersUtils())
    }
    if (['serviceorders'].includes(record)) {
      const { createFileServiceorderPdf } = useServiceorders()
      row.fileServiceorderPdf = await createFileServiceorderPdf(row, useServiceordersUtils())
    }

    await next()
  }
}

export const handleBlobDelete = (record: string) => {
  return async (context: HookContext, next: NextFunction) => {
    const { removeFile } = useApiBlob()
    const [rows] = context.arguments as [TData[]]

    if (['pricelists'].includes(record)) {
      await Promise.all(rows.map(row => removeFile(row.filePricelistPdf as BlobObject)))
    }
    if (['productionorders'].includes(record)) {
      await Promise.all(rows.map(row => removeFile(row.fileProductionorderPdf as BlobObject)))
    }
    if (['quotes'].includes(record)) {
      await Promise.all(rows.map(row => removeFile(row.fileQuotePdf as BlobObject)))
    }
    if (['salesorders'].includes(record)) {
      await Promise.all(rows.map(row => removeFile(row.fileSalesorderPdf as BlobObject)))
    }
    if (['serviceorders'].includes(record)) {
      await Promise.all(rows.map(row => removeFile(row.fileServiceorderPdf as BlobObject)))
    }

    await next()
  }
}
