<script setup lang="ts">
const model = defineModel<string[] | undefined | null>({ required: true })
const query = defineModel<string>('query', { default: '' })
const options = defineModel<string[]>('options', { required: true })

defineProps<{
  creatable?: boolean
  clearable?: boolean
}>()

async function onUpdateModelValue(value: string[]) {
  const el = document.querySelector('.select-menu-creatable-string input') as HTMLInputElement
  if (el) el.value = '' // clear input
  query.value = '' // clear query

  // applies heuristic to ensure all model values include both label and value properties
  model.value = value?.map((tag) => {
    const value = tag.value ?? tag.label ?? tag
    const label = tag?.label ?? tag?.value ?? tag
    const option = options.value.find(option => option.value === value)
    return { ...option, label, value }
  }) ?? []

  await nextTick()
}

function onClickRemoveTag(index: number) {
  model.value = model.value.filter((_, i) => i !== index)
}

// NOTE: if label format is "PREFIX | SUFFIX" badge renders only "PREFIX"
const getTagLabel = (tag: string) => tag.label?.split(' | ')?.[0]
</script>

<template>
  <USelectMenu
    v-model:query="query"
    :model-value="model"
    :options="options"
    searchable
    :searchable-placeholder="creatable ? 'Buscar o crear' : 'Buscar'"
    creatable
    multiple
    v-bind="$attrs"
    class="select-menu-creatable-string"
    @update:model-value="onUpdateModelValue"
  >
    <template #label>
      <template v-if="model?.length">
        <div class="w-full flex gap-1">
          <UBadge
            v-for="(tag, i) in Array.isArray(model) ? model : [model]"
            :key="`tag-${tag}-${i}`"
            color="gray"
            size="sm"
          >
            {{ getTagLabel(tag) }}
            <UButton
              v-if="clearable"
              variant="ghost"
              size="xs"
              color="gray"
              class="ml-1 p-0"
              @click.prevent.stop="onClickRemoveTag(i)"
            >
              <UIcon name="i-mdi-close" />
            </UButton>
          </UBadge>
        </div>
      </template>
      <template v-else>
        <span class="text-gray-500 dark:text-gray-400 truncate">
          {{ creatable ? 'Selecciona o crea' : 'Selecciona' }}
        </span>
      </template>
    </template>

    <template #option="{ option }">
      <span class="truncate">{{ option.label }}</span>
    </template>

    <template v-if="creatable" #option-create>
      <UKbd>Enter</UKbd> para crear nuevo
    </template>
  </USelectMenu>
</template>
