<script setup lang="ts">
import type { ZodSchema } from 'zod'

const model = defineModel<{ serviceitemId?: string, [key: string]: string | number | boolean }>({
  required: true,
})

const props = defineProps<{
  schema: ZodSchema
  disabled?: boolean
  filteredIds?: string[]
  slideoverOpenEdit: () => void
}>()

const $services = await useFetch<Serviceitem[]>('/api/db/services', {
  query: { $columns: ['id', 'name', 'type'] },
  default: () => [],
})
const $serviceitems = await useFetch<Serviceitem[]>('/api/db/serviceitems', {
  query: { $columns: ['id', 'name', 'type', 'serviceId'] },
  default: () => [],
})

const services$ = useServices()
const serviceitems$ = useServiceitems()

const serviceId = ref<number | undefined>(undefined)
const serviceType = ref<string | undefined>('finished-goods')

const options = { type: optionsServices.type }

const maps = {
  serviceId: computed(() => toMapByKey($services?.data.value, 'id')),
  serviceitemId: computed(() => toMapByKey($serviceitems?.data.value, 'id')),
  servicesByType: computed(() => toMapByGroupKey($services?.data.value, 'type')),
  serviceitemsByServiceId: computed(() => toMapByGroupKey($serviceitems?.data.value, 'serviceId')),
}

const regenerateKey = ref(useId())

watch(model.value.serviceitemId, () => {
  if (!model.value.serviceitemId) return
  const serviceitem = maps.serviceitemId.value.get(model.value.serviceitemId) as Serviceitem
  const selectedService = maps.serviceId.value.get(serviceitem?.serviceId) as Service
  serviceType.value = selectedService.type
  serviceId.value = selectedService.id
  regenerateKey.value = useId()
},
{ immediate: true })

const FIELDSET = `w-full grid grid-cols-1 gap-2 !mt-4 !mb-2 p-4 border rounded-lg`
</script>

<template>
  <fieldset :class="FIELDSET" :disabled="disabled">
    <UFormGroup
      label="Tipo de servicio"
      :required="isRequired(schema, 'serviceitemId')"
    >
      <SelectMenuBaseInfo
        v-model="serviceType"
        :options="options.type ?? []"
        :disabled="disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Servicio"
      :required="isRequired(schema, 'serviceitemId')"
    >
      <UButtonGroup class="flex">
        <SelectMenuBase
          :key="`service-id-${regenerateKey}`"
          v-model="serviceId"
          :options="maps.servicesByType?.value.get(serviceType)?.map(toOption) ?? []"
          class="flex-1"
        />
        <UButton
          icon="i-mdi-plus"
          @click="openNested(() => services$.slideoverOpenCreate({
            data: { type: serviceType },
            disabledFields: ['type'],
            onSubmit: async (event) => {
              services$.onSubmitCreate(event.data)
              await $services.refresh()
              await openNested(() => props.slideoverOpenEdit({ ...props, data: model }))
            },
          }))"
        />
      </UButtonGroup>
    </UFormGroup>

    <UFormGroup
      label="Item"
      :required="isRequired(schema, 'item')"
    >
      <UButtonGroup class="flex">
        <SelectMenuBase
          v-model="model.serviceitemId"
          :options="maps.serviceitemsByServiceId?.value.get(serviceId)?.map(toOption).filter((item) => !filteredIds?.includes(item.value)) ?? []"
          :disabled="!serviceId"
          class="flex-1"
        />
        <UButton
          icon="i-mdi-plus"
          :disabled="!serviceId"
          @click="openNested(() => serviceitems$.slideoverOpenCreate({
            data: { type: serviceType, serviceId: serviceId },
            disabledFields: ['type', 'serviceId'],
            onSubmit: async (event) => {
              const serviceitem = await serviceitems$.onSubmitCreate(event.data)
              model.serviceitemId = serviceitem!.id
              await $serviceitems.refresh()
              await openNested(() => props.slideoverOpenEdit({ ...props, data: model }))
            },
          }))"
        />
      </UButtonGroup>
    </UFormGroup>
  </fieldset>
</template>
