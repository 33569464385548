<script setup lang="ts">
const model = defineModel<number | undefined>()

const color = computed(() => {
  if (!model.value) return 'gray'
  if (model.value <= 3) return 'red'
  if (model.value <= 6) return 'amber'
  if (model.value <= 7) return 'orange'
  return 'green'
})
</script>

<template>
  <div>
    <URange
      v-model="model"
      :min="1"
      :max="10"
      :step="1"
      :color="color"
    />
    <div class="flex justify-between mt-2 text-gray-500 dark:text-gray-400 text-sm">
      <span>1</span>
      <span>10</span>
    </div>
  </div>
</template>
