import { createId, createUid, type Options } from '#netzo/utils/core/index'
import { toCurrency } from '#netzo/utils/i18n'
import { format, parseISO } from 'date-fns'
import { merge } from 'lodash-es'
import type { Content, ContentColumns, TDocumentDefinitions, TDocumentInformation } from 'pdfmake/interfaces'

export const getDefaultSalesorder = (data: Partial<Salesorder>) => {
  const uid = createUid(new Date(), 'OV') // IMPORTANT: cloudflare throws error if called on global scope
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'quote',
    stage: 'received',
    status: 'pending',
    paymentStatus: 'pending',
    uid: uid,
    name: uid,
    date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    receiver: {
      taxCountryCode: 'MX',
      address: {},
    },
    issuer: {
      taxId: defaultLegalInformation.taxId,
      taxCountryCode: defaultLegalInformation.taxCountryCode,
      legalName: defaultLegalInformation.legalName,
      satFiscalRegime: defaultLegalInformation.satFiscalRegime,
      phone: defaultLegalInformation.phone,
      email: defaultLegalInformation.email,
      issuedIn: defaultLegalInformation.address.postalCode + ' ' + defaultLegalInformation.address.state,
      cfdiType: 'I',
      address: defaultLegalInformation.address,
    },
    delivery: getDefaultDelivery({}),
    payment: merge(defaultPayment, data?.payment),
    notes: [],
    fileSalesorderPdf: [],
    pdfSettings: defaultPdfSettings,
    tags: [],
    files: [],
    data: getDefaultSalesorderData(data?.data ?? {}),
  }, data)
}

export const getSalesorderInformation = (data: Partial<SalesorderWithRelations>) => {
  if (!data) return []

  const maps = {
    type: toMapByKey(optionsSalesorders.type, 'value'),
    status: toMapByKey(optionsSalesorders.status, 'value'),
    stage: toMapByKey(optionsSalesorders.stage, 'value'),
    schedule: toMapByKey(optionsDeliveries.schedule, 'value'),
    incoterm: toMapByKey(optionsDeliveries.incoterm, 'value'),
  }
  const deliverySchedule = data.delivery?.schedule
  const deliveryDate = data.delivery?.date
  const isDatetime = data.delivery?.isDatetime

  const delivery = deliverySchedule !== 'scheduled'
    ? maps.schedule.get(deliverySchedule)?.label
    : deliveryDate
      ? !isDatetime
          ? parseISO(deliveryDate).toLocaleDateString()
          : parseISO(deliveryDate).toLocaleString()
      : null

  const localDate = data.date ? parseISO(data.date) : null
  const date = localDate
    ? data.isDatetime
      ? localDate.toLocaleString()
      : localDate.toLocaleDateString()
    : null
  return [
    ['Referencia', data.uid],
    ['Nombre', data.name],
    ['Vendedor', data.user?.name],
    ['Cliente', data.account?.name],
    ['Fecha de recepción', date],
    ['Terminos de entrega', maps.incoterm.get(data.delivery.incoterm)?.label],
    ['Entrega', delivery],
    data.branchId && ['Sucursal', data.branch?.name],
  ].filter(([_, value]) => value != null)
}

export const optionsSalesorders = {
  type: [
    { value: 'quote', label: 'Cotización', description: 'Orden de venta proviene de una cotización firmada por el cliente que indica su acuerdo con los términos propuestos.', icon: 'i-mdi-file-document-outline', color: 'blue' },
    { value: 'purchaseorder', label: 'Orden de compra', description: 'Orden de venta proviene de una orden de compra oficial emitida por el cliente solicitando productos o servicios.', icon: 'i-mdi-cart', color: 'green' },
    { value: 'payslip', label: 'Recibo de pago', description: 'Orden de venta proviene de un recibo que indica un pago adelantado realizado por el cliente.', icon: 'i-mdi-cash' },
  ],
  stage: [
    { value: 'received', label: 'Recepción', description: 'El cliente nos envió una orden de venta antes de ser procesada se debe revisar y aprobar.', icon: 'i-mdi-send', color: 'gray' },
    { value: 'approval', label: 'Aprobación', description: 'La orden de venta necesita ser aprobada antes de ser procesada.', icon: 'i-mdi-check-circle-outline', color: 'amber' },
    { value: 'processing', label: 'Procesamiento', description: 'La orden de venta ha sido aprobada y se deben generar las órdenes de trabajo internas para su ejecución.', icon: 'i-mdi-cog', color: 'blue' },
    { value: 'billing', label: 'Cobranza', description: 'La orden de venta ha sido procesada y se espera el pago del cliente.', icon: 'i-mdi-cash', color: 'green' },
    { value: 'completed', label: 'Completada', description: 'La orden de venta se ha completado con éxito y no requiere más acciones.', icon: 'i-mdi-check-circle-outline', color: 'green' },
    { value: 'canceled', label: 'Cancelada', description: 'La orden de ventas se canceló por cualquier motivo.', icon: 'i-mdi-close-circle-outline', color: 'red' },
  ],
  status: optionsSharedApp.statusProcess,
  paymentStatus: [
    { value: 'pending', label: 'Pendiente de cobrar', description: 'El pago de la orden de venta aún no ha sido realizado.', icon: 'i-mdi-clock-outline', color: 'red' },
    { value: 'partial', label: 'Parcialmente cobrada', description: 'El pago de la orden de venta ha sido parcialmente realizado.', icon: 'i-mdi-cash-multiple', color: 'amber' },
    { value: 'paid', label: 'Cobrada', description: 'El pago de la orden de venta ha sido realizado con éxito.', icon: 'i-mdi-check-circle-outline', color: 'green' },
  ],
  delivery: {
    incoterm: optionsDeliveries.incoterm,
  },
} satisfies Options

export const optionsSalesordersMap = {
  'Tipo': optionsSalesorders.type,
  'Etapa': optionsSalesorders.stage,
  'Estado': optionsSalesorders.status,
  'Estado de Pago': optionsSalesorders.paymentStatus,
  'Entrega': optionsDeliveriesMap,
}

export const relationsSalesorders: Record<string, boolean> = {
  account: true,
  branch: true,
  contact: true,
  deal: true,
  quote: true,
  salesorderitems: true,
  user: true,
  productionorders: true,
}

export type SalesorderWithRelations = Salesorder & {
  account?: Account
  branch?: Branch
  contact?: Contact
  deal?: Deal
  quote?: Quote
  salesorderitems?: Salesorderitem[]
  user?: User
  productionorders?: Productionorder[]
}

export const salesorderToViewCalendarEvent = (calendarId: keyof Salesorder, utils: SalesordersUtils) => {
  return (row: Salesorder): CalendarEvent => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.name}`,
      description: row.text!,
      start: format(new Date(row.date), dateFormat),
      end: format(new Date(row.date), dateFormat),
      calendarId: row[calendarId],
    }
  }
}

export const createPdfmakeDocumentDefinitionSalesorders = async (
  data: SalesorderWithRelations,
  utils: SalesordersUtils,
): Promise<TDocumentDefinitions> => {
  data.delivery ??= {}
  data.payment ??= {}
  data.data ??= {}
  data.salesorderitems ??= []

  const maps = {
    fiscalData: {
      satUnitKey: toMapByKey(optionsSAT.productUnitId, 'value'),
    },
  }

  const exchangeRateValue = data.payment.exchangeRate?.value ?? 1
  const exchangeRateDate = (data.payment.exchangeRate?.date ?? new Date().toISOString())?.split('T')[0]

  const amount = data.salesorderitems.reduce((acc: number, salesorderitem: Salesorderitem) => acc + salesorderitem.amount, 0) * exchangeRateValue
  const vat = data.salesorderitems.reduce((acc: number, salesorderitem: Salesorderitem) => {
    const { transfers = [] } = salesorderitem.taxes ?? {}
    return acc + transfers.reduce((acc, tax) => acc + tax.amount, 0)
  }, 0) * exchangeRateValue
  const total = amount + vat

  // sections:

  const info: TDocumentInformation = {
    title: `${data.uid}`,
    subject: 'Orden de Venta La Tinta',
    keywords: 'diseño, agencia, publicidad',
    author: 'LA TINTA',
    producer: 'Netzo (https://netzo.dev)',
  }

  const sectionHeader: ContentColumns = {
    columns: [
      { image: SYMBOL_BASE64, fit: [80, 200], margin: [5, 5] },
      {
        stack: [
          { text: 'Orden de Venta', fontSize: 18, alignment: 'right', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Referencia: ', bold: true }, `${data.uid}`], alignment: 'right' },
          { text: [{ text: 'Fecha de documento: ', bold: true }, new Date().toLocaleDateString()], alignment: 'right' },
          { text: [{ text: 'Fecha de recepción: ', bold: true }, new Date(data.date).toLocaleDateString()], alignment: 'right' },
          { text: [{ text: 'Vendedor: ', bold: true }, { text: `${data.user?.name}` }], alignment: 'right' },
          { text: [{ text: 'Tipo: ', bold: true }, utils.maps.type.get(data.type)?.label!], alignment: 'right' },
          { text: [{ text: 'Etapa: ', bold: true }, utils.maps.stage.get(data.stage)?.label!], alignment: 'right' },
          { text: [{ text: 'Estado: ', bold: true }, utils.maps.status.get(data.status)?.label!], alignment: 'right' },
          { text: [{ text: 'Estado de pago: ', bold: true }, utils.maps.paymentStatus.get(data.paymentStatus)?.label!], alignment: 'right' },
        ],
        margin: [0, 10],
      },
    ],
    margin: [0, 0, 0, 5],
  }

  const showCurrency = data.payment.currency !== defaultAppVariables.currency

  const sectionClientInfo: ContentColumns = {
    columns: [
      {
        stack: [
          { text: 'Emisor', style: 'header', margin: [0, 0, 0, 5] },
          { text: data.issuer.legalName || '', margin: [0, 0, 0, 2] },
          { text: data.issuer.taxId || '', margin: [0, 0, 0, 2] },
          { text: formatAddress(data.issuer.address) || '', margin: [0, 0, 0, 2] },
          { text: [{ text: 'Regimen fiscal: ', bold: true }, utils.maps.billing?.satFiscalRegime.get(data.issuer.satFiscalRegime)!.label] },
        ],
        width: showCurrency ? '25%' : '33%',
        margin: [0, 10],
      },
      {
        stack: [
          { text: 'Receptor', style: 'header', margin: [0, 0, 0, 5] },
          { text: data.receiver.legalName || 'Por definir', margin: [0, 0, 0, 2] },
          { text: data.receiver.taxId || 'Por definir', margin: [0, 0, 0, 2] },
          { text: formatAddress(data.receiver.address) || '', margin: [0, 0, 0, 2] },
          { text: [{ text: 'Regimen fiscal: ', bold: true }, utils.maps.billing?.satFiscalRegime.get(data.receiver.satFiscalRegime)?.label || 'Por definir'] },
        ],
        width: showCurrency ? '25%' : '33%',
        margin: [2, 10],
      },
      {
        stack: [
          { text: 'Entrega', style: 'header', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Incoterm: ', bold: true }, utils.maps.delivery.incoterm.get(data.delivery.incoterm)?.label || ''], margin: [0, 0, 0, 2] },
          { text: [{ text: 'Tiempo: ', bold: true }, utils.maps.delivery.schedule.get(data.delivery.schedule)?.label || ''], margin: [0, 0, 0, 2] },
          ...(['scheduled'].includes(data.delivery.schedule)
            ? [
                { text: [{ text: 'Fecha: ', bold: true }, format(new Date(data.delivery?.date), 'yyyy-MM-dd')], margin: [0, 0, 0, 2] },
              ] as Content[] // Explicitly cast the array to Content[]
            : []),
          ...(!['pending', 'EXW'].includes(data.delivery.incoterm)
            ? [
                { text: [{ text: 'Sucursal: ', bold: true }, data.branch?.name || ''], margin: [0, 0, 0, 2] },
                { text: [{ text: 'Dirección: ', bold: true }, formatAddress(data.delivery.address) || ''], margin: [0, 0, 0, 2] },
              ] as Content[] // Explicitly cast the array to Content[]
            : []),
          ...(data.contact
            ? [
                { text: [{ text: 'Contacto: ', bold: true }, { text: `${data.contact?.name ?? 'Por definir'}` }] as Content, margin: [0, 0, 0, 2] },
                { text: [{ text: 'Teléfono: ', bold: true }, { text: `${data.contact?.phone ?? 'Por definir'}` }] as Content, margin: [0, 0, 0, 2] },
              ]
            : []),
        ] as Content[], // Ensuring the stack is explicitly typed as Content[]
        width: showCurrency ? '25%' : '33%',
        margin: [2, 10],
      },
      {
        stack: showCurrency
          ? [
              { text: 'Moneda', style: 'header', margin: [0, 0, 0, 5] as [number, number, number, number] },
              { text: [{ text: 'Precios base: ', bold: true }, utils.maps.payment.currency.get(defaultAppVariables.currency)?.label || ''] },
              { text: [{ text: 'Moneda: ', bold: true }, utils.maps.payment.currency.get(data.payment.currency)?.label || ''] },
              { text: [{ text: 'Tipo de cambio (referencia): ', bold: true }, toCurrency(exchangeRateValue, data.payment.currency) || ''] },
              { text: [{ text: 'Fecha referencia: ', bold: true }, exchangeRateDate || ''] },
            ]
          : [], // Default to an empty array when not showing currency
        width: '25%',
        margin: [0, 10] as [number, number],
      },
    ],
  }

  type Tax = Salesorderitem['taxes']['transfers'][number] | Salesorderitem['taxes']['retentions'][number]

  const getSalesorderitemTaxes = (tax: Tax) => {
    if (!tax.enabled) return null
    const taxType = optionsSAT.taxTypes.find(option => option.value === tax.tax)?.label
    const satFactorType = optionsSAT.taxFactor.find(option => option.value === tax.satFactorType)?.label
    const base = toCurrency(Number(tax.base) * Number(exchangeRateValue), data.payment.currency)
    const satRateOrQuota = tax.satRateOrQuota
    const amount = toCurrency(Number(tax.amount) * Number(exchangeRateValue), data.payment.currency)
    return `${taxType}, Base: ${base}, ${satFactorType}: ${satRateOrQuota}, Importe: ${amount}`
  }

  const sectionSalesorderitemsTax: (salesorderitem: Salesorderitem) => Content[] = (salesorderitem) => {
    const { taxes = {} } = salesorderitem

    const transfers = taxes.transfers?.map(item => getSalesorderitemTaxes(item)).filter(Boolean)
    const retentions = taxes.retentions?.map(item => getSalesorderitemTaxes(item)).filter(Boolean)

    const result: Content[] = []

    // Add transfer section if available
    if (transfers?.length) {
      result.push({
        text: `Traslados:`,
        bold: true,
        style: 'tableRowDetails',
      })

      // Add each transfer tax item
      transfers.forEach((tax) => {
        result.push({
          text: tax,
          style: 'tableRowDetails',
        })
      })
    }

    // Add retentions if available
    if (retentions?.length) {
      result.push({
        text: `Retenciones:`,
        bold: true,
        style: 'tableRowDetails',
      })

      // Add each transfer tax item
      retentions.forEach((tax) => {
        result.push({
          text: tax,
          style: 'tableRowDetails',
        })
      })
    }

    return result
  }

  const sectionSalesorderitems: Content = {
    table: {
      dontBreakRows: true,
      headerRows: 1,
      widths: data.pdfSettings?.images ? ['auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'] : ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'],
      body: [
        [
          { text: '#', style: 'tableHeader' },
          { text: 'SKU', style: 'tableHeader' },
          { text: 'Cantidad', style: 'tableHeader' },
          ...data.pdfSettings?.images ? [{ text: 'Imagen', style: 'tableHeader' }] : [],
          { text: 'Item(s)', style: 'tableHeader' },
          { text: 'Unidad', style: 'tableHeader' },
          { text: 'Precio U', style: 'tableHeader' },
          { text: 'Descuento', style: 'tableHeader' },
          { text: 'Subtotal', style: 'tableHeader' },
        ],
        ...(await Promise.all(data.salesorderitems.map(async (salesorderitem: Salesorderitem, index: number) => [
          { text: index + 1, style: 'tableRow' },
          { text: salesorderitem.sku, style: 'tableRow' },
          { text: salesorderitem.quantity?.toString() ?? '', alignment: 'center', style: 'tableRow' },
          ...(data.pdfSettings?.images ? [salesorderitem.image ? { image: await getBase64ImageFromURL(salesorderitem.image), width: 40, height: 40, margin: [5, 5, 5, 5] } : { text: '' }] : []),
          {
            stack: [
              { text: salesorderitem.name, fontSize: 8, style: 'tableRow' },
              salesorderitem.description && { text: salesorderitem.description, style: 'tableRowDetails' },
              ...data.pdfSettings?.details
                ? [
                    { text: utils.maps.billing?.satTaxObject.get(salesorderitem.fiscalData.satTaxObject!)!.label ?? null, bold: true, style: 'tableRowDetails' },
                    ...sectionSalesorderitemsTax(salesorderitem),
                  ]
                : [],
            ].filter(Boolean),
          },
          { text: maps.fiscalData.satUnitKey.get(salesorderitem.fiscalData.satUnitKey!)!.label || '', style: 'tableRow' },
          { text: toCurrency(Number(salesorderitem.unitPrice) * Number(exchangeRateValue), data.payment.currency), alignment: 'right', style: 'tableRow' },
          { text: toCurrency(Number(salesorderitem.discount?.amount ?? 0) * Number(exchangeRateValue), data.payment.currency), alignment: 'right', style: 'tableRow' },
          { text: toCurrency(Number(salesorderitem.amount) * Number(exchangeRateValue), data.payment.currency), alignment: 'right', style: 'tableRow' },
        ]))),
      ].filter(Boolean),
    },
    layout: {
      hLineWidth: function (i, node) {
        return i === 0 || i === node.table.body.length ? 0 : 0.1
      },
      vLineWidth: function (i) { return 0 },
      hLineColor: function (i, node) {
        return i === 0 || i === node.table.body.length ? 'white' : 'gray'
      },
      vLineColor: function (i) { return 'white' },
    },
  }
  const toWords = toSpelledCurrency(data.payment.currency)

  const sectionTotalizedContent: Content[] = [
    {
      columns: [
        {
          margin: [0, 5],
          alignment: 'right',
          table: {
            dontBreakRows: true,
            widths: ['*', 'auto', 'auto'],
            body: [
              [{ text: '' }, { text: 'Subtotal:', alignment: 'left' }, { text: `${toCurrency(amount, data.payment.currency)}`, alignment: 'right' }],
              [{ text: '' }, { text: 'IVA:', alignment: 'left' }, { text: `${toCurrency(vat, data.payment.currency)}`, alignment: 'right' }],
              [{ text: `${toWords.convert(total)?.toUpperCase()}`, alignment: 'right' }, { text: 'Total:', bold: true, alignment: 'left' }, { text: `${toCurrency(total, data.payment.currency)}`, bold: true, alignment: 'right' }],
            ],
          },
          layout: 'noBorders',
        },
      ],
    },
  ]

  const sectionNotes: Content[] = data.notes.length
    ? [
        { text: 'Observaciones', style: 'header', margin: [0, 10, 0, 5] },
        { ul: data.notes.map(note => ({ text: note, color: '#595959', margin: [0, 0, 0, 2], alignment: 'justify' })) },
      ]
    : []

  const tableType = await getOptionsTable(optionsSalesorders.type, 'Tipo de Lista')
  const tableStatus = await getOptionsTable(optionsSalesorders.status, 'Estado de Lista')
  const tableStages = await getOptionsTable(optionsSalesorders.stage, 'Etapa de Lista')

  const sectionLegend = data.pdfSettings?.details
    ? [
        { text: 'Descripción de Opciones', style: 'header', color: '#595959', pageBreak: 'before' },
        { text: 'Resumen de las opciones disponibles para las etiquetas.', color: '#595959', margin: [0, 0, 0, 10] },
        {
          columns: [
            { width: '50%', stack: [tableType] },
            { width: '50%', stack: [tableStatus] },
          ],
          columnGap: 10,
        },
        tableStages,
      ]
    : []

  return {
    language: 'es-ES',
    userPassword: data.pdfSettings?.password?.enabled ? data.pdfSettings?.password?.value : undefined,
    watermark: data.pdfSettings?.watermark?.enabled
      ? {
          text: data.pdfSettings?.watermark.text,
          color: data.pdfSettings?.watermark.color,
          bold: data.pdfSettings?.watermark.bold,
          italics: data.pdfSettings?.watermark.italic,
          opacity: 0.05,
        }
      : undefined,
    info,
    content: [
      sectionHeader,
      sectionClientInfo,
      sectionSalesorderitems,
      ...sectionTotalizedContent,
      ...sectionNotes,
      ...sectionLegend,
    ],
    footer: (currentPage, pageCount, pageSize) => {
      return {
        columns: [
          { image: LOGO_BASE64, fit: [80, 20], margin: [20, 10, 0, 0] },
          {
            stack: [{ text: [{ text: 'Generado con ' }, { text: 'netzo.dev', link: 'https://netzo.dev', color: 'blue' }] }],
            alignment: 'center',
            margin: [0, 10, 0, 0],
          },
          { text: `Página ${currentPage.toString()} de ${pageCount}`, alignment: 'right', margin: [0, 10, 20, 0] },
        ],
        widths: ['auto', '*', 'auto'],
      }
    },
    styles: {
      header: { fontSize: 10, bold: true, margin: [0, 0, 10, 5] },
      tableHeader: { bold: true, fillColor: '#aaa', fillOpacity: 0.25, margin: [5, 5], noWrap: true },
      tableRow: { bold: false, margin: [5, 5] },
      tableRowDetails: { fontSize: 6, margin: [5, 1, 5, 1] },
    },
    defaultStyle: { fontSize: 8 },
    pageSize: 'LETTER',
    pageMargins: [20, 20, 20, 40],
  }
}

export const viewSettingsSalesorders: ViewSettings<Salesorder> = {
  record: 'salesorders',
  views: optionsShared.views.filter(view => ['table', 'kanban', 'calendar'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'fileSalesorderPdf',
      label: 'PDF',
      class: '',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'stage',
      label: 'Etapa',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'paymentStatus',
      label: 'Estado de Pago',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'date',
      label: 'Recepción',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'userId',
      label: 'Vendedor',
    },
    {
      key: 'accountId',
      label: 'Cuenta',
    },
    {
      key: 'contactId',
      label: 'Contacto',
    },
    {
      key: 'dealId',
      label: 'Negocio',
    },
    {
      key: 'quoteId',
      label: 'Cotización',
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'delivery.incoterm',
      label: 'Incoterm',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'delivery.schedule',
      label: 'Tiempo de entrega',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'branchId',
      label: 'Sucursal de entrega',
    },
    {
      key: 'delivery.address',
      label: 'Dirección de entrega',
      class: 'min-w-[200px]', // for <th>
    },
    {
      key: 'delivery.text',
      label: 'Detalles de entrega',
      class: 'min-w-[300px]',
    },
    {
      key: 'payment.satPaymentMethod',
      label: 'Método de pago',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.conditions',
      label: 'Condiciones de pago',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.currency',
      label: 'Moneda',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.exchangeRate.value',
      label: 'Tipo cambio',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'salesorderitems.amount',
      label: 'Subtotal',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'salesorderitems.taxes.transfers',
      label: 'Traslados',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'salesorderitems.taxes.retentions',
      label: 'Retenciones',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'salesorderitems.total',
      label: 'Total',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groups: [
    { label: 'Tipo', value: 'type', options: optionsSalesorders.type },
    { label: 'Etapa', value: 'stage', options: optionsSalesorders.stage },
    { label: 'Estado', value: 'status', options: optionsSalesorders.status },
    { label: 'Estado de Pago', value: 'paymentStatus', options: optionsSalesorders.paymentStatus },
    { label: 'Incoterm', value: 'delivery.incoterm', options: optionsDeliveries.incoterm },
  ],
  group: 'stage',
}
