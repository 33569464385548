import { AppBanksSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppBanksSlideoverProps = ComponentProps<typeof AppBanksSlideover>

export type BanksUtils = ReturnType<typeof useBanksUtils>

export const useBanks = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Bank>('banks')

  const slideoverOpenCreate = (props: AppBanksSlideoverProps) => {
    return new Promise<Bank>((resolve, reject) => {
      const data = getDefaultBank(props.data)
      slideover.open(AppBanksSlideover, {
        title: 'Crear banco',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppBanksSlideoverProps) => {
    return new Promise<Bank>((resolve, reject) => {
      const data = getDefaultBank(props.data)
      slideover.open(AppBanksSlideover, {
        title: 'Editar banco',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    name: z.string(),
    status: z.string(),
    website: z.string(),
    phone: z.string(),
    swift: z.string(),
    bankCode: z.string(),
    subsidiaryNumber: z.string(),
    address: addressSchema,
  }).required()

  // actions:

  const getDropdownItems = (row: Bank, refresh: () => Promise<void>, utils: BanksUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'banks', row, refresh, utils),
      getDropdownItem('copyId', 'banks', row, refresh, utils),
      getDropdownItem('archive', 'banks', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'banks', row, refresh, utils),
      getDropdownItem('files', 'banks', row, refresh, utils),
    ],
    getDropdownItemGroup('status', 'banks', row, refresh, utils),
    // resource actions:
    [
      {
        label: 'Crear cuenta financiera',
        icon: 'i-mdi-card-account-details-outline',
        click: () => {
          const financialaccounts$ = useFinancialaccounts()
          financialaccounts$.slideoverOpenCreate({
            data: {
              type: 'bank',
              status: 'active' as Financialaccount['status'],
              bankId: row.id,
            },
            disabledFields: ['bankId'],
          }).then(refresh)
        },
      },
      ...dropdownItemsAction,
    ],
    [
      {
        label: 'Llamar',
        icon: 'i-mdi-phone-outgoing',
        disabled: !row.phone,
        click: () => open(`tel:${row.phone}`),
      },
    ],
    [
      getDropdownItem('info', 'banks', row, refresh, utils),
      getDropdownItem('inmutable', 'banks', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'banks', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useBanksUtils = () => {
  const options = {
    ...optionsBanks,
  }

  const maps = {
    status: toMapByKey(optionsBanks.status, 'value'),
  }

  return { options, maps }
}
