import type { Options } from '#netzo/utils/core/index'

export const addressSchema = z.object({
  street: z.string(),
  numberExterior: z.string(),
  numberInterior: z.string().nullable().optional(),
  neighborhood: z.string().nullable().optional(),
  city: z.string(),
  district: z.string().nullable().optional(),
  state: z.string(),
  country: z.string(),
  postalCode: z.string(),
})
// NOTE: This options MUST remain in this file, as they are specific to the `deliveries` components
export const optionsAddress = {
} satisfies Options
