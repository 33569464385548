<script setup lang="ts">
const model = defineModel<Bank>({ required: true })

const props = defineProps<{
  utils: BanksUtils
  disabled?: boolean
}>()
</script>

<template>
  <div />
</template>
