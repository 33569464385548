import { AppNotesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppNotesSlideoverProps = ComponentProps<typeof AppNotesSlideover>

export type NotesUtils = ReturnType<typeof useNotesUtils>

export const useNotes = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Note>('notes')

  const slideoverOpen = (props: AppNotesSlideoverProps) => {
    return new Promise<Note>((resolve, reject) => {
      try {
        const data = getDefaultNote(props.data)
        slideover.open(AppNotesSlideover, {
          title: 'Notas del registro',
          ...props,
          data,
        })
        resolve()
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  const schema = z.object({
    recordId: z.string(),
    record: z.string(),
    text: z.string(),
    userId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Note, refresh: () => Promise<void>, utils: NotesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('copyId', 'notes', row, refresh, utils),
    ],
    // resource actions:
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('delete', 'notes', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    slideoverOpen,
    modal,
    modalOpenDelete,
    schema,
    getDropdownItems,
  }
}

export const useNotesUtils = () => {
  const options = {
    ...optionsNotes,
  }

  const maps = {}

  return { options, maps }
}
