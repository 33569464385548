<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types';

const model = defineModel<boolean>()

const props = defineProps<{
  data: Partial<Serviceorder>
  onSubmit: (event: FormSubmitEvent<Partial<Serviceorder['data']['approval']>>) => Promise<void>
}>()

const state = ref<Partial<Serviceorder['data']['approval']>>(props.data?.data ?? {})

const schema = z.object({
  approvedBy: z.string().nonempty('Firma es requerida').min(3).max(100),
  approvedAt: z.string(),
  nps: z.number().int().min(0).max(10).default(0),
  text: z.string().nullable().optional(),
})

const optionNps = computed(() => optionsSharedApp.nps.find(opt => opt.value === state.value.nps))

const disabled = computed(() => !state.value.approvedBy?.length || !state.value.nps)
const loading = ref(false)

const onSubmitWithLoading = async (event: FormSubmitEvent<Partial<Serviceorder['data']['approval']>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardModal
    v-model="model"
    title="Finalizar Aprobación"
    description="Firma con tu nombre completo y calificanos para confirmar la aprobación. Tu opinión es muy importante para nosotros."
  >
    <UForm
      id="form.serviceorders-approval"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      class="space-y-6"
      @error="onFormError"
      @submit="onSubmitWithLoading"
    >
      <UFormGroup
        label="Firma con tu nombre completo"
        name="approvedBy"
        required
      >
        <InputSignature
          v-model="state.approvedBy"
          autofocus
          @blur="() => state.approvedAt = new Date().toISOString()"
        />
      </UFormGroup>

      <UFormGroup
        label="Califica tu experiencia"
        name="nps"
        required
        class="mb-4"
        :hint="`${state.nps} = ${optionNps?.label}`"
      >
        <InputNps v-model="state.nps" />
      </UFormGroup>

      <UFormGroup
        label="Comentarios adicionales"
        name="text"
      >
        <UTextarea
          v-model="state.text"
          :rows="5"
          autoresize
        />
      </UFormGroup>
    </UForm>

    <template #footer>
      <UButton
        form="form.serviceorders-approval"
        type="submit"
        label="Finalizar Aprobación"
        color="green"
        block
        :loading="loading"
        :disabled="disabled"
      />
    </template>
  </UDashboardModal>
</template>
