import { createId, createUid, type Options } from '#netzo/utils/core/index'
import type { CalendarEvent } from '@schedule-x/calendar'
import { format } from 'date-fns'
import { merge } from 'lodash-es'
import type { Content, ContentColumns, TDocumentDefinitions, TDocumentInformation } from 'pdfmake/interfaces'

export const getDefaultServiceorder = (data: Partial<Serviceorder>) => {
  const uid = createUid(new Date(), 'OS') // IMPORTANT: cloudflare throws error if called on global scope
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'complete',
    stage: 'new',
    status: 'pending',
    priority: '2',
    uid: uid,
    name: uid,
    isDatetime: true,
    useAccountAddress: true,
    address: {},
    fileServiceorderPdf: [],
    pdfSettings: defaultPdfSettings,
    tags: [],
    files: [],
    data: getDefaultServiceorderData(data?.data ?? {}),
  }, data)
}

export const optionsServiceorders = {
  type: [
    { value: 'complete', label: 'Completa', description: 'La orden de venta se está cumpliendo en su totalidad, sin elementos pendientes.', icon: 'i-mdi-check-circle-outline', color: 'green' },
    { value: 'partial', label: 'Parcial', description: 'La orden de venta se está cumpliendo parcialmente; quedan elementos pendientes.', icon: 'i-mdi-alert-circle-outline', color: 'yellow' },
    { value: 'rework', label: 'Retrabajo', description: 'Se está realizando un retrabajo debido a problemas con un cumplimiento anterior.', icon: 'i-mdi-wrench-outline', color: 'orange' },
  ],
  stage: optionsProductionorders.stage,
  status: optionsSharedApp.statusProcess,
  priority: optionsSharedApp.priority,
} satisfies Options

export const optionsServiceordersMap = {
  Tipo: optionsServiceorders.type,
  Etapa: optionsServiceorders.stage,
  Estado: optionsServiceorders.status,
  Prioridad: optionsServiceorders.priority,
  Entrega: optionsDeliveriesMap,
}

export const relationsServiceorders: Record<string, boolean> = {
  account: true,
  branch: true,
  contact: true,
  productionorder: true,
  salesorder: true,
  user: true,
  serviceorderitems: true,
}

export type ServiceorderWithRelations = Serviceorder & {
  account?: Account
  branch?: Branch
  contact?: Contact
  productionorder?: Productionorder
  salesorder?: Salesorder
  user?: User
  serviceorderitems?: Serviceorderitem[]
}

export const serviceorderToViewCalendarEvent = (calendarId: keyof Serviceorder, utils: ServiceordersUtils) => {
  return (row: Serviceorder): CalendarEvent | null => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    if (!row.dateStart || !row.dateEnd) return null
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.name}`,
      description: row.text!,
      start: format(new Date(row.dateStart), dateFormat),
      end: format(new Date(row.dateEnd), dateFormat),
      calendarId: row[calendarId],
    }
  }
}

export const createPdfmakeDocumentDefinitionServiceorders = async (
  data: ServiceorderWithRelations,
  utils: ServiceordersUtils,
  salesorder: SalesorderWithRelations,
): Promise<TDocumentDefinitions> => {
  data.data ??= {}
  data.serviceorderitems ??= []

  const maps = {
    serviceorderitems: {
      type: toMapByKey(optionsServiceorders.type, 'value'),
      stage: toMapByKey(optionsServiceorders.stage, 'value'),
      status: toMapByKey(optionsSharedApp.statusProcess, 'value'),
      priority: toMapByKey(optionsSharedApp.priority, 'value'),
    },
    salesorder: {
      incoterm: toMapByKey(optionsDeliveries.incoterm, 'value'),
      schedule: toMapByKey(optionsDeliveries.schedule, 'value'),
    },
  }

  const info: TDocumentInformation = {
    title: `${data.uid}`,
    subject: 'Orden de Servicio La Tinta',
    keywords: 'diseño, agencia, publicidad',
    author: 'LA TINTA',
    producer: 'Netzo (https://netzo.dev)',
  }

  const sectionHeader: ContentColumns = {
    columns: [
      { image: SYMBOL_BASE64, fit: [80, 200], margin: [5, 5] },
      {
        stack: [
          { text: 'Orden de Servicio', fontSize: 18, alignment: 'right', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Referencia: ', bold: true }, `${data.uid}`], alignment: 'right' },
          { text: [{ text: 'Nombre: ', bold: true }, data.name], alignment: 'right' },
          { text: [{ text: 'Fecha de documento: ', bold: true }, new Date().toLocaleDateString()], alignment: 'right' },
        ],
        margin: [0, 10],
      },
    ],
    margin: [0, 0, 0, 5],
  }

  const showSalesorder = data.salesorderId && data.type === 'external'

  const sectionInfo: ContentColumns = {
    columns: [
      {
        stack: [
          { text: 'Informacion General', style: 'header', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Tipo: ', bold: true }, utils.maps.type.get(data.type)?.label!] },
          { text: [{ text: 'Resonsable: ', bold: true }, data.user?.name] },
          { text: [{ text: 'Etapa: ', bold: true }, utils.maps.stage.get(data.stage)?.label!] },
          { text: [{ text: 'Estado: ', bold: true }, utils.maps.status.get(data.status)?.label!] },
          { text: [{ text: 'Prioridad: ', bold: true }, utils.maps.priority.get(data.priority)?.label!] },
          { text: [{ text: 'Inicio: ', bold: true }, new Date(data.dateStart).toLocaleDateString(), ' - ', { text: 'Fin: ', bold: true }, new Date(data.dateEnd).toLocaleDateString()] },
        ],
        width: '33%',
        margin: [0, 10],
      },
      {
        stack: [
          { text: 'Orden de Venta', style: 'header', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Nombre: ', bold: true }, salesorder.name] },
          { text: [{ text: 'Referencia: ', bold: true }, salesorder.uid] },
          { text: [{ text: 'Vendedor: ', bold: true }, salesorder.user?.name] },
          { text: [{ text: 'Fecha de recepcion: ', bold: true }, new Date(salesorder.date).toLocaleDateString()] },
          { text: [{ text: 'Cliente: ', bold: true }, salesorder.account?.name] },
          salesorder.branch && { text: [{ text: 'Sucursal: ', bold: true }, salesorder.branch?.name || ''] } as Content,
        ].filter(Boolean) as Content[],
        width: '33%',
        margin: [2, 10],
      },
      {
        stack: [
          ...(showSalesorder
            ? [
                { text: 'Entrega', style: 'header', margin: [0, 0, 0, 5] },
                { text: [{ text: 'Incoterm: ', bold: true }, maps.salesorder.incoterm.get(salesorder.delivery?.incoterm)?.label || ''], margin: [0, 0, 0, 2] },
                { text: [{ text: 'Tiempo: ', bold: true }, maps.salesorder.schedule.get(salesorder.delivery?.schedule)?.label || ''], margin: [0, 0, 0, 2] },
                ...(['scheduled'].includes(salesorder.delivery?.schedule)
                  ? [
                      { text: [{ text: 'Fecha: ', bold: true }, format(new Date(salesorder.delivery?.date), 'yyyy-MM-dd')], margin: [0, 0, 0, 2] },
                    ] as Content[]
                  : []),
                ...(!['pending', 'EXW'].includes(salesorder.delivery?.incoterm)
                  ? [
                      { text: [{ text: 'Dirección: ', bold: true }, formatAddress(salesorder.delivery?.address) || ''], margin: [0, 0, 0, 2] },
                    ] as Content[]
                  : []),
                ...(salesorder.contact
                  ? [
                      { text: [{ text: 'Contacto: ', bold: true }, { text: `${salesorder.contact?.name ?? 'Por definir'}` }] as Content, margin: [0, 0, 0, 2] },
                      { text: [{ text: 'Teléfono: ', bold: true }, { text: `${salesorder.contact?.phone ?? 'Por definir'}` }] as Content, margin: [0, 0, 0, 2] },
                    ]
                  : []),
              ]
            : []),
        ] as Content[], // Ensuring the stack is explicitly typed as Content[]
        width: '33%',
        margin: [2, 10],
      },
    ],
  }

  const sectionServiceorderitems: Content = {
    table: {
      dontBreakRows: true,
      headerRows: 0,
      widths: data.pdfSettings?.images ? ['50%', '50%'] : ['*'],
      body: [
        ...(await Promise.all(data!.serviceorderitems!.map(async (item: Serviceorderitem, index: number) => [
          ...([item.images[0] ? { image: await getBase64ImageFromURL(item.images[0]), fit: [250, 250], margin: [5, 5, 5, 5], alignment: 'center' } : { text: '' }]),
          {
            stack: [
              { text: [{ text: 'Nombre: ', bold: true }, item.name], margin: [5, 2] },
              { text: [{ text: 'Especificaciones: ', bold: true }, item.text], margin: [5, 2] },
              { text: [{ text: 'Estado: ', bold: true }, maps.serviceorderitems.status.get(item.status)!.label], margin: [5, 2] },
              { text: [{ text: 'Cantidad (Plan): ', bold: true }, item.quantityPlanned], margin: [5, 2] },
              { text: [{ text: 'Cantidad (Real): ', bold: true }, item.quantityActual], margin: [5, 2] },
              item.tasks!.length && { text: [{ text: 'Tareas: ', bold: true }], margin: [5, 2, 5, 1] },
              item.tasks!.length && getChecklist(item.tasks!, 1),
            ].filter(Boolean),
          },
        ]))),
      ].filter(Boolean),
    },
    layout: {
      defaultBorder: true,
    },
  }

  const tableType = await getOptionsTable(optionsServiceorders.type, 'Tipo de Orden')
  const tableStatus = await getOptionsTable(optionsServiceorders.status, 'Estado de Orden')
  const tableStages = await getOptionsTable(optionsServiceorders.stage, 'Etapa de Orden')
  const tablePriorities = await getOptionsTable(optionsServiceorders.priority, 'Prioridad de Orden')

  return {
    language: 'es-MX',
    userPassword: data.pdfSettings?.password?.enabled ? data.pdfSettings?.password?.value : undefined,
    watermark: data.pdfSettings?.watermark?.enabled
      ? {
          text: data.pdfSettings?.watermark.text,
          color: data.pdfSettings?.watermark.color,
          bold: data.pdfSettings?.watermark.bold,
          italics: data.pdfSettings?.watermark.italic,
          opacity: 0.05,
        }
      : undefined,
    info,
    content: [
      sectionHeader,
      sectionInfo,
      data.serviceorderitems?.length ? sectionServiceorderitems : undefined,
      { text: 'Descripción de Opciones', style: 'header', color: '#595959', pageBreak: 'before' },
      { text: 'Resumen de las opciones disponibles para las etiquetas.', color: '#595959', margin: [0, 0, 0, 10] },
      {
        columns: [
          { width: '50%', stack: [tableType] },
          { width: '50%', stack: [tableStatus] },
        ],
        columnGap: 10,
      },
      {
        columns: [
          { width: '50%', stack: [tableStages] },
          { width: '50%', stack: [tablePriorities] },
        ],
        columnGap: 10,
      },
    ],
    footer: (currentPage: number, pageCount: number) => ({
      columns: [
        { image: LOGO_BASE64, fit: [80, 20], margin: [20, 10, 0, 0] },
        {
          stack: [
            {
              text: [
                { text: 'Generado con ' },
                { text: 'netzo.dev', link: 'https://netzo.dev', color: 'blue' },
              ],
            },
          ],
          alignment: 'center',
          margin: [0, 10, 0, 0],
        },
        {
          text: `Página ${currentPage.toString()} de ${pageCount}`,
          alignment: 'right',
          margin: [0, 10, 20, 0],
        },
      ],
      widths: ['auto', '*', 'auto'],
    }),
    styles: {
      header: { fontSize: 10, bold: true, margin: [0, 0, 10, 5] },
      tableHeader: { bold: true, fillColor: '#aaa', fillOpacity: 0.25, margin: [5, 5], noWrap: true },
      tableRow: { bold: false, margin: [5, 5] },
    },
    defaultStyle: { fontSize: 8 },
    pageSize: 'LETTER',
    pageMargins: [20, 20, 20, 40],
  }
}

export const viewSettingsServiceorders: ViewSettings<Serviceorder> = {
  record: 'serviceorders',
  views: optionsShared.views.filter(view => ['table', 'kanban', 'calendar'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'fileServiceorderPdf',
      label: 'PDF',
      class: '',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'stage',
      label: 'Etapa',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateStart',
      label: 'Fecha de inicio',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateEnd',
      label: 'Fecha de cierre',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'accountId',
      label: 'Cuenta',
    },
    {
      key: 'userId',
      label: 'Responsable',
    },
    {
      key: 'contactId',
      label: 'Contacto',
    },
    {
      key: 'branchId',
      label: 'Sucursal',
    },
    {
      key: 'address',
      label: 'Dirección',
      class: 'min-w-[300px]',
    },
    {
      key: 'tasks',
      label: 'Tareas',
      class: 'min-w-[200px]',
    },
    {
      key: 'items',
      label: 'Items',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'productionorderId',
      label: 'Orden de producción',
    },
    {
      key: 'productionorder.stage',
      label: 'Etapa (OP)',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'productionorder.status',
      label: 'Estado (OP)',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'salesorderId',
      label: 'Orden de venta',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groups: [
    { label: 'Tipo', value: 'type', options: optionsServiceorders.type },
    { label: 'Etapa', value: 'stage', options: optionsServiceorders.stage },
    { label: 'Estado', value: 'status', options: optionsServiceorders.status },
    { label: 'Prioridad', value: 'priority', options: optionsServiceorders.priority },
  ],
  group: 'stage',
}
