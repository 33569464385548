import type { Content } from 'pdfmake/interfaces'

export const CHECKBOX_UNMARKED = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABGSURBVHgB7dXBDQAgCENRahyE/ZdUSuIENJ76Ezm+eAKcKpQRzEyio0eDoQcQ048+Y4U4gwYNGvwDbo5ajr3PJtFgUJ+AC/JdLYOGrkhiAAAAAElFTkSuQmCC'
export const CHECKBOX_MARKED = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADmSURBVHgBrdSBDYQgDAVQuNwAuoEjuIIT6AaOoiO4kaPoBrBBjzaHIQctEK6JRg15+dgGDa7UPwvBYRgQbbrQoHDfhPRyHAdc1wWlZYyBfd8hNF5hWrdATdOk7vvO7sxaS2tdgHjLXsfato3iS0kx2TiOsK7r880bEZhDU1gW5FAOKwJ/UQkrBkNUwqpATNZ1Ha2RGuWNd8lozPOsXEp6Ps+TntniEqb+mdR9cctSAziUBXPd5NAkWIJxaATWYCk0AvHUqMFCdFmWB9R001pBwzmL49X3PRk0hzhXiLaUn00NLdES9QHF8pmkV3v9YQAAAABJRU5ErkJggg=='

export const getChecklist = (
  checklist: { resolved: boolean, label?: string, text?: string }[],
  cols: 1 | 2 | 3,
): Content => {
  const rows: any[] = []
  for (let i = 0; i < checklist.length; i += cols) {
    const row: any[] = [] // Ensure 'row' is an array of content type

    for (let j = 0; j < cols; j++) {
      const item = checklist[i + j]
      if (item) {
        row.push({
          table: {
            widths: ['auto', '*'], // Set auto width for checkbox column, the rest takes remaining space
            body: [
              [
                {
                  image: item.resolved ? CHECKBOX_MARKED : CHECKBOX_UNMARKED,
                  fit: [10, 10],
                },
                {
                  text: item.label ?? item.text,
                },
              ],
            ],
          },
          layout: 'noBorders',
        })
      }
      else {
        row.push({
          table: {
            widths: ['auto', '*'],
            body: [[{ text: '', colSpan: 2 }]],
          },
          layout: 'noBorders',
        })
      }
    }

    rows.push(row)
  }

  return {
    table: {
      dontBreakRows: true,
      headerRows: 0,
      widths: Array(cols).fill('*'), // Dynamic column widths based on the number of columns
      body: rows,
    },
    layout: 'noBorders',
    margin: [5, 5],
  }
}
