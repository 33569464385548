import { FilesSlideover } from '#components'
import type { TRecord } from '#shared'
import type { DropdownItem } from '#ui/types'

export const getDropdownItem = (
  key: string,
  record: string,
  row: TRecord,
  refresh: () => Promise<void>,
  utils: TRecordUtils,
): DropdownItem => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenEdit,
  } = useRecord(record)

  const route = useRoute()
  const { moduleId } = useModules()

  return ({
    edit: {
      label: row.$inmutable ? 'Ver detalles' : 'Editar',
      icon: row.$inmutable ? 'i-mdi-text-box-search' : 'i-mdi-pencil',
      click: () => {
        slideoverOpenEdit({
          data: row,
        }).then(refresh)
      },
    },
    items: {
      label: 'Ver ítems',
      icon: 'i-mdi-format-list-bulleted',
      disabled: `${route.fullPath}` === `/${moduleId.value}/${record}/${row.id}/items`,
      href: `/${moduleId.value}/${record}/${row.id}/items`,
    },
    copyId: {
      label: 'Copiar ID',
      icon: 'i-mdi-content-copy',
      click: () => { copyToClipboard(row.id) },
    },
    copyUid: {
      label: 'Copiar Referencia',
      icon: 'i-mdi-content-copy',
      click: () => { copyToClipboard(row.uid) },
    },
    archive: {
      label: row.deletedAt ? 'Desarchivar' : 'Archivar',
      icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
      click: async () => {
        const deletedAt = row?.deletedAt ? null : new Date().toISOString()
        await onSubmitUpdate({ ...row, deletedAt }) // soft delete
        await refresh()
      },
    },
    notes: {
      label: 'Abrir notas',
      icon: 'i-mdi-text',
      click: () => {
        const notes$ = useNotes()
        notes$.slideoverOpen({ data: { recordId: row.id, record, text: '' } })
      },
    },
    files: {
      label: 'Ver archivos',
      icon: 'i-mdi-file-multiple',
      click: () => {
        slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
      },
    },
    password: {
      label: 'Copiar contraseña',
      icon: 'i-mdi-asterisk',
      disabled: !row.pdfSettings?.password?.enabled,
      click: () => copyToClipboard(row.pdfSettings?.password?.value),
    },
    info: {
      label: 'Información',
      icon: 'i-mdi-information',
      click: () => { slideoverOpenInformation(record, row) },
    },
    inmutable: {
      label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
      icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
      click: async () => {
        await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
        await refresh()
      },
    },
    delete: {
      label: 'Eliminar',
      icon: 'i-mdi-delete',
      iconClass: 'bg-red-500',
      labelClass: 'text-red-500',
      click: () => {
        modalOpenDelete({
          onSubmit: async (event) => {
            await onSubmitDelete([row])
            modal.close()
            await refresh()
          },
        })
      },
    },
  })?.[key]
}

export const getDropdownItemGroup = (
  key: string,
  record: string,
  row: TRecord,
  refresh: () => Promise<void>,
  utils: TRecordUtils,
): DropdownItem[] => {
  const { onSubmitUpdate } = useRecord(record)

  // Check if the key exists in the options and map dynamically
  const groupItems = utils.options[key]?.map(option => ({
    label: `Marcar como ${option?.label.toLowerCase()}`,
    icon: option?.icon,
    disabled: row[key] === option.value || row.$inmutable,
    click: async () => {
      await onSubmitUpdate({ ...row, [key]: option.value })
      await refresh()
    },
  }))

  return groupItems || []
}
