import { AppSalesordersSlideover, FilesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'
import type { Salesorder } from '~~/server/utils/db.types'

// types:

type AppSalesordersSlideoverProps = ComponentProps<typeof AppSalesordersSlideover>

export type SalesordersUtils = ReturnType<typeof useSalesordersUtils>

export const useSalesorders = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Salesorder>('salesorders')

  const slideoverOpenCreate = (props: AppSalesordersSlideoverProps) => {
    return new Promise<Salesorder>((resolve, reject) => {
      const data = getDefaultSalesorder(props.data)
      slideover.open(AppSalesordersSlideover, {
        title: 'Registrar orden de venta',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppSalesordersSlideoverProps) => {
    return new Promise<Salesorder>((resolve, reject) => {
      const data = getDefaultSalesorder(props.data)
      slideover.open(AppSalesordersSlideover, {
        title: 'Editar orden de venta',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    stage: z.string(),
    status: z.string(),
    paymentStatus: z.string(),
    name: z.string(),
    date: z.string(),
    payment: paymentSchema,
    delivery: deliverySchema,
    accountId: z.string(),
    dealId: z.string(),
    quoteId: z.string(),
    userId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Salesorder, refresh: () => Promise<void>, utils: SalesordersUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'salesorders', row, refresh, utils),
      getDropdownItem('items', 'salesorders', row, refresh, utils),
      getDropdownItem('copyId', 'salesorders', row, refresh, utils),
      getDropdownItem('copyUid', 'salesorders', row, refresh, utils),
      getDropdownItem('archive', 'salesorders', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'salesorders', row, refresh, utils),
      getDropdownItem('files', 'salesorders', row, refresh, utils),
      {
        label: 'Ver archivos (negocio)',
        icon: 'i-mdi-file-multiple',
        disabled: !row.dealId && row.deal.files.length > 0,
        click: () => {
          slideover.open(FilesSlideover, { data: row.deal, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    [
      getDropdownItem('password', 'salesorders', row, refresh, utils),
      {
        label: 'Ver PDF',
        icon: 'i-mdi-file-pdf',
        click: () => slideoverOpenFilePreview(row.id, row.fileSalesorderPdf, `Orden de venta: ${row.name}`),
      },
    ],
    getDropdownItemGroup('status', 'salesorders', row, refresh, utils),
    [
      {
        label: 'Agregar item',
        disabled: row.$inmutable,
        icon: ICONS.salesorderitems,
        click: () => {
          const salesorderitems$ = useSalesorderitems()
          salesorderitems$.slideoverOpenCreate({
            data: { salesorderId: row.id },
            disabledFields: ['salesorderId'],
          }).then(refresh)
        },
      } as DropdownItem,
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'salesorders', row, refresh, utils),
      getDropdownItem('inmutable', 'salesorders', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'salesorders', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  // files:

  const { uploadFile, removeFile } = useApiBlob()

  const createPdf = async (data: SalesorderWithRelations, utils: SalesordersUtils) => {
    const documentDefinition = await createPdfmakeDocumentDefinitionSalesorders(data, utils)
    const pdf = await usePDFMake().createPdf(documentDefinition)
    return pdf
  }

  // updates the salesorder.files array (to be used after POST/PATCH)
  async function createFileSalesorderPdf(data: Partial<Salesorder>, utils: SalesordersUtils) {
    const [existingData] = await $fetch<SalesorderWithRelations[]>(`/api/db/salesorders`, {
      query: { id: data.id, $with: relationsSalesorders },
    })
    const newData = { ...existingData, ...data } as SalesorderWithRelations
    const pdf = await createPdf(newData, utils)
    // IMPORTANT: wrap with a Promise to await the blob
    const blob: Blob = await new Promise(resolve => pdf.getBlob(blob => resolve(blob)))
    const file = new File([blob], 'salesorder.pdf', { type: 'application/pdf' })
    return uploadFile(file, { prefix: `salesorders/${data.id}` })
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
    createPdf,
    createFileSalesorderPdf,
  }
}

export const useSalesordersUtils = ({ $accounts, $branches, $contacts, $deals, $quotes, $users }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $deals?: Awaited<ReturnType<typeof useFetch<Deal[]>>>
  $quotes?: Awaited<ReturnType<typeof useFetch<Quote[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsSalesorders,
    delivery: optionsDeliveries,
    productionorder: {
      ...optionsProductionorders,
    },
    serviceorder: {
      ...optionsServiceorders,
    },
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    dealId: computed(() => $deals?.data.value.map(toOption)),
    quoteId: computed(() => $quotes?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsSalesorders.type, 'value'),
    stage: toMapByKey(optionsSalesorders.stage, 'value'),
    status: toMapByKey(optionsSalesorders.status, 'value'),
    paymentStatus: toMapByKey(optionsSalesorders.paymentStatus, 'value'),
    satCfdiUse: toMapByKey(optionsSAT.taxCfdiUse, 'value'),
    billing: {
      cfdiType: toMapByKey(optionsSAT.cfdiType, 'value'),
      satFiscalRegime: toMapByKey(optionsSAT.fiscalRegime, 'value'),
      satTaxObject: toMapByKey(optionsSAT.taxObject, 'value'),
    },
    payment: {
      method: toMapByKey(optionsSAT.paymentMethod, 'value'),
      form: toMapByKey(optionsSAT.paymentForm, 'value'),
      condition: toMapByKey(optionsAccounts.billing.paymentConditions, 'value'),
      currency: toMapByKey(optionsSAT.currency, 'value'),
    },
    delivery: {
      incoterm: toMapByKey(optionsDeliveries.incoterm, 'value'),
      schedule: toMapByKey(optionsDeliveries.schedule, 'value'),
    },
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches?.data.value, 'id')),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    dealId: computed(() => toMapByKey($deals?.data.value, 'id')),
    quoteId: computed(() => toMapByKey($quotes?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches?.data.value, 'accountId')),
    contactsByAccountId: computed(() => toMapByGroupKey($contacts?.data.value, 'accountId')),
    dealByAccountId: computed(() => toMapByGroupKey($deals?.data.value, 'accountId')),
    quoteByAccountId: computed(() => toMapByGroupKey($quotes?.data.value, 'accountId')),
    quoteByDealId: computed(() => toMapByGroupKey($quotes?.data.value, 'dealId')),
  }

  return { options, maps }
}
