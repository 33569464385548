<script setup lang="ts">
const model = defineModel<Productionorderitem>({ required: true })

const props = defineProps<{
  utils: ProductionorderitemsUtils
  disabled?: boolean
}>()
</script>

<template>
  <details open>
    <summary class="mt-4 mb-2 cursor-pointer font-bold text-gray-700">
      Aprobación
    </summary>
    <fieldset :class="FIELDSET" :disabled="disabled">
      <UFormGroup
        label="Items de Orden de Servicio"
        name="serviceorderitemIds"
      >
        <SelectMenuBase
          v-model="state.serviceorderitemId"
          :options="data.serviceorderitems.map(toOption)"
          multiple
        />
      </UFormGroup>

      <UFormGroup class="grid grid-cols-2 md:grid-cols-3 gap-4 mb-4">
        <div
          v-for="(item, index) in model.data.items"
          :key="`item-${index}`"
          class="flex items-center gap-1"
        >
          <UButton
            variant="ghost"
            size="sm"
            :icon="!item.resolved ? 'i-mdi-checkbox-blank-outline' : 'i-mdi-checkbox-marked'"
            @click="() => item.resolved = !item.resolved"
          />
          <span class="flex-1 block font-medium text-gray-700 dark:text-gray-200">
            {{ item.label }}
          </span>
        </div>
      </UFormGroup>
    </fieldset>
  </details>
</template>
