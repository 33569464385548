import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

// TODO: Fix edit tax salesorderitems must also be considered
export const getDefaultSalesorderitem = (data: Partial<Salesorderitem>): Salesorderitem => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'products',
    subtype: 'finished-goods',
    discount: { type: 'percent' },
    fiscalData: {
      satTaxObject: '02',
    },
    taxes: {
      transfers: [
        {
          enabled: true,
          tax: '002',
          satFactorType: 'rate',
          satRateOrQuota: 0.16000,
          base: 0,
          amount: 0,
        },
        {
          enabled: false,
          tax: '003',
          satFactorType: 'rate',
          satRateOrQuota: 0.08000,
          base: 0,
          amount: 0,
        },
      ],
      retentions: [
        {
          enabled: false,
          tax: '002',
          satFactorType: 'rate',
          satRateOrQuota: 0.10000,
          base: 0,
          amount: 0,
        },
        {
          enabled: false,
          tax: '001',
          satFactorType: 'rate',
          satRateOrQuota: 0.10000,
          base: 0,
          amount: 0,
        },
      ],
    },
    tags: [],
    files: [],
    data: getDefaultSalesorderitemData(data?.data ?? {}),
  }, data)
}

export const optionsSalesorderitems = {
  type: optionsCategories.type,
  subtype: {
    products: optionsProducts.type,
    services: optionsServices.type,
  },
  discount: [
    { value: 'percent', label: '%' },
    { value: 'number', label: '$' },
  ],
} satisfies Options

export const optionsSalesorderitemsMap = {
  Tipo: optionsSalesorderitems.type,
  Subtipo: optionsSalesorderitems.subtype,
}

export const relationsSalesorderitems: Record<string, boolean> = {
  productitem: true,
  salesorder: true,
  serviceitem: true,
}

export type SalesorderitemWithRelations = Salesorderitem & {
  productitem: Productitem
  salesorder: Salesorder
  serviceitem: Serviceitem
}

export const viewSettingsSalesorderitems: ViewSettings<Salesorderitem> = {
  record: 'salesorderitems',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'subtype',
      label: 'Subtipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'sku',
      label: 'SKU',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'fiscalData.satProductServiceKey',
      label: 'Clave (SAT)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'fiscalData.satUnitKey',
      label: 'Unidad (SAT)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'quantity',
      label: 'Cantidad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'unitPrice',
      label: 'Precio unitario',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'discount.amount',
      label: 'Descuento',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'amount',
      label: 'Importe',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'taxes.transfers',
      label: 'Traslados',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'taxes.retentions',
      label: 'Retenciones',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'total',
      label: 'Total',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
