import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultCategory = (data: Partial<Category>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    tags: [],
    files: [],
    data: getDefaultCategoryData(data?.data ?? {}),
  }, data)
}

export const getCategoryInformation = (data: Partial<Category>) => {
  const maps = {
    type: toMapByKey(optionsCategories.type, 'value'),
  }
  if (!data) return []
  return [
    ['Categoria', data?.name],
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Descripción', data?.description],
  ].filter(([_, value]) => value != null)
}

export const optionsCategories = {
  type: [
    { value: 'products', label: 'Productos', icon: 'i-mdi-package-variant-closed', color: 'orange', description: 'Los productos son bienes, tangibles (p.ej. computadoras), que forman parte de las actividades principales de una empresa.' },
    { value: 'services', label: 'Servicios', icon: 'i-mdi-tools', color: 'purple', description: 'Los servicios son bienes, intangibles (p.ej. consultoría, envío de paquetes).' },
  ],
} satisfies Options

export const optionsCategoriesMap = {
  Tipo: optionsCategories.type,
}

export const relationsCategories: Record<string, boolean> = {
  products: true,
  services: true,
}

export type CategoryWithRelations = Category & {
  products: Product[]
  services: Service[]
}

export const viewSettingsCategories: ViewSettings<Category> = {
  record: 'categories',
  views: optionsShared.views.filter(view => ['table', 'gallery'].includes(view.value)),
  selectedView: 'gallery',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
