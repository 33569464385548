<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Note> & Pick<Note, 'recordId' | 'record' | 'text'>
  title?: string
}>()

const { user } = useUserSession()
const { userModule } = useModules()

const state = ref<Partial<Note>>(getDefaultNote({
  ...props.data,
  text: '',
  recordId: props.data.recordId!,
  record: props.data.record!,
  userId: user.value!.id,
}))

const query = ref<ViewQuery>({
  recordId: [props.data.recordId],
  record: [props.data.record],
  userId: [],
  deletedAt: '$isNull',
  $search: { columns: ['text'], value: '' },
  $sort: { column: 'createdAt', direction: 'desc' },
  // $pagination: { page: 1, pageSize: 25 },
  $with: relationsNotes,
})
const $notes = await useFetch<NoteWithRelations[]>('/api/db/notes', {
  query,
  default: () => [],
})

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpen,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
} = useNotes()

const utils = useNotesUtils()

const getDefault = () => getDefaultNote({
  ...props.data,
  text: '',
  recordId: props.data.recordId!,
  record: props.data.record!,
  userId: user.value!.id,
})

const loading = ref(false)

const onSubmitNote = async (event: FormSubmitEvent<Partial<Note>>) => {
  loading.value = true
  await onSubmitCreate(event.data)
  await $notes.refresh()
  loading.value = false
  state.value = getDefault()
}
</script>

<template>
  <UDashboardSlideover
    :title="title"
    :ui="{ body: { base: 'px-0' }, footer: { padding: 'pt-0' } }"
  >
    <div class="flex flex-col h-full gap-3">
      <UForm
        id="form.notes"
        :validate-on="['submit']"
        :schema="schema"
        :state="state"
        class="px-4"
        @submit="onSubmitNote"
      >
        <UFormGroup name="text">
          <UTextarea
            v-model="state.text!"
            :rows="4"
            :maxrows="20"
            autoresize
            placeholder="Escribe una nota..."
            :options="['Formatea a Markdown']"
          />
          <UButton
            form="form.notes"
            block
            type="submit"
            :loading="loading"
            :disabled="!state.text"
            class="my-3"
          >
            Agregar nota
          </UButton>
        </UFormGroup>
      </UForm>

      <ViewPlaceholder
        :loading="['pending'].includes($notes.status.value)"
        :rows="$notes.data.value"
        icon="i-mdi-note-text"
        label="No hay notas para mostrar."
      >
        <div class="flex-1 h-full overflow-y-auto flex flex-col gap-3 px-4">
          <AppNotesCardNote
            v-for="(note, i) in $notes.data.value"
            :key="`note-${note.id}-${i}`"
            :note="note"
            :dropdown-items="getDropdownItems(note, $notes.refresh, utils)"
          />
        </div>
      </ViewPlaceholder>
    </div>
  </UDashboardSlideover>
</template>
