import { AppAuditlogsModalChanges } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

export type AuditlogsUtils = ReturnType<typeof useAuditlogsUtils>

export const useAuditlogs = () => {
  // const slideover = useSlideover()
  const modal = useModal()

  // forms:

  const {
    onSubmitCreate,
    onSubmitUpdate,
    // onSubmitDelete,
    onSubmitUpdateMultiple,
  } = useApiDB<Auditlog>('auditlogs')

  const modalOpenChanges = (props: ComponentProps<typeof AppAuditlogsModalChanges>) => {
    modal.open(AppAuditlogsModalChanges, { title: 'Cambios en el registro', ...props })
  }

  const schema = z.object({
    action: z.string(),
    recordId: z.string(),
    record: z.string(),
    userId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Auditlog, refresh: () => Promise<void>, utils: AuditlogsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('copyId', 'auditlogs', row, refresh, utils),
      {
        label: 'Ver cambios',
        icon: 'i-mdi-magnify',
        click: () => { modalOpenChanges({ data: row, utils }) },
      },
    ],
    [
      ...dropdownItemsAction,
    ],
  ].filter(items => items?.length)

  // other:

  const getAuditlogDescription = (auditlog: Auditlog, utils: AuditlogsUtils) => {
    const action = ({
      create: 'creó',
      update: 'actualizó',
      delete: 'eliminó',
    })[auditlog.action] ?? auditlog.action
    // const record = utils.maps.record.get(auditlog.record)?.label?.toLowerCase()
    const user = utils.maps.userId.value.get(auditlog.userId)
    const userName = user?.name ?? user?.email ?? auditlog.userId
    const columns = Object.keys(auditlog.data ?? {}).map(key => `<code>${key}</code>`)
    return ['update'].includes(auditlog.action)
      ? `${userName} ${action} el registro en los campos ${columns.join(', ')}.`
      : `${userName} ${action} el registro.`
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitUpdateMultiple,
    modal,
    modalOpenChanges,
    schema,
    getDropdownItems,
    getAuditlogDescription,
  }
}

export const useAuditlogsUtils = ({ $users }: {
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsAuditlogs,
    record: optionsShared.record,
    userId: computed(() => $users.data.value.map(toOption)),
  }

  const maps = {
    action: toMapByKey(optionsAuditlogs.action, 'value'),
    record: toMapByKey(optionsAuditlogs.record, 'value'),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
  }

  return { options, maps }
}
