import { AppApplicationsSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppApplicationsSlideoverProps = ComponentProps<typeof AppApplicationsSlideover>

export type ApplicationsUtils = ReturnType<typeof useApplicationsUtils>

export const useApplications = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Application>('applications')

  const slideoverOpenCreate = (props: AppApplicationsSlideoverProps) => {
    return new Promise<Application>((resolve, reject) => {
      const data = getDefaultApplication(props.data)
      slideover.open(AppApplicationsSlideover, {
        title: 'Crear aplicación',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppApplicationsSlideoverProps) => {
    return new Promise<Application>((resolve, reject) => {
      const data = getDefaultApplication(props.data)
      slideover.open(AppApplicationsSlideover, {
        title: 'Editar aplicación',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    name: z.string(),
    description: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Application, refresh: () => Promise<void>, utils: ApplicationsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'applications', row, refresh, utils),
      getDropdownItem('copyId', 'applications', row, refresh, utils),
      getDropdownItem('archive', 'applications', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'applications', row, refresh, utils),
      getDropdownItem('files', 'applications', row, refresh, utils),
    ],
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'applications', row, refresh, utils),
      getDropdownItem('inmutable', 'applications', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'applications', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useApplicationsUtils = () => {
  const options = {
    ...optionsApplications,
  }

  const maps = {}

  return { options, maps }
}
