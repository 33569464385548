<script setup lang="ts">
const model = defineModel<boolean>()

const props = defineProps<{
  title?: string
  description?: string
  data: User
}>()

const { clear } = useUserSession()

const signOut = async () => {
  await clear()
  return navigateTo('/auth/login')
}

const loading = ref(false)

async function onDelete() {
  loading.value = true

  await $fetch(`/api/db/users/${props.data.id}`, { method: 'DELETE' })

  setTimeout(() => {
    loading.value = false
    useToastAlert().success({
      title: 'Tu usuario ha sido eliminado. Cerrando sesión...',
      callback: signOut,
    })
    model.value = false
  }, 2000)
}
</script>

<template>
  <UDashboardModal
    v-model="model"
    :title
    :description
    icon="i-mdi-alert-circle"
    prevent-close
    :close-button="null"
    :ui="{
      icon: {
        base: 'text-red-500 dark:text-red-400',
      } as any,
      footer: {
        base: 'ml-16',
      } as any,
    }"
  >
    <template #footer>
      <UButton
        color="red"
        label="Eliminar"
        :loading="loading"
        @click="onDelete"
      />
      <UButton
        variant="outline"
        label="Cancelar"
        @click="model = false"
      />
    </template>
  </UDashboardModal>
</template>
