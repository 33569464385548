import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultProductitem = (data: Partial<Productitem>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    status: 'active',
    fiscalData: {},
    tags: [],
    files: [],
    data: getDefaultProductitemData(data?.data ?? {}),
  }, data)
}

export const productitemToConcept = (data: Productitem & { product: Product }, basePrice?: number): Partial<Quoteitem> | Partial<Salesorderitem> => {
  const { product, ...productitem } = data ?? {}
  return {
    type: 'products',
    subtype: product.type,
    name: productitem?.name,
    description: productitem?.description || null,
    image: productitem?.image ?? product.image ?? null,
    sku: productitem?.sku || null,
    fiscalData: {
      satProductServiceKey: product?.fiscalData?.satProductServiceKey ?? undefined,
      satUnitKey: productitem?.fiscalData?.satUnitKey ?? undefined,
    },
    unitPrice: basePrice,
    productitemId: productitem.id,
  }
}

export const optionsProductitems = {
  status: optionsSharedApp.statusResource,
} satisfies Options

export const optionsProductitemsMap = {
  'Estado': optionsProductitems.status,
  'Datos Fiscales': {
    'Unidad SAT': optionsSAT.productUnitId,
  },
}

export const relationsProductitems: Record<string, boolean> = {
  pricelistitems: true,
  product: true,
  salesorderitems: true,
  quoteitems: true,
}

export type ProductitemWithRelations = Productitem & {
  pricelistitems: Pricelistitem[]
  product: Product
  salesorderitems: Salesorderitem[]
  quoteitems: Quoteitem[]
}

export const viewSettingsProductitems: ViewSettings<Productitem> = {
  record: 'productitems',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'sku',
      label: 'SKU',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'productId',
      label: 'Producto',
      sortable: true,
    },
    {
      key: 'product.type',
      label: 'Tipo de Producto',
      sortable: true,
      lass: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'product.status',
      label: 'Estado de Producto',
      sortable: true,
      lass: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'product.fiscalData.satProductServiceKey',
      label: 'Clave (SAT)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'fiscalData.satUnitKey',
      label: 'Unidad (SAT)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
