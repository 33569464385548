<script setup lang="ts">
const model = defineModel<Quoteitem | Salesorderitem>({ required: true })

defineProps<{
  utils: QuoteitemsUtils
  schema?: z.ZodObject<Quoteitem>
  disabledFields?: (keyof Quoteitem | string)[]
  disabled?: boolean
}>()

const onUpdateQuoteitemTaxes = () => {
  const calculateTax = (taxItem: ItemTaxes) => ({
    ...taxItem,
    base: Number(model.value.amount),
    amount: taxItem.enabled ? Number(model.value.amount) * Number(taxItem.satRateOrQuota) : 0,
  })

  const { transfers, retentions } = model.value.taxes!

  model.value.taxes.transfers = transfers.map(calculateTax)
  model.value.taxes.retentions = retentions.map(calculateTax)
}

watch(() => model.value.amount, () => {
  onUpdateQuoteitemTaxes()
})
</script>

<template>
  <fieldset :class="FIELDSET_FULL_12" :disabled="disabled">
    <UFormGroup
      label="Objeto de Impuesto"
      name="fiscalData.satTaxObject"
      :required="isRequired(schema, 'fiscalData.satTaxObject')"
      class="md:col-span-4"
    >
      <SelectMenuBase
        v-model="model.fiscalData.satTaxObject"
        :options="optionsSAT.taxObject"
        :disabled="disabledFields?.includes('fiscalData.satTaxObject')"
      />
    </UFormGroup>

    <UFormGroup
      label="Unidad (SAT)"
      name="fiscalData.satUnitKey"
      :required="isRequired(schema, 'fiscalData.satUnitKey')"
      class="md:col-span-4"
    >
      <SelectMenuBase
        v-model="model.fiscalData.satUnitKey"
        :disabled="disabledFields?.includes('fiscalData.satUnitKey')"
        :options="optionsSAT.productUnitId"
      />
    </UFormGroup>

    <UFormGroup
      label="Clave Producto (SAT)"
      name="fiscalData.satProductServiceKey"
      :required="isRequired(schema, 'fiscalData.satProductServiceKey')"
      class="md:col-span-4"
    >
      <InputBaseLink
        v-model="model.fiscalData.satProductServiceKey"
        :disabled="disabledFields?.includes('fiscalData.satProductServiceKey')"
        href="https://www.sat.gob.mx/consultas/53693/catalogo-de-productos-y-servicios"
      />
    </UFormGroup>

    <UDivider class="md:col-span-12 py-3" />

    <Alert
      v-if="['01'].includes(model.fiscalData?.satTaxObject)"
      type="info"
      title="Item sin objecto de impuestos. Los impuestos estan deshabilitados y no se aplicarán."
      class="md:col-span-12"
    />
    <template v-else>
      <UFormGroup
        label="Traslados"
        name="transfers"
        class="md:col-span-6 flex flex-col gap-2"
      >
        <div class="grid grid-cols-[1fr,2fr,2fr,2fr] items-center gap-2">
          <UCheckbox
            v-model="model.taxes.transfers[0].enabled"
            label="IVA"
            @update:model-value="onUpdateQuoteitemTaxes"
          />
          <SelectMenuBase
            v-model="model.taxes.transfers[0].satFactorType"
            :options="optionsSAT.taxFactor"
            :disabled="!model.taxes.transfers[0].enabled"
            @update:model-value="onUpdateQuoteitemTaxes"
          />
          <SelectMenuBase
            v-model="model.taxes.transfers[0].satRateOrQuota"
            :options="optionsSAT.tax002RateTransfers"
            :disabled="!model.taxes.transfers[0].enabled"
            @update:model-value="onUpdateQuoteitemTaxes"
          />
          <InputReadOnly
            :model-value="toCurrency(model.taxes.transfers[0]?.amount || 0, defaultAppVariables.currency)"
          />
        </div>
        <div class="grid grid-cols-[1fr,2fr,2fr,2fr] items-center gap-2 mt-2">
          <UCheckbox
            v-model="model.taxes.transfers[1].enabled"
            label="IEPS"
            @update:model-value="onUpdateQuoteitemTaxes"
          />
          <SelectMenuBase
            v-model="model.taxes.transfers[1].satFactorType"
            :options="optionsSAT.taxFactor"
            :disabled="!model.taxes.transfers[1].enabled"
            @update:model-value="onUpdateQuoteitemTaxes"
          />
          <SelectMenuBase
            v-model="model.taxes.transfers[1].satRateOrQuota"
            :options="optionsSAT.tax003RateTransfers"
            :disabled="!model.taxes.transfers[1].enabled"
            @update:model-value="onUpdateQuoteitemTaxes"
          />
          <InputReadOnly
            :model-value="toCurrency(model.taxes.transfers[1]?.amount || 0, defaultAppVariables.currency)"
          />
        </div>
        <div class="grid grid-cols-[1fr,2fr,2fr,2fr] items-center gap-2 mt-2">
          <div class="col-span-2" />
          <div class="flex justify-end">
            <span class="font-regular">Total:</span>
          </div>

          <InputReadOnly
            :model-value="toCurrency(
              model.taxes.transfers.reduce((acc, tax) => acc + tax.amount, 0) || 0,
              defaultAppVariables.currency,
            )"
          />
        </div>
      </UFormGroup>
      <UFormGroup
        label="Retenciones"
        name="retentions"
        class="md:col-span-6 flex flex-col gap-2"
      >
        <div class="grid grid-cols-[1fr,2fr,2fr,2fr] items-center gap-2">
          <UCheckbox
            v-model="model.taxes.retentions[0].enabled"
            label="IVA"
            @update:model-value="onUpdateQuoteitemTaxes"
          />
          <SelectMenuBase
            v-model="model.taxes.retentions[0].satFactorType"
            :options="optionsSAT.taxFactor"
            :disabled="!model.taxes.retentions[0].enabled"
            @update:model-value="onUpdateQuoteitemTaxes"
          />
          <SelectMenuBase
            v-model="model.taxes.retentions[0].satRateOrQuota"
            :options="optionsSAT.tax002RateRetentions"
            :disabled="!model.taxes.retentions[0].enabled"
            @update:model-value="onUpdateQuoteitemTaxes"
          />
          <InputReadOnly
            :model-value="toCurrency(model.taxes.retentions[0]?.amount || 0, defaultAppVariables.currency)"
          />
        </div>
        <div class="grid grid-cols-[1fr,2fr,2fr,2fr] items-center gap-2 mt-2">
          <UCheckbox
            v-model="model.taxes.retentions[1].enabled"
            label="ISR"
            @update:model-value="onUpdateQuoteitemTaxes"
          />
          <SelectMenuBase
            v-model="model.taxes.retentions[1].satFactorType"
            :options="optionsSAT.taxFactor"
            :disabled="!model.taxes.retentions[1].enabled"
            @update:model-value="onUpdateQuoteitemTaxes"
          />
          <SelectMenuBase
            v-model="model.taxes.retentions[1].satRateOrQuota"
            :options="optionsSAT.tax001RateRetentions"
            :disabled="!model.taxes.retentions[1].enabled"
            @update:model-value="onUpdateQuoteitemTaxes"
          />
          <InputReadOnly
            :model-value="toCurrency(model.taxes.retentions[1]?.amount || 0, defaultAppVariables.currency)"
          />
        </div>
        <div class="grid grid-cols-[1fr,2fr,2fr,2fr] items-center gap-2 mt-2">
          <div class="col-span-2" />
          <div class="flex justify-end">
            <span class="font-regular">Total:</span>
          </div>

          <InputReadOnly
            :model-value="toCurrency(
              model.taxes.retentions.reduce((acc, tax) => acc + tax.amount, 0) || 0,
              defaultAppVariables.currency,
            )"
          />
        </div>
      </UFormGroup>
    </template>
  </fieldset>
</template>
