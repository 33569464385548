<script setup lang="ts">
const model = defineModel<Industry>({ required: true })

const props = defineProps<{
  utils: IndustriesUtils
  disabled?: boolean
}>()
</script>

<template>
  <div />
</template>
